import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import facilityReducer from "./slices/facilitySlice";
import navActiveReducer from "./slices/navActiveSlice";
import roleReducer from "./slices/roleSlice";
import loginReducer from "./slices/loginSlice";
import patientReducer from "./slices/patientSlice";
import visitReducer from "./slices/visitSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    facility: facilityReducer,
    navActive: navActiveReducer,
    role: roleReducer,
    login: loginReducer,
    patient: patientReducer,
    visit: visitReducer
  },
});
