import { Link, useLocation, NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const SideNav = () => {
	const location = useLocation();
	const pathName = location.pathname;

	// const state = {
	// 	fid: location.state.fid,
	// };
	// console.log(location.pathname);
	const facility = useSelector((state) => state.facility.value);
	const slug = facility.name.replace(/ +/g, "-").toLowerCase();

	// const dashboardBaseUrl = `/dashboard/${facility.fid}/${slug}`;
	// const activeNav = useSelector((state) => state.navActive.value);

	useEffect(() => {
		return () => {
			var navbarStyle = localStorage.getItem("navbarStyle");
			if (navbarStyle && navbarStyle !== "transparent") {
				document.querySelector(".navbar-vertical").classList.add(`navbar-${navbarStyle}`);
			}
		};
	}, []);
	return (
		<>
			<nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
				<div className="d-flex align-items-center flex-column">
					{/* <div className="toggle-icon-wrapper">
            <button className="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Toggle Navigation">
              <span className="navbar-toggle-icon">
                <span className="toggle-line"></span>
              </span>
            </button>
          </div> */}
					<Link className="navbar-brand px-0" to="./">
						<div className="py-3">
							<img className="w-100" src={process.env.PUBLIC_URL + "/assets/img/logo_text.svg"} alt="" />
						</div>
					</Link>
				</div>
				<div className="collapse navbar-collapse" id="navbarVerticalCollapse">
					<div className="navbar-vertical-content scrollbar">
						<ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
							<li className="nav-item bg-light p-1 mb-3 side-nav-facility-name">
								<strong className="text-uppercase text-warning">{facility.name} </strong>
							</li>

							{/* Overview */}
							<li className="nav-item">
								<NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to={`./`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-chart-pie"></span>
										</span>
										<span className="nav-link-text ps-1">Overview</span>
									</div>
								</NavLink>
							</li>

							{/* Patients */}
							<li className="nav-item">
								<div className="row navbar-vertical-label-wrapper mt-3 mb-2">
									<div className="col-auto navbar-vertical-label text-uppercase">RECORDS</div>
									<div className="col ps-0">
										<hr className="mb-0 navbar-vertical-divider" />
									</div>
								</div>

								<NavLink className={({ isActive }) => "nav-link" + (isActive || pathName.includes("/patient") ? " active" : "")} to={`./patients/all`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-wheelchair fs-1"></span>
										</span>
										<span className="nav-link-text ps-1 text-capitalize">Patients</span>
									</div>
								</NavLink>

								{/* <Link className={`nav-link ${pathName.includes("/patient/new") && "active"}`} to={`./patient/new`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-plus"></span>
										</span>
										<span className="nav-link-text ps-1">Register new patient</span>
									</div>
								</Link> */}

								<NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to={`./staff/all`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-users"></span>
										</span>
										<span className="nav-link-text ps-1 text-capitalize">Staff</span>
									</div>
								</NavLink>

								{/* <Link className={`nav-link ${pathName.includes("/staff/new") && "active"}`} to={`/dashboard/${facility.fid}/${slug}/staff/new`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-plus"></span>
										</span>
										<span className="nav-link-text ps-1">Add new staff</span>
									</div>
								</Link> */}
							</li>

							{/* Clinical Management */}
							<li className="nav-item">
								<div className="row navbar-vertical-label-wrapper mt-3 mb-2">
									<div className="col-auto navbar-vertical-label text-uppercase">Clinical Management</div>
									<div className="col ps-0">
										<hr className="mb-0 navbar-vertical-divider" />
									</div>
								</div>

								{/* Visits */}
								<NavLink className={({ isActive }) => "nav-link" + (isActive || pathName.includes("/appointment") ? " active" : "")} to={`./appointments/all`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-clipboard-list"></span>
										</span>
										<span className="nav-link-text ps-1">Appointments</span>
									</div>
								</NavLink>

								{/* <Link className={`nav-link ${pathName.includes("/visits/new") && "active"}`} to={`./visits/new`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-plus"></span>
										</span>
										<span className="nav-link-text ps-1">Create visit</span>
									</div>
								</Link> */}
							</li>

							{/* Inventory */}
							<li className="nav-item">
								<div className="row navbar-vertical-label-wrapper mt-3 mb-2">
									<div className="col-auto navbar-vertical-label text-uppercase">Inventory</div>
									<div className="col ps-0">
										<hr className="mb-0 navbar-vertical-divider" />
									</div>
								</div>

								<NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to={`./inventory/pharmacy`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-pills"></span>
										</span>
										<span className="nav-link-text ps-1 text-capitalize">Pharmacy</span>
									</div>
								</NavLink>

								<NavLink className={({ isActive }) => "nav-link" + (isActive || pathName.includes("/service") ? " active" : "")} to={`./inventory/services`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-stethoscope"></span>
										</span>
										<span className="nav-link-text ps-1 text-capitalize">Services</span>
									</div>
								</NavLink>

								<NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to={`./inventory/shop`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-shopping-basket"></span>
										</span>
										<span className="nav-link-text ps-1 text-capitalize">Shop</span>
									</div>
								</NavLink>

								{/* <Link className={`nav-link ${pathName.includes("/staff/new") && "active"}`} to={`/dashboard/${facility.fid}/${slug}/staff/new`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-plus"></span>
										</span>
										<span className="nav-link-text ps-1">Add new staff</span>
									</div>
								</Link> */}
							</li>

							<li className="nav-item">
								<div className="row navbar-vertical-label-wrapper mt-3 mb-2">
									<div className="col-auto navbar-vertical-label text-uppercase">Accounts and Revenue</div>
									<div className="col ps-0">
										<hr className="mb-0 navbar-vertical-divider" />
									</div>
								</div>

								<NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to={`./billing`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-users"></span>
										</span>
										<span className="nav-link-text ps-1 text-capitalize">Out-Patient Billing</span>
									</div>
								</NavLink>

								{/* <Link className={`nav-link ${pathName.includes("/staff/new") && "active"}`} to={`/dashboard/${facility.fid}/${slug}/staff/new`} role="button" data-bs-toggle="" aria-expanded="false">
									<div className="d-flex align-items-center">
										<span className="nav-link-icon">
											<span className="fas fa-plus"></span>
										</span>
										<span className="nav-link-text ps-1">Add new staff</span>
									</div>
								</Link> */}
							</li>
						</ul>

						<div className="settings mb-3">
							<div className="card alert p-0 shadow-none" role="alert">
								<div className="btn-close-falcon-container">
									<div className="btn-close-falcon" aria-label="Close" data-bs-dismiss="alert"></div>
								</div>
								<div className="card-body text-center">
									<img src="../assets/img/icons/spot-illustrations/navbar-vertical.png" alt="" width="80" />
									<p className="fs--2 mt-2">
										Loving what you see? <br />
										Get your copy of <a href="#!">Falcon</a>
									</p>
									<div className="d-grid">
										<a className="btn btn-sm btn-purchase" href="https://themes.getbootstrap.com/product/falcon-admin-dashboard-webapp-template/" target="_blank" rel="noreferrer">
											Purchase
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};

export default SideNav;
