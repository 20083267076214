import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import requester from "../../helpers/requester";

const initialState = {
	user: {},
	status: "idle", // 'idle' | 'pending' | 'succeeded' | 'failed'
	error: null,
};

export const fetchUser = createAsyncThunk("/user/current", async (thunkAPI) => {
	try {
		const response = await requester.get("/user/current");
		return response.data;
	} catch (error) {
		// console.log(error.response.status);
		return error.response.status;
	}
});

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		updateUserInfo: (state, action) => {
			state.user = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUser.pending, (state, action) => {
				state.status = "pending";
			})
			.addCase(fetchUser.fulfilled, (state, action) => {
				state.status = "success";
				state.user = action.payload;
				state.error = null;
			})
			.addCase(fetchUser.rejected, (state, action) => {
				state.status = "failed";
				state.error = action;
			});
	},
});

export const { updateUserInfo } = userSlice.actions;
export default userSlice.reducer;
