import requester from "./requester";

const createVisit = (visitData) => {
	return requester.post("/visit/new", visitData);
};

const getAllVisits = (fid) => {
	return requester.get(`/visits/${fid}/all`);
};

const getVisit = (data) => {
	return requester.get(`/visit/${data.visit_number}/${data.fid}`);
};

const visitService = {
	createVisit,
	getAllVisits,
	getVisit,
};

export default visitService;
