import requester from "./requester";

const addNewPatient = (patientData) => {
	return requester.post("/patient/new", patientData);
};

const getAllPatients = (fid) => {
	return requester.get(`/patients/${fid}/all`);
};

const getPatient = (data) => {
	return requester.get(`/patient/${data.pid}/${data.fid}`);
};

const patientService = {
	addNewPatient,
	getAllPatients,
	getPatient,
};

export default patientService;
