import { useParams, useLocation, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import Layout from "./Layout";
import facilityService from "../../helpers/facilityService";
import { useEffect, useState } from "react";
import Loader from "../Loader";
import { updateFacility } from "../../app/slices/facilitySlice";

const Dashboard = () => {
	let { fid } = useParams();
	const [isLoading, setisLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			fetchFacility();
		};
	}, []);

	const fetchFacility = async () => {
		try {
			const response = await facilityService.fetchFacility(fid);
			if (response.data.success) {
				await dispatch(updateFacility(response.data.facility));
				setisLoading(false);
			} else {
				await setisLoading(false);
				window.location.href = "/404";
			}
		} catch (error) {}
	};

	return (
		<>
			{isLoading && <Loader />}
			{!isLoading && (
				<Layout>
					<Outlet />
				</Layout>
			)}
		</>
	);
};

export default Dashboard;
