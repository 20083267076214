import cookie from "react-cookies";
import { decodeToken, isExpired } from "react-jwt";

const setToken = (tokenData) => {
	const decodedToken = decodeToken(tokenData.token);
	const expires = new Date(0);
	expires.setUTCSeconds(decodedToken.exp);

	const isTokenExpired = isExpired(tokenData.token);
	//   console.log(isTokenExpired);

	const cookieOptions = {
		path: "/",
		expires: expires,
		maxAge: tokenData.expires,
		domain: "https://apomudin.com",
	};
	// cookie.save("APOMUDIN_USER_TOKEN", tokenData, [cookieOptions]);

	localStorage.setItem("APOMUDIN_USER_TOKEN", JSON.stringify(tokenData.token));
	localStorage.setItem("EXPIRES_IN", JSON.stringify(tokenData.expires));
	// return;
	//   return saveCookei;
	//   if (cookie.save("APOMUDIN_USER_TOKEN", tokenData, [cookieOptions])) {
	//     return true;
	//   } else {
	//     return false;
	//   }
};
const tokenService = {
	setToken,
};

export default tokenService;
