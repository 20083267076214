import { useSelector } from "react-redux";
import authService from "../helpers/authService";
import SearchForm from "./dashboard/SearchForm";
import { Link } from "react-router-dom";

const TopNav = () => {
	const user = useSelector((state) => state.user.user);
	const role = useSelector((state) => state.role.value);

	const handleLogout = async (e) => {
		await authService.logoutUser();
	};

	return (
		<>
			<nav className="navbar navbar-light navbar-glass navbar-top navbar-expand">
				<button className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation">
					<span className="navbar-toggle-icon">
						<span className="toggle-line"></span>
					</span>
				</button>
				<Link className="navbar-brand me-1 me-sm-3" to="/facilities">
					<div className="d-flex align-items-center">
						<img className="me-2" src={process.env.PUBLIC_URL + "/assets/img/logo_text.svg"} alt="" width="150" />
					</div>
				</Link>
				<ul className="navbar-nav align-items-center d-none d-lg-block">
					<li className="nav-item">
						<SearchForm />
					</li>
				</ul>
				<ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
					<li className="nav-item">
						<div className="theme-control-toggle fa-icon-wait px-2">
							<input className="form-check-input ms-0 theme-control-toggle-input" id="themeControlToggle" type="checkbox" data-theme-control="theme" value="dark" />
							<label className="mb-0 theme-control-toggle-label theme-control-toggle-light" htmlFor="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch to light theme">
								<span className="fas fa-sun fs-0"></span>
							</label>
							<label className="mb-0 theme-control-toggle-label theme-control-toggle-dark" htmlFor="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch to dark theme">
								<span className="fas fa-moon fs-0"></span>
							</label>
						</div>
					</li>

					<li className="nav-item dropdown">
						<a className="nav-link px-0 fa-icon-wait" id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-hide-on-body-scroll="data-hide-on-body-scroll">
							<span className="fas fa-hospital" data-fa-transform="shrink-6" style={{ fontSize: "33px" }}></span>
						</a>
						<div className="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg" aria-labelledby="navbarDropdownNotification">
							<div className="card card-notification shadow-none">
								<div className="card-header">
									<div className="row justify-content-between align-items-center">
										<div className="col-auto">
											<h6 className="card-header-title mb-0">All facilities</h6>
										</div>
										{/* <div className="col-auto ps-0 ps-sm-3">
                      <a className="card-link fw-normal" href="#">
                        Mark all as read
                      </a>
                    </div> */}
									</div>
								</div>
								<div className="scrollbar" style={{ maxHeight: "19rem" }}>
									<div className="list-group list-group-flush fw-normal fs--1">
										<div className="list-group-title border-bottom bg-white">MY FACILITIES</div>
										<div className="list-group-item">
											<a className="notification notification-flush notification-unread" href="#!">
												<div className="notification-avatar">
													<div className="avatar avatar-2xl me-3">
														<div className="avatar-name rounded-circle">
															<span>RC</span>
														</div>
													</div>
													{/* <div className="avatar avatar-2xl me-3">
                            <img className="rounded-circle" src="../assets/img/team/1-thumb.png" alt="" />
                          </div> */}
												</div>
												<div className="notification-body">
													<p className="mb-1">
														<strong>Rinat Clinic Limited</strong>
													</p>
													<span className="notification-time">
														{/* <span className="me-2" role="img" aria-label="Emoji">
                              💬
                            </span> */}
														Position: Owner
													</span>
												</div>
											</a>
										</div>
										<div className="list-group-item">
											<a className="notification notification-flush notification-unread" href="#!">
												<div className="notification-avatar">
													<div className="avatar avatar-2xl me-3">
														<div className="avatar-name rounded-circle">
															<span>AB</span>
														</div>
													</div>
												</div>
												<div className="notification-body">
													<p className="mb-1">
														<strong>Albert Brooks Hospital</strong>
													</p>
													<span className="notification-time">Position: Owner</span>
												</div>
											</a>
										</div>
										<div className="list-group-title border-bottom bg-white">OTHER FACILITIES</div>

										<div className="list-group-item">
											<a className="notification notification-flush notification-unread" href="#!">
												<div className="notification-avatar">
													<div className="avatar avatar-2xl me-3">
														<div className="avatar-name rounded-circle">
															<span>KH</span>
														</div>
													</div>
													{/* <div className="avatar avatar-2xl me-3">
                            <img className="rounded-circle" src="../assets/img/icons/weather-sm.jpg" alt="" />
                          </div> */}
												</div>
												<div className="notification-body">
													<p className="mb-1">
														<strong>Korleybu Hospital</strong>
													</p>
													<span className="notification-time">
														<span className="me-2" role="img" aria-label="Emoji">
															Position: Nurse
														</span>
													</span>
												</div>
											</a>
										</div>

										<div className="list-group-item">
											<a className="notification notification-flush notification-unread" href="#!">
												<div className="notification-avatar">
													<div className="avatar avatar-2xl me-3">
														<div className="avatar-name rounded-circle">
															<span>NC</span>
														</div>
													</div>
													{/* <div className="avatar avatar-2xl me-3">
                            <img className="rounded-circle" src="../assets/img/icons/weather-sm.jpg" alt="" />
                          </div> */}
												</div>
												<div className="notification-body">
													<p className="mb-1">
														<strong>Nyaho Clinic</strong>
													</p>
													<span className="notification-time">
														<span className="me-2" role="img" aria-label="Emoji">
															Position: Nurse
														</span>
													</span>
												</div>
											</a>
										</div>
									</div>
								</div>
								<div className="card-footer text-center border-top">
									{/* <a className="card-link d-block" href="../app/social/notifications.html">
                    View all
                  </a> */}
								</div>
							</div>
						</div>
					</li>

					<li className="nav-item dropdown">
						<a className="nav-link notification-indicator notification-indicator-primary px-0 fa-icon-wait" id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-hide-on-body-scroll="data-hide-on-body-scroll">
							<span className="fas fa-bell" data-fa-transform="shrink-6" style={{ fontSize: "33px" }}></span>
						</a>
						<div className="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg" aria-labelledby="navbarDropdownNotification">
							<div className="card card-notification shadow-none">
								<div className="card-header">
									<div className="row justify-content-between align-items-center">
										<div className="col-auto">
											<h6 className="card-header-title mb-0">Notifications</h6>
										</div>
										<div className="col-auto ps-0 ps-sm-3">
											<a className="card-link fw-normal" href="#">
												Mark all as read
											</a>
										</div>
									</div>
								</div>
								<div className="scrollbar" style={{ maxHeight: "19rem" }}>
									<div className="list-group list-group-flush fw-normal fs--1">
										<div className="list-group-title border-bottom">NEW</div>
										<div className="list-group-item">
											<a className="notification notification-flush notification-unread" href="#!">
												<div className="notification-avatar">
													<div className="avatar avatar-2xl me-3">
														<img className="rounded-circle" src="../assets/img/team/1-thumb.png" alt="" />
													</div>
												</div>
												<div className="notification-body">
													<p className="mb-1">
														<strong>Emma Watson</strong> replied to your comment : "Hello world 😍"
													</p>
													<span className="notification-time">
														<span className="me-2" role="img" aria-label="Emoji">
															💬
														</span>
														Just now
													</span>
												</div>
											</a>
										</div>
										<div className="list-group-item">
											<a className="notification notification-flush notification-unread" href="#!">
												<div className="notification-avatar">
													<div className="avatar avatar-2xl me-3">
														<div className="avatar-name rounded-circle">
															<span>AB</span>
														</div>
													</div>
												</div>
												<div className="notification-body">
													<p className="mb-1">
														<strong>Albert Brooks</strong> reacted to <strong>Mia Khalifa's</strong> status
													</p>
													<span className="notification-time">
														<span className="me-2 fab fa-gratipay text-danger"></span>9hr
													</span>
												</div>
											</a>
										</div>
										<div className="list-group-title border-bottom">EARLIER</div>
										<div className="list-group-item">
											<a className="notification notification-flush" href="#!">
												<div className="notification-avatar">
													<div className="avatar avatar-2xl me-3">
														<img className="rounded-circle" src="../assets/img/icons/weather-sm.jpg" alt="" />
													</div>
												</div>
												<div className="notification-body">
													<p className="mb-1">The forecast today shows a low of 20&#8451; in California. See today's weather.</p>
													<span className="notification-time">
														<span className="me-2" role="img" aria-label="Emoji">
															🌤️
														</span>
														1d
													</span>
												</div>
											</a>
										</div>
										<div className="list-group-item">
											<a className="border-bottom-0 notification-unread  notification notification-flush" href="#!">
												<div className="notification-avatar">
													<div className="avatar avatar-xl me-3">
														<img className="rounded-circle" src="../assets/img/logos/oxford.png" alt="" />
													</div>
												</div>
												<div className="notification-body">
													<p className="mb-1">
														<strong>University of Oxford</strong> created an event : "Causal Inference Hilary 2019"
													</p>
													<span className="notification-time">
														<span className="me-2" role="img" aria-label="Emoji">
															✌️
														</span>
														1w
													</span>
												</div>
											</a>
										</div>
										<div className="list-group-item">
											<a className="border-bottom-0 notification notification-flush" href="#!">
												<div className="notification-avatar">
													<div className="avatar avatar-xl me-3">
														<img className="rounded-circle" src="../assets/img/team/10.jpg" alt="" />
													</div>
												</div>
												<div className="notification-body">
													<p className="mb-1">
														<strong>James Cameron</strong> invited to join the group: United Nations International Children's Fund
													</p>
													<span className="notification-time">
														<span className="me-2" role="img" aria-label="Emoji">
															🙋‍
														</span>
														2d
													</span>
												</div>
											</a>
										</div>
									</div>
								</div>
								<div className="card-footer text-center border-top">
									<a className="card-link d-block" href="../app/social/notifications.html">
										View all
									</a>
								</div>
							</div>
						</div>
					</li>

					{/* <li className=" nav-pipe"></li> */}

					<li className="nav-item dropdown border-md-start border-0 ms-0 ms-md-3 ps-0 ps-md-3">
						<a className="nav-link pe-0 ps-2 align-items-center d-flex" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span className="me-1 text-capitalize"> {user.first_name}</span>
							<div className="avatar avatar-xl">
								<img className="rounded-circle" src="/assets/img/team/3-thumb.png" alt="" />
							</div>
						</a>
						<div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
							<div className="bg-white dark__bg-1000 rounded-2 py-2">
								{role && (
									<>
										<p className="px-3 fw-bold text-warning mb-0" href="#!">
											{/* <span className="fas fa-crown me-1"></span> */}
											<span className="text-capitalize">{role}</span>
										</p>
										<div className="dropdown-divider"></div>
									</>
								)}

								<Link className="dropdown-item" to="/facilities">
									Facilities
								</Link>

								<div className="dropdown-divider"></div>

								<a className="dropdown-item" href="#!">
									Set status
								</a>
								<a className="dropdown-item" href="../pages/user/profile.html">
									Profile &amp; account
								</a>
								<a className="dropdown-item" href="#!">
									Feedback
								</a>

								<div className="dropdown-divider"></div>
								<a className="dropdown-item" href="../pages/user/settings.html">
									Settings
								</a>
								<button className="dropdown-item" onClick={() => handleLogout()}>
									Logout
								</button>
							</div>
						</div>
					</li>
				</ul>
			</nav>
		</>
	);
};

export default TopNav;
