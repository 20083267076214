import { Navigate, Outlet } from "react-router-dom";
import cookie from "react-cookies";
import { Fade } from "react-awesome-reveal";

const AuthPages = () => {
  const token = JSON.parse(localStorage?.getItem("APOMUDIN_USER_TOKEN"));

  return token ? (
    <Navigate to="/facilities" />
  ) : (
    <Fade>
      <Outlet />
    </Fade>
  );
};

export default AuthPages;
