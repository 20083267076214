import requester from "./requester";

const fetchCountries = async () => {
	return await requester.get("/countries/all");
};

const calculateBMI = (weight = 0, height = 0) => {
	return (parseFloat(weight || 0) / parseFloat(height || 0) ** 2).toFixed(1).toString();
};

const allowNumbersOnly = (number) => {
	const reg = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
	if (reg.test(number)) {
		return number;
	}
};

const checkBMI = (BMI = 0) => {
	if ((BMI || 0) < 18.5) {
		return "Underweight";
	} else if (BMI > 18.5 && BMI < 24.9) {
		return "Normal / Healthy";
	} else if (BMI > 25 && BMI < 29.9) {
		return "Overweight";
	} else {
		return "Obese";
	}
};

const convertImageToBase64 = async (image) => {
	// console.log(image[0]);
	let fileReader = new FileReader();
	fileReader.readAsDataURL(image[0]);
	return (fileReader.onload = (event) => {
		// console.log(event.target.result);
		return event.target.result;
	});
};

const miscellaneous = {
	fetchCountries,
	calculateBMI,
	allowNumbersOnly,
	checkBMI,
	convertImageToBase64,
};

export default miscellaneous;
