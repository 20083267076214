import TopNav from "./TopNav";
import Footer from "./Footer";
import { Fade } from "react-awesome-reveal";
const Layout = ({ children }) => {
  return (
    <>
      <div className="container">
        <div className="content">
          <TopNav />
          <div className="mt-5"></div>
          <Fade>{children}</Fade>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
