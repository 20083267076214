import { useState } from "react";
import { Modal } from "react-bootstrap";

const PrescriptionMedicine = () => {
	const [showModal, setShowModal] = useState(false);

	async function handleSubmit() {}

	return (
		<>
			<div className="card mb-3 overflow-hidden">
				<div className="card-header">
					<h5>Prescription Medicine</h5>
				</div>
				<div className="card-body">
					{[1, 2].map((data) => (
						<div key={data}>
							<div class="d-flex mb-3 hover-actions-trigger align-items-center">
								<div class="ms-3 flex-shrink-1 flex-grow-1">
									<h6 class="mb-2">
										<a class="stretched-link text-900 fw-semi-bold" href="#!">
											Stopkof syrup
										</a>
									</h6>
									<div className="row">
										<div className="col">
											<div class="fs--1">
												<span class="fw-semi-bold">Dosage:</span>
												<span class="fw-medium text-black ms-2">2 tables</span>
											</div>
											<div class="fs--1">
												<span class="fw-semi-bold">Frequency:</span>
												<span class="fw-medium text-black ms-2">morning</span>
											</div>
										</div>

										<div className="col">
											<div class="fs--1">
												<span class="fw-semi-bold">No. of days:</span>
												<span class="fw-medium text-black ms-2">2 weeks</span>
											</div>
											<div class="fs--1">
												<span class="fw-semi-bold">Food relation:</span>
												<span class="fw-medium text-black ms-2">after food</span>
											</div>
										</div>

										<div className="col">
											<div class="fs--1">
												<span class="fw-semi-bold">Route:</span>
												<span class="fw-medium text-black ms-2">oral</span>
											</div>
										</div>
									</div>

									<div class="fs--1 mt-2">
										<span class="fw-semi-bold">Instructions:</span>
										<span class="fw-medium text-black ms-2">
											Lorem ipsum, dol sit amet consectetur adipisicing elit. Repudiandae sapiente alias neque iure sit
											cum iusto explicabo dignissimos recusandae, veritatis numquam ipsam nam harum eius, assumenda
											officia vitae! Sed, ea!
										</span>
									</div>

									<div class="hover-actions end-0 top-50 translate-middle-y">
										<a
											class="btn btn-light border-300 btn-sm me-1 text-600"
											data-bs-toggle="tooltip"
											data-bs-placement="top"
											title="Download"
											href="/assets/img/icons/cloud-download.svg"
											download="download"
										>
											<img src="/assets/img/icons/cloud-download.svg" alt="" width="15" />
										</a>
										<button
											class="btn btn-light border-300 btn-sm me-1 text-600 shadow-none"
											type="button"
											data-bs-toggle="tooltip"
											data-bs-placement="top"
											title="Edit"
										>
											<img src="/assets/img/icons/edit-alt.svg" alt="" width="15" />
										</button>
									</div>
								</div>
							</div>
							<hr class="bg-200" />
						</div>
					))}

					<a
						className="mb-4 d-block d-flex align-items-center"
						href="#experience-form"
						data-bs-toggle="collapse"
						aria-expanded="true"
						aria-controls="experience-form"
						onClick={() => setShowModal(true)}
					>
						<span className="circle-dashed">
							<span className="fas fa-plus"></span>
						</span>
						<span className="ms-3">Add prescription</span>
					</a>
				</div>
			</div>

			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				dialogClassName="modal-90w"
				contentClassName="min-vh-50"
				animation={false}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>Add prescripiton</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className="row g-3 needs-validation" onSubmit={handleSubmit}>
						<div className="col-12">
							<label className="form-label" htmlFor="attendance">
								Medicine
							</label>
							<input type="text" className="form-control" />
						</div>
						<div className="col-lg-6">
							<label className="form-label" htmlFor="type">
								Dosage
							</label>
							<div className="row g-2">
								<div className="col">
									<input type="number" placeholder="Quantity" className="form-control" />
								</div>
								<div className="col">
									<select name="type" id="type" className="form-select">
										<option value="Tablet(s)">Table(s)</option>
										<option value="mg">mg</option>
									</select>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<label className="form-label" htmlFor="category">
								Frequency
							</label>
							<select name="category" id="category" className="form-select">
								<option value="morning">Morning</option>
								<option value="evening">Evening</option>
								<option value="afternoon">afternoon</option>
								<option value="PRN">PRN</option>
								<option value="STAT">STAT</option>
								<option value="once a day">once a day</option>
								<option value="2 times a day">2 times a day</option>
								<option value="Urgent">Urgent</option>
								<option value="3 times a day">3 times a day</option>
								<option value="4 times a day">4 times a day</option>
								<option value="bed time">bed time</option>
								<option value="hourly">hourly</option>
							</select>
						</div>

						<div className="col-lg-6">
							<label className="form-label" htmlFor="type">
								No. of days
							</label>
							<div className="row g-2">
								<div className="col">
									<input type="number" placeholder="Quantity" className="form-control" />
								</div>
								<div className="col">
									<select name="type" id="type" className="form-select">
										<option value="day">day</option>
										<option value="week">week</option>
										<option value="week">week</option>
										<option value="year">year</option>
									</select>
								</div>
							</div>
						</div>

						<div className="col-lg-6">
							<label className="form-label" htmlFor="category">
								Food relation
							</label>
							<select name="category" id="category" className="form-select">
								<option value="before breakfast">before breakfast</option>
								<option value="with breakfast">with breakfast</option>
								<option value="after breakfast">after breakfast</option>
								<option value="before lunch">before lunch</option>
								<option value="with lunch">with lunch</option>
								<option value="after lunch">after lunch</option>
								<option value="before dinner">before dinner</option>
								<option value="with dinner">with dinner</option>
								<option value="after dinner">after dinner</option>
								<option value="before food">before food</option>
								<option value="with food">with food</option>
								<option value="after food">after food</option>
							</select>
						</div>

						<div className="col-lg-6">
							<label className="form-label" htmlFor="category">
								Route
							</label>
							<select name="category" id="category" className="form-select">
								<option value="oral">oral</option>
								<option value="ophthalmic">ophthalmic</option>
								<option value="topical">topical</option>
								<option value="auricular (otic)">auricular (otic)</option>
								<option value="nasal">nasal</option>
								<option value="intramuscluar">intramuscluar</option>
								<option value="respiratory (inhalation)">respiratory (inhalation)</option>
								<option value="transdermal">transdermal</option>
								<option value="intravanous">intravanous</option>
								<option value="intra-articular">intra-articular</option>
								<option value="vaginal">vaginal</option>
							</select>
						</div>

						<div className="col-12">
							<label className="form-label" htmlFor="category">
								Instruction
							</label>
							<textarea className="form-control" rows="4"></textarea>
						</div>

						<div className="col-12 d-flex">
							<button className="btn btn-primary px-4" type="submit" disabled={""}>
								Save
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PrescriptionMedicine;
