import { Fade } from "react-awesome-reveal";
import IntlTelInput from "react-intl-tel-input";
import Select from "react-select";
import { useState, useEffect } from "react";
import DatePicker from "react-date-picker";
import countriesList from "../../../helpers/countriesList";
import { languages } from "../../../helpers/languages";
import patientService from "../../../helpers/patientService";
import { Modal } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PageHeader from "../PageHeader";
import moment from "moment/moment";
import Loader from "../../Loader";

const RegisterPatient = () => {
	const navigate = useNavigate();
	const facility = useSelector((state) => state.facility.value);
	const slug = facility.name.replace(/ +/g, "-").toLowerCase();
	const startDate = new Date();
	startDate.setDate(startDate.getDate() - 21900);

	const nationalities = countriesList.nationalities();
	const countries = countriesList.countries();
	const regions = countriesList.regions();
	const { pid, fid } = useParams();

	const [formError, setformError] = useState({});
	const [formFeedback, setformFeedback] = useState("");

	const [isSubmitting, setisSubmitting] = useState(false);
	const [isLoading, setisLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [private_insurance, setprivateInsurance] = useState(false);
	const [showMembershipNumberField, setshowMembershipNumberField] = useState(false);
	const [has_nhis, sethasNhis] = useState(false);
	const [disableInputs, setdisableInputs] = useState(true);

	const [formData, setformData] = useState({
		pid: "",
		new_born: false,
		first_name: "",
		middle_name: "",
		last_name: "",
		dob: "", //new Date(1990, 0, 1)
		age: 0,
		gender: "",
		marital_status: "",
		phone_number: "",
		email: "",
		occupation: "",
		educational_level: "",
		religion: "",
		nationality: "",
		languages_spoken: [],
		country: "",
		region: "",
		town_city: "",
		street_address: "",
		house_number: "",
		ghana_post_gps: "",
		health_insurance: "",
		insurance_provider: "",
		membership_number: "",
		nhis_number: "",
		private_insurance: false,
		create_appointment: true,
		has_nhis: false,
		facility: facility.fid,
	});

	const getPatientData = () => {
		patientService
			.getPatient({ pid: pid, fid: fid })
			.then((response) => {
				if (response.data.success) {
					let patient = response.data.patient;
					Object.keys(patient).forEach((k) => (patient[k] = patient[k] === null ? "" : patient[k]));
					patient.dob = moment(patient.dob)._d; //convert dob format to be compatible with calendar plugin ;;;  Mon Jan 01 1990 00:00:00 GMT+0000 (Greenwich Mean Time)
					patient.languages_spoken = patient.languages_spoken.map((language) => ({ label: language, value: language }));
					Object.assign(formData, patient);
					setformData({ ...formData });
					console.log(formData);
					setisLoading(false);
				}
				if (!response.data.success) {
					navigate("../patients/all");
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		if (pid) {
			getPatientData();
		} else {
			setisLoading(false);
		}
	}, [pid]);

	const handleInsurance = (e) => {
		e.target.value === "insurance-company" ? setprivateInsurance(true) : setprivateInsurance(false);
		e.target.value === "nhis" ? sethasNhis(true) : sethasNhis(false);
		setformData({ ...formData, health_insurance: e.target.value });
		setshowMembershipNumberField(false); //hide the membership number input
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setisSubmitting(true);

		setformError({});

		patientService
			.addNewPatient(formData)
			.then((response) => {
				if (response.data.success) {
					setformData({ ...formData, pid: response.data.pid });
					setformFeedback(response.data.message);
					setShowModal(true);
				}

				if (response.data.errors) {
					setformError(response.data.errors);
				}

				setisSubmitting(false);
			})
			.catch((error) => {
				setisSubmitting(false);
			});
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<Fade>
					<PageHeader title="Patients" subTitle={pid ? pid : "Register New Patient"} showButton={pid ? true : false} buttonText={`Create Appointment`} buttonRoute={pid ? `../appointment/new/${pid}` : ``} />

					<div className="card mb-3 overflow-hidden">
						{/* <div className="card-header">
							<div className="row gx-0 align-items-center">
								<div className="col col-md-auto d-flex justify-content-end order-md-3  ms-auto">
									{disableInputs ? (
										<button
											className="btn btn-falcon-primary btn-sm"
											type="button"
											data-event="edit"
											onClick={() => {
												setdisableInputs(false);
											}}>
											Edit
										</button>
									) : (
										<button
											className="btn btn-falcon-primary btn-sm"
											type="button"
											data-event="edit"
											onClick={() => {
												setdisableInputs(true);
											}}>
											Cancel Edit
										</button>
									)}
								</div>
							</div>
						</div> */}
						<div className="card-body bg-light">
							<div className="row gx-3 align-items-center mb-3">
								<div className="col-auto order-md-0 px-0">
									<h6 className="fw-normal m-0 ">
										Feilds with <span className="text-warning">orange</span> markings are required
									</h6>
								</div>
								{/* <div className="row gx-0 align-items-center"> */}
								<div className="col col-md-auto d-flex justify-content-end order-md-3  ms-auto px-0">
									{disableInputs ? (
										<button
											className="btn btn-falcon-primary btn-sm"
											type="button"
											data-event="edit"
											onClick={() => {
												setdisableInputs(false);
											}}>
											Edit
										</button>
									) : (
										<button
											className="btn btn-falcon-primary btn-sm"
											type="button"
											data-event="edit"
											onClick={() => {
												setdisableInputs(true);
											}}>
											Cancel Edit
										</button>
									)}
								</div>
								{/* </div> */}
							</div>

							<form className="row g-3 needs-validation" onSubmit={handleSubmit}>
								<div className="col-12 bg-200 fw-bold text-1000 py-2">Basic Information</div>

								{/* <div>
									<span className="me-2 fw-bold">Age:</span>
									<span>{formData.age}yrs</span>
								</div> */}

								<div className="col-12">
									<div className="form-check form-switch mb-0 lh-1">
										<input
											className="form-check-input"
											type="checkbox"
											id="new_born"
											name="new_born"
											value={formData.new_born}
											defaultChecked={formData.new_born}
											onChange={() => {
												setformData({ ...formData, new_born: !formData.new_born });
											}}
											disabled={disableInputs}
										/>
										<label className="form-check-label mb-0" htmlFor="new_born">
											New born
										</label>
									</div>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="first_name">
										First Name
									</label>
									<input
										className="form-control"
										id="first_name"
										name="first_name"
										type="text"
										value={formData.first_name}
										onChange={(e) => {
											setformData({ ...formData, first_name: e.target.value });
										}}
										required
										disabled={disableInputs}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="middle_name">
										Middle Name
									</label>
									<input
										className="form-control"
										id="middle_name"
										name="middle_name"
										type="text"
										value={formData.middle_name}
										onChange={(e) => {
											setformData({ ...formData, middle_name: e.target.value });
										}}
										disabled={disableInputs}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="last_name">
										Last Name
									</label>
									<input
										className="form-control"
										id="last_name"
										name="last_name"
										type="text"
										value={formData.last_name}
										onChange={(e) => {
											setformData({ ...formData, last_name: e.target.value });
										}}
										required
										disabled={disableInputs}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="dob">
										Date of Birth
									</label>

									<DatePicker
										onChange={(value) => {
											setformData({ ...formData, dob: value });
										}}
										value={formData.dob}
										className={["form-control py-1 d-block required"]}
										required={true}
										format="dd-MM-y"
										name="dob"
										autoFocus={false}
										dayPlaceholder="dd"
										monthPlaceholder="mm"
										yearPlaceholder="yyyy"
										defaultActiveStartDate={new Date(1990, 0, 1)}
										maxDate={new Date()}
										// view="month"
										disabled={disableInputs}
									/>
								</div>

								{/* age */}
								{pid && (
									<div className="col-lg-4">
										<label className="form-label" htmlFor="last_name">
											Age (years)
										</label>
										<input className="form-control" id="last_name" name="last_name" type="text" value={formData.age} onChange={(e) => {}} readOnly disabled={disableInputs} />
									</div>
								)}

								<div className="col-lg-4">
									<label className="form-label" htmlFor="gender">
										Gender
									</label>
									<select
										className="form-select"
										id="gender"
										name="gender"
										value={formData.gender}
										onChange={(e) => {
											setformData({ ...formData, gender: e.target.value });
										}}
										required
										disabled={disableInputs}>
										<option value="" hidden disabled></option>
										<option value="female">Female</option>
										<option value="male">Male</option>
									</select>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="marital_status">
										Marital Status
									</label>
									<select
										className="form-select"
										id="marital_status"
										name="marital_status"
										value={formData.marital_status}
										onChange={(e) => {
											setformData({ ...formData, marital_status: e.target.value });
										}}
										required
										disabled={disableInputs}>
										<option value="" hidden disabled></option>
										<option value="single">Single</option>
										<option value="married">Married</option>
										<option value="devorced">Divorced</option>
									</select>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="phone_number">
										Phone Number
									</label>
									<IntlTelInput
										preferredCountries={["gh"]}
										onlyCountries={["gh"]}
										onPhoneNumberChange={(status, phone_number, country, e) => {
											const reg = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
											if (reg.test(phone_number) || phone_number === "") {
												setformData({ ...formData, phone_number: phone_number });
											}
										}}
										inputClassName={`form-control`}
										containerClassName="intl-tel-input w-100 w-md-auto d-block"
										value={formData.phone_number}
										defaultCountry="gh"
										autoComplete="on"
										nationalMode="true"
										formatOnInit="true"
										format="true"
										fieldId="phone_number"
										fieldName="phone_number"
										useMobileFullscreenDropdown="true"
										disabled={disableInputs}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="email">
										Email
									</label>
									<input
										className="form-control"
										id="email"
										type="email"
										name="email"
										value={formData.email}
										onChange={(e) => {
											setformData({ ...formData, email: e.target.value });
										}}
										disabled={disableInputs}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="occupation">
										Occupation
									</label>
									<input
										className="form-control"
										id="occupation"
										name="occupation"
										value={formData.occupation}
										type="text"
										onChange={(e) => {
											setformData({ ...formData, occupation: e.target.value });
										}}
										disabled={disableInputs}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="educational_level">
										Educational Level
									</label>
									<select
										className="form-select"
										id="educational_level"
										name="educational_level"
										value={formData.educational_level}
										onChange={(e) => {
											setformData({ ...formData, educational_level: e.target.value });
										}}
										required
										disabled={disableInputs}>
										<option value="" hidden disabled></option>
										<option value="jhs">Junior High School</option>
										<option value="shs">Senior High School</option>
										<option value="graduate">Graduate</option>
										<option value="post_graduate">Post Graduate</option>
										<option value="none">None</option>
									</select>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="religion">
										Religion
									</label>
									<select
										className="form-select"
										id="religion"
										name="religion"
										value={formData.religion}
										onChange={(e) => {
											setformData({ ...formData, religion: e.target.value });
										}}
										required
										disabled={disableInputs}>
										<option value="" hidden disabled></option>
										<option value="christian">Christian</option>
										<option value="muslim">Muslim</option>
										<option value="other">Other</option>
										<option value="none">None</option>
									</select>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="nationality">
										Nationality
									</label>
									<select
										className="form-select"
										id="nationality"
										name="nationality"
										value={formData.nationality}
										onChange={(e) => {
											setformData({ ...formData, nationality: e.target.value });
										}}
										required
										disabled={disableInputs}>
										<option value="" hidden disabled></option>
										{nationalities.map((national) => {
											return (
												<option key={national} value={national}>
													{national.charAt(0).toUpperCase() + national.slice(1)}
												</option>
											);
										})}
									</select>
								</div>

								<div className="col-lg-8">
									<label className="form-label" htmlFor="languages_spoken">
										Languages Spoken
									</label>
									<Select
										defaultValue={formData.languages_spoken}
										isMulti
										isSearchable
										onChange={(languageObject) => {
											setformData({ ...formData, languages_spoken: languageObject });
										}}
										name="languages_spoken"
										className="form-select p-0"
										options={languages}
										isDisabled={disableInputs}
									/>
								</div>

								<div className="col-12 bg-200 fw-bold text-1000 py-2">Address</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="country">
										Country
									</label>
									<select
										className="form-select"
										id="country"
										name="country"
										value={formData.country}
										onChange={(e) => {
											setformData({ ...formData, country: e.target.value });
										}}
										required
										disabled={disableInputs}>
										<option value="" hidden disabled></option>
										{countries.map((c) => {
											return (
												<option key={c.name} value={c.name.toLowerCase()}>
													{c.name}
												</option>
											);
										})}
									</select>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="region">
										Region
									</label>
									<select
										className="form-select"
										id="region"
										name="region"
										value={formData.region}
										onChange={(e) => {
											setformData({ ...formData, region: e.target.value });
										}}
										disabled={disableInputs}>
										<option value="" hidden disabled></option>
										{regions.map((r) => {
											return (
												<option key={r} value={r.toLowerCase()}>
													{r}
												</option>
											);
										})}
									</select>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="town_city">
										Town/City
									</label>
									<input
										className="form-control"
										id="town_city"
										name="town_city"
										type="text"
										value={formData.town_city}
										onChange={(e) => {
											setformData({ ...formData, town_city: e.target.value });
										}}
										disabled={disableInputs}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="street_address">
										Street Address
									</label>
									<input
										className="form-control"
										id="street_address"
										name="street_address"
										type="text"
										value={formData.street_address}
										onChange={(e) => {
											setformData({ ...formData, street_address: e.target.value });
										}}
										disabled={disableInputs}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="house_number">
										House No.
									</label>
									<input
										className="form-control"
										id="house_number"
										name="house_number"
										type="text"
										value={formData.house_number}
										onChange={(e) => {
											setformData({ ...formData, house_number: e.target.value });
										}}
										disabled={disableInputs}
									/>
								</div>

								<div className="col-lg-4">
									<label className="form-label" htmlFor="ghana_post_gps">
										Ghana Post GPS
									</label>
									<input
										className="form-control"
										id="ghana_post_gps"
										name="ghana_post_gps"
										type="text"
										placeholder="GH-123-456"
										value={formData.ghana_post_gps}
										onChange={(e) => {
											setformData({ ...formData, ghana_post_gps: e.target.value });
										}}
										disabled={disableInputs}
									/>
								</div>

								<div className="col-12 bg-200 fw-bold text-1000 py-2">Payer Information</div>

								<div className="col-lg-6">
									<label className="form-label" htmlFor="health_insurance">
										Payment provider
									</label>
									<select className="form-select" id="health_insurance" name="health_insurance" value={formData.health_insurance} onChange={(e) => handleInsurance(e)} disabled={disableInputs} required>
										<option value="" disabled hidden></option>
										<option value="none">Private - Cash</option>
										<option value="nhis">National Health Insurance (NHIS)</option>
										<option value="insurance-company">Private Insurance Company</option>
									</select>
								</div>

								<div className="col-lg-6">
									{has_nhis && (
										<Fade>
											<label className="form-label" htmlFor="nhis_number">
												National Health Insurance Number
											</label>
											<input
												className="form-control"
												id="nhis_number"
												name="nhis_number"
												type="text"
												placeholder="GH-123-456"
												value={formData.nhis_number}
												onChange={(e) => {
													setformData({ ...formData, nhis_number: e.target.value });
												}}
												disabled={disableInputs}
											/>
										</Fade>
									)}

									{private_insurance && (
										<Fade>
											<label className="form-label" htmlFor="insurance_provider">
												Choose Insurance Company
											</label>
											<select
												className="form-select"
												id="insurance_provider"
												name="insurance_provider"
												value={formData.insurance_provider}
												onChange={(e) => {
													setformData({ ...formData, insurance_provider: e.target.value });
													setshowMembershipNumberField(true);
												}}
												required
												disabled={disableInputs}>
												<option value="" hidden disabled></option>
												<option value="none">Apex Mutual Health</option>
												<option value="nhis">Acacia Pharmacy</option>
												<option value="private">Cosmopolitan Health Service</option>
											</select>
										</Fade>
									)}

									{private_insurance && showMembershipNumberField && (
										<Fade>
											<label className="form-label mt-3" htmlFor="nhis_number">
												Membership Number
											</label>
											<input
												className="form-control"
												id="membership_number"
												name="membership_number"
												type="text"
												placeholder=""
												value={formData.membership_number}
												onChange={(e) => {
													setformData({ ...formData, membership_number: e.target.value });
												}}
												required
												disabled={disableInputs}
											/>
										</Fade>
									)}
								</div>

								<div className="col-12">
									<div className="form-check form-switch mb-0 lh-1">
										<input
											className="form-check-input"
											type="checkbox"
											id="create_appointment"
											value={formData.create_appointment}
											name="create_appointment"
											defaultChecked={formData.create_appointment}
											onClick={() => {
												setformData({ ...formData, create_appointment: !formData.create_appointment });
											}}
											disabled={disableInputs}
										/>
										<label className="form-check-label mb-0" htmlFor="create_appointment">
											Create appointment after register
										</label>
									</div>
								</div>

								<div className="col-12">
									{formError
										? Object.keys(formError).map((error) => {
												return (
													<p key={error} className="text-danger m-0 shake">
														{formError[error]}
													</p>
												);
										  })
										: ""}
								</div>
								<div className="col-12 d-flex">
									<button className="btn btn-primary px-4" type="submit" disabled={isSubmitting || disableInputs ? true : false}>
										<span>{`${isSubmitting ? "Saving..." : "Save"}`}</span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</Fade>
			)}

			<Modal
				show={showModal}
				onHide={() => {
					navigate(`../patient/${formData.pid}`);
					setShowModal(false);
				}}
				backdrop="static"
				keyboard={false}
				centered>
				<Modal.Header closeButton className="border-0"></Modal.Header>
				<Modal.Body className="text-center">
					<img className="rounded-circle" width={"80"} src={process.env.PUBLIC_URL + "/assets/img/icons/success.png"} alt="" />
					<div className="my-4">
						<h5>Success!</h5>
						<p>{formFeedback}</p>

						{!pid && (
							<div className="d-flex justify-content-center border p-3 w-50 mx-auto">
								<span className="m-0 me-2">Folder No:</span>
								<h5>{formData.pid}</h5>
							</div>
						)}
					</div>
					<div className="my-4">
						<Link className="btn btn-falcon-primary me-3" to={`../appointment/new/${formData.pid}`}>
							Create appointment for {formData.first_name}
						</Link>
						<Link className="btn btn-primary" to={`../patients/all`}>
							Continue
						</Link>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default RegisterPatient;
