import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import PageHeader from "../PageHeader";
const AllStaff = () => {
	return (
		<>
			<Fade>
				<div className=" mb-3">
					<PageHeader title={`Records`} subTitle={`All Staff`} showButton={true} buttonText={`new Staff`} buttonRoute={`../staff/new`} />

					<div className="card bg-100">
						<div className="card-body">
							<div className="row">
								<div className="col-lg-4 border-lg-end border-bottom border-lg-0 pb-3 pb-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-primary">
												<span className="fs--2 fas fa-phone text-primary"></span>
											</div>
											<h6 className="mb-0">Doctors</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">15%</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-up text-primary"></span>
												<p className="fs--2 mb-0 text-nowrap">2500 vs 2683 </p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[220,230,150,175,200,170,70,160],"color":"#2c7be5","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#2c7be53A"},{"offset":1,"color":"#2c7be50A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>
								<div className="col-lg-4 border-lg-end border-bottom border-lg-0 py-3 py-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-info">
												<span className="fs--2 fas fa-user text-info"></span>
											</div>
											<h6 className="mb-0">Nurses</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">13%</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-up text-success"></span>
												<p className="fs--2 mb-0 text-nowrap">1635 vs 863 </p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[90,160,150,120,230,155,220,240],"color":"#27bcfd","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#27bcfd3A"},{"offset":1,"color":"#27bcfd0A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>
								<div className="col-lg-4 pt-3 pt-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-success">
												<span className="fs--2 fas fa-bolt text-success"></span>
											</div>
											<h6 className="mb-0">Lab Technicians</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">16%</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-down text-danger"></span>
												<p className="fs--2 mb-0 text-nowrap">1423 vs 256 </p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[200,150,175,130,150,115,130,100],"color":"#00d27a","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#00d27a3A"},{"offset":1,"color":"#00d27a0A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="card mb-3">
							<div className="card-header bg-light">
								<div className="row align-items-center">
									<div className="col">
										<h5 className="mb-0" id="followers">
											My staff <span className="d-none d-sm-inline-block">(233)</span>
										</h5>
									</div>
									{/* <div className="col text-end">
                    <a className="font-sans-serif" href="../../app/social/followers.html">
                      My staff
                    </a>
                  </div> */}
								</div>
							</div>
							<div className="card-body bg-light px-1 py-0">
								<div className="row g-0 text-center fs--1">
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/1.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Emilia Clarke</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Technext limited
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/2.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Kit Harington</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Harvard Korea Society
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/3.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Sophie Turner</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Graduate Student Council
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/4.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Peter Dinklage</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Art Club, MIT
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/5.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Nikolaj Coster</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Archery Club, MIT
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/6.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Isaac Hempstead</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Asymptones
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/7.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Alfie Allen</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Brain Trust
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/8.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Iain Glen</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													GSAS Action Coalition
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/9.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Liam Cunningham</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Caving Club, MIT
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/10.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">John Bradley</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Chess Club
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/11.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Rory McCann</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Chamber Music Society
												</a>
											</p>
										</div>
									</div>
									<div className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-1">
										<div className="bg-white dark__bg-1100 p-3 h-100">
											<a href="../../pages/user/profile.html">
												<img className="img-thumbnail img-fluid rounded-circle mb-3 shadow-sm" src={process.env.PUBLIC_URL + "/assets/img/team/12.jpg"} alt="" width="100" />
											</a>
											<h6 className="mb-1">
												<a href="../../pages/user/profile.html">Joe Dempsie</a>
											</h6>
											<p className="fs--2 mb-1">
												<a className="text-700" href="#!">
													Clubchem
												</a>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</>
	);
};

export default AllStaff;
