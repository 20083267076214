import PageHeader from "../PageHeader";
import { Fade } from "react-awesome-reveal";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import patientService from "../../../helpers/patientService";
import Loader from "../../Loader";
import visitService from "../../../helpers/visitService";

const CreateVisit = () => {
	const navigate = useNavigate();
	const { pid, fid } = useParams();
	const [patient, setpatient] = useState({});
	const [openMoal, setopenMoal] = useState(false);
	const [isLoading, setisLoading] = useState(true);
	const [dataFound, setdataFound] = useState(false);
	const [visitNumber, setvisitNumber] = useState("");
	const [isSubmitting, setisSubmitting] = useState(false);

	const [modalInfo, setmodalInfo] = useState({
		title: "",
		subTitle: "",
		image: "",
	});

	const [form, setform] = useState({
		visit_type: "",
		care_type: "",
		department: "",
		to_see: "",
		description: "",
		pid: pid,
		fid: fid,
		status: "Client Service",
	});

	const getPatient = () => {
		patientService
			.getPatient({ pid: pid, fid: fid })
			.then((response) => {
				if (response.data.success) {
					setpatient(response.data.patient);
					setdataFound(true);
					setisLoading(false);
				}

				if (!response.data.success) {
					navigate("../appointments/all");
				}
			})
			.catch((error) => {
				setdataFound(false);
				setisLoading(false);
				setopenMoal(true);
			});
	};

	useEffect(() => {
		getPatient();
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();

		setisSubmitting(true);

		if (form.visit_type !== "" && form.care_type !== "" && form.department !== "" && form.to_see !== "") {
			visitService
				.createVisit(form)
				.then((response) => {
					if (response.data.success) {
						// setvisitNumber(response.data.visit_number);
						setmodalInfo({ ...modalInfo, title: "Success!", subTitle: "Patients visit number is:", image: "success.png" });
						// setopenMoal(true);
						// setisSubmitting(false);
					} else {
						// setvisitNumber(response.data.visit_number);
						setmodalInfo({ ...modalInfo, title: "Already in queue!", subTitle: "Patient is already in queue.", image: "warning.png" });
						// setopenMoal(true);
						// setisSubmitting(false);
					}
					setvisitNumber(response.data.visit_number);
					setopenMoal(true);
					setisSubmitting(false);
				})
				.catch((error) => {
					setisSubmitting(false);
				});
		}
	};

	const handleCloseModal = () => {
		setform({ ...form, visit_type: "", care_type: "", department: "", to_see: "", description: "" });
		document.querySelector("input[type=radio][name=visit_type]:checked").checked = false;
		setisSubmitting(false);
		setopenMoal(false);
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<Fade>
					{dataFound ? (
						<>
							<PageHeader title="Appointments" subTitle={`Create appointment`} />
							<div className="card mb-3">
								<div className="card-body">
									<div className="d-flex align-items-center mb-2">
										<div className="avatar avatar-xl me-2">
											<img className="rounded-circle" src={process.env.PUBLIC_URL + "/assets/img/team/1-thumb.png"} alt="" />
										</div>
										<h6 className="m-0 text-capitalize">{patient.full_name}</h6>
									</div>

									<div className="row">
										<div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
											<p className="mb-1 fs--1">
												<strong>Folder No: </strong>
												{patient.pid}
											</p>
											<p className="mb-0 fs--1">
												<strong>Age: </strong>
												{patient.age}
											</p>
										</div>

										<div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
											<p className="mb-0 fs--1 text-capitalize">
												<strong>Gender: </strong>
												{patient.gender}
											</p>
											<p className="mb-0 fs--1">
												<strong>Date Registered: </strong>
												{patient.created_at}
											</p>
										</div>

										{/* <div className="col-md-6 col-lg-3">
									<div className="d-flex">
										<img className="me-3" src="../../../assets/img/icons/visa.png" width="40" height="30" alt="" />
										<div className="flex-1">
											<h6 className="mb-0">Antony Hopkins</h6>
											<p className="mb-0 fs--1">**** **** **** 9809</p>
										</div>
									</div>
								</div> */}
									</div>
								</div>
							</div>
							<div className="card mb-3 overflow-hidden">
								<div className="card-body bg-light">
									<h6 className="fw-normal">
										Feilds with <span className="text-warning">orange</span> markings are required
									</h6>
									<form className="row g-3 needs-validation" onSubmit={handleSubmit}>
										<div className="col-12 bg-200 fw-bold text-1000 py-2">Appointment Information</div>
										<div className="col-12">
											<label className="form-check-label">Appointment Type</label>
											<br />
											<div className="form-check form-check-inline">
												<input
													className="form-check-input"
													id="out_patient_visit"
													type="radio"
													name="visit_type"
													value="Out-Patient"
													onChange={(e) => {
														setform({ ...form, visit_type: e.target.value });
													}}
													required
												/>
												<label className="form-check-label" htmlFor="out_patient_visit">
													Out-patient
												</label>
											</div>
											<div className="form-check form-check-inline">
												<input
													className="form-check-input"
													id="in_patient_visit"
													type="radio"
													name="visit_type"
													value="In-Patient"
													onChange={(e) => {
														setform({ ...form, visit_type: e.target.value });
													}}
													required
												/>
												<label className="form-check-label" htmlFor="in_patient_visit">
													In-patient
												</label>
											</div>
										</div>

										<div className="col-md-4">
											<label className="form-label" htmlFor="care_type">
												Care Type
											</label>
											<select
												id="care_type"
												className="form-select"
												value={form.care_type}
												onChange={(e) => {
													setform({ ...form, care_type: e.target.value });
												}}
												aria-label="Care type"
												name="care_type"
												required>
												<option value="" hidden></option>
												<option value="General">General Care</option>
												<option value="Antenatal">Antenatal Care</option>
												<option value="Investigation/Procedure">Investigation / Procedure</option>
												<option value="Mental Health">Mental Health Care</option>
												<option value="Postnatal">Postnatal Care</option>
												<option value="Surgery">Surgery</option>
											</select>
										</div>

										<div className="col-md-4">
											<label className="form-label" htmlFor="department">
												Department
											</label>
											<select
												id="department"
												className="form-select"
												value={form.department}
												onChange={(e) => {
													setform({ ...form, department: e.target.value });
												}}
												aria-label="Department"
												name="department"
												required>
												<option value="" hidden></option>
												<option value="General Medical Care">General Medical Care</option>
												<option value="OPD">Out-patient Department (OPD)</option>
												<option value="Laboratory">Laboratory</option>
												<option value="Maternity">Maternity</option>
												<option value="Nutrition and Dietetics">Nutrition and Dietetics</option>
												<option value="Obstetrics and Gynecology">Obstetrics and Gynecology</option>
												<option value="Pharmacy">Pharmacy</option>
											</select>
										</div>

										<div className="col-md-4">
											<label className="form-label" htmlFor="to_see">
												Doctor / Nurse
											</label>
											<select
												id="to_see"
												className="form-select"
												value={form.to_see}
												onChange={(e) => {
													setform({ ...form, to_see: e.target.value });
												}}
												name="to_see"
												aria-label="doctor/nurse"
												required>
												<option value="" hidden></option>
												<option value="Doctor">Doctor</option>
												<option value="Nurse">Nurse</option>
											</select>
										</div>

										<div className="col-12">
											<div className="mb-3">
												<label className="form-label" htmlFor="description">
													Description
												</label>
												<textarea
													className="form-control"
													value={form.description}
													onChange={(e) => {
														setform({ ...form, description: e.target.value });
													}}
													name="description"
													id="description"
													rows="3"></textarea>
											</div>
										</div>

										<div className="col-12">
											<button type="submit" disabled={isSubmitting} className="btn btn-primary">
												{!isSubmitting ? <span>Create appointment</span> : <span>Loading...</span>}
											</button>
										</div>
									</form>
								</div>
							</div>
							<Modal show={openMoal} onHide={() => setopenMoal(false)} backdrop="static" keyboard={false} centered>
								<Modal.Header closeButton={true} className="border-0"></Modal.Header>
								<Modal.Body className="text-center">
									<img className="rounded-circle" width={"80"} src={`${process.env.PUBLIC_URL}/assets/img/icons/${modalInfo.image}`} alt={modalInfo.image} />
									<div className="my-4">
										<h5>{modalInfo.title}</h5>
										<p className="">{modalInfo.subTitle}</p>

										<div className="d-flex justify-content-center border p-3 w-75 mx-auto">
											<span className="m-0 me-2">Queue No:</span>
											<h5>{visitNumber}</h5>
										</div>
									</div>
									<div className="my-4">
										{/* <button className="btn btn-falcon-primary me-2" onClick={handleCloseModal}>
												Add another visit
											</button> */}
										<Link className="btn btn-primary" to={`../appointment/${visitNumber}/vitals`}>
											Continue
										</Link>
									</div>
								</Modal.Body>
							</Modal>
						</>
					) : (
						<div className="card">
							<div className="card-body overflow-hidden p-lg-6">
								<div className="row align-items-center">
									<div className="col-lg-6">
										<img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/img/icons/spot-illustrations/21.png`} alt="" />
									</div>
									<div className="col-lg-6 ps-lg-4 my-5 text-center text-lg-start">
										<h4 className="text-black">
											Patient with id number: <br />
											{pid} not found!
										</h4>
										<p className="lead">Create an appointment from the patients list.</p>
										<Link className="btn btn-outline-secondary" to={`../patients/all`}>
											Go to patients
										</Link>
									</div>
								</div>
							</div>
						</div>
					)}
				</Fade>
			)}
		</>
	);
};

export default CreateVisit;
