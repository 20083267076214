import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Register from "./components/authentication/Register";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/authentication/Login";
import Dashboard from "./components/dashboard/Dashboard";
import ProtectedRoutes from "./components/ProtectedRoute";
import AuthPages from "./components/AuthPages";
import Facilities from "./components/facilities/Facilities";
import CreateFacility from "./components/facilities/CreateFacility";
import store from "./app/store";
import { Provider } from "react-redux";
import NotFound from "./components/NotFound";
import NotFoundDashboard from "./components/dashboard/NotFoundDashboard";
import ExpiredToken from "./components/ExpiredToken";
import FacilitySettings from "./components/facilities/FacilitySettings";

import Overview from "./components/dashboard/Overview";
import AllPatients from "./components/dashboard/patients/AllPatients";
import RegisterPatient from "./components/dashboard/patients/RegisterPatient";
import CreateVisit from "./components/dashboard/visits/CreateVisit";
import AllVisits from "./components/dashboard/visits/AllVisits";
import Visit from "./components/dashboard/visits/Visit";
import Billing from "./components/dashboard/accounts/billing";
// import Pharmacy from "./components/dashboard/inventory/Pharmacy";
import InventoryItems from "./components/dashboard/inventory/InventoryItems";
import AddInventoryItem from "./components/dashboard/inventory/AddInventoryItem";
import CreateStaff from "./components/dashboard/staff/CreateStaff";
import AllStaff from "./components/dashboard/staff/AllStaff";
import Vitals from "./components/dashboard/visits/subcomponents/Vitals";
import MedicalRecords from "./components/dashboard/visits/subcomponents/MedicalRecords";
import History from "./components/dashboard/visits/subcomponents/History";
import Diagnosis from "./components/dashboard/visits/subcomponents/Diagnosis";
import PrescriptionMedicine from "./components/dashboard/visits/subcomponents/PrescriptionMedicine";

const root = ReactDOM.createRoot(document.getElementById("top"));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<Routes>
					<Route element={<ProtectedRoutes />}>
						<Route exact path="/" element={<Navigate to="/facilities" />} />
						<Route exact path="/facilities" element={<Facilities />} />
						<Route exact path="/facility/new" element={<CreateFacility />} />
						<Route exact path="/facility/:fid/:facilityName/settings" element={<FacilitySettings />} />
						<Route exact path="/:fid/:facilityName" element={<Dashboard />}>
							<Route path="" element={<Overview />} />

							{/* Patients route */}
							<Route exact path="patients/all" element={<AllPatients />} />
							<Route exact path="patient/new" element={<RegisterPatient />} />
							<Route path="patient/:pid" element={<RegisterPatient />} />

							{/* Appointment route */}
							<Route path="appointments/all" element={<AllVisits />} />
							<Route path="appointment/new/:pid" element={<CreateVisit />} />
							<Route path="appointment/:visit_number" element={<Visit />}>
								<Route path="vitals" element={<Vitals />} />
								<Route path="diagnosis" element={<Diagnosis />} />
								<Route path="medical-records" element={<MedicalRecords />} />
								<Route path="prescription-medicine" element={<PrescriptionMedicine />} />
								<Route path="history" element={<History />} />
							</Route>

							{/* Accounts route */}
							<Route path="billing" element={<Billing />} />

							{/* Inventory route */}
							<Route exact path="inventory/:category" element={<InventoryItems />} />
							<Route exact path="inventory/:category/new" element={<AddInventoryItem />} />

							{/* Staff route */}
							<Route path="staff/all" element={<AllStaff />} />
							<Route path="staff/new" element={<CreateStaff />} />
							<Route path="*" element={<NotFoundDashboard />} />
						</Route>
						{/* <Route exact path="/dashboard/:fid/:facilityName/*" element={<Dashboard />} /> */}
					</Route>
					<Route element={<AuthPages />}>
						<Route exact path="/login" element={<Login />} />
						<Route exact path="/register" element={<Register />} />
						<Route exact path="/expired-session" element={<ExpiredToken />} />
					</Route>
					<Route exact path="/404" element={<NotFound />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
