import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { isExpired } from "react-jwt";
import { Fade } from "react-awesome-reveal";
import { useDispatch } from "react-redux";
import { fetchUser } from "../app/slices/userSlice";
import { useEffect, useState } from "react";

const ProtectedRoutes = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const [isValidToken, setisValidToken] = useState(false);

	const checkSession = () => {
		const token = JSON.parse(localStorage?.getItem("APOMUDIN_USER_TOKEN"));
		if (token) {
			const tokenExpired = isExpired(token);
			if (!tokenExpired) {
				setisValidToken(true);
				dispatch(fetchUser());
			} else {
				setisValidToken(false);
				localStorage.removeItem("APOMUDIN_USER_TOKEN");
				localStorage.removeItem("EXPIRES_IN");
				window.location.href = "/expired-session";
			}
		} else {
			localStorage.removeItem("APOMUDIN_USER_TOKEN");
			localStorage.removeItem("EXPIRES_IN");
			window.location.href = "/expired-session";
		}
	};

	useEffect(() => {
		checkSession();
	}, [location.pathname]);

	return (
		<>
			{isValidToken && (
				<Fade>
					<Outlet />
				</Fade>
			)}
		</>
	);
};

export default ProtectedRoutes;
