import { Link } from "react-router-dom";
const BreadCrumb = (props) => {
  return (
    <>
      <nav className="mb-3" style={{ "--falcon-breadcrumb-divider": "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%23748194'/%3E%3C/svg%3E&#34)" }} aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link className="text-capitalize" to={"/" + props.pathName}>
              {props.pathName}
            </Link>
          </li>
          <li className="breadcrumb-item active text-capitalize" aria-current="page">
            {props.to}
          </li>
        </ol>
      </nav>
    </>
  );
};

export default BreadCrumb;