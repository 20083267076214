import SideNav from "./SideNav";
import TopNav from "../TopNav";
import Footer from "../Footer";
import NavRight from "./NavRight";
import { useEffect } from "react";
import { Fade } from "react-awesome-reveal";

const Layout = ({ children }) => {
	useEffect(() => {
		return () => {
			var isFluid = JSON.parse(localStorage.getItem("isFluid"));
			if (isFluid) {
				var container = document.querySelector("[data-layout]");
				container.classList.remove("container");
				container.classList.add("container-fluid");
			}
		};
	}, []);

	return (
		<>
			<Fade>
				<div className="container-fluid" data-layout="container">
					<SideNav />
					<div className="content">
						<TopNav />
						<div>{children}</div>
						<Footer />
					</div>
				</div>
				<NavRight />
			</Fade>
		</>
	);
};

export default Layout;
