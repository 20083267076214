import { Modal } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";

const TriageModal = ({ showTriageModal, setShowTriageModal }) => {
	return (
		<>
			<Modal show={showTriageModal} onHide={() => setShowTriageModal(false)} size="lg" keyboard={false}>
				<Modal.Header closeButton className="border-0">
					TRIAGE COLOR
				</Modal.Header>
				<Modal.Body className="text-center">
					<ul className="nav nav-pills" id="pill-tews" role="tablist">
						<li className="nav-item">
							<a className="nav-link active" id="ctg-tab" data-bs-toggle="tab" href="#ctg" role="tab" aria-controls="ctg" aria-selected="true">
								CTG Discriminator List
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="adult-tews-tab" data-bs-toggle="tab" href="#adult-tews" role="tab" aria-controls="adult-tews" aria-selected="false">
								Adult TEWS
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="older-child-tews-tab" data-bs-toggle="tab" href="#older-child-tews" role="tab" aria-controls="older-child-tews" aria-selected="false">
								Older Child TEWS
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="younger-child-tews-tab" data-bs-toggle="tab" href="#younger-child-tews" role="tab" aria-controls="younger-child-tews" aria-selected="false">
								Younger Child TEWS
							</a>
						</li>
					</ul>
					<div className="tab-content border p-3 mt-3" id="pill-myTabContent">
						<div className="tab-pane fade show active" id="ctg" role="tabpanel" aria-labelledby="ctg-tab">
							<Fade>
								<img className="" width={"100%"} src={process.env.PUBLIC_URL + "/assets/img/tews/tews-score.png"} alt="" />
							</Fade>
						</div>
						<div className="tab-pane fade" id="adult-tews" role="tabpanel" aria-labelledby="profile-tab">
							<img className="" width={"100%"} src={process.env.PUBLIC_URL + "/assets/img/tews/adult-tews.png"} alt="" />
						</div>
						<div className="tab-pane fade" id="older-child-tews" role="tabpanel" aria-labelledby="contact-tab">
							<img className="" width={"100%"} src={process.env.PUBLIC_URL + "/assets/img/tews/older-child-tews.png"} alt="" />
						</div>
						<div className="tab-pane fade" id="younger-child-tews" role="tabpanel" aria-labelledby="younger-child-tab">
							<img className="" width={"100%"} src={process.env.PUBLIC_URL + "/assets/img/tews/younger-child-tews.png"} alt="" />
						</div>
					</div>
					<div className="text-start mt-3">
						<div className="row">
							<div className="col-12">
								<span className="fw-bold">TEWS</span> = Triage Early Warning Score
							</div>
						</div>
						<div className="row">
							<div className="col-md-5">
								<span className="fw-bold"> RR</span> = Respiratory Rate
							</div>
							<div className="col-md-5">
								<span className="fw-bold"> HR</span> = Heart Rate
							</div>
							<div className="col-md-5">
								<span className="fw-bold"> SBP</span>= Systolic Blood Pressure
							</div>
							<div className="col-md-5">
								<span className="fw-bold"> AVPU </span>= Alert, Verbal, Pain, Unconscious
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default TriageModal;
