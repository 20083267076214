import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const EmptyState = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="position-absolute-center w-100">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 text-center">
            <img className="img-fluid" src="../assets/img/icons/spot-illustrations/21.png" width="200" alt="" />
            <h3 className="text-primary">No facilities under your belt yet.</h3>
            <p className="lead">Facilities you own and work with, will show here.</p>
            <Link to="/facility/new" className="btn btn-falcon-primary">
              <i className="fa fa-plus"></i> Add new facility
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyState;
