import { createSlice } from "@reduxjs/toolkit";

export const roleSlice = createSlice({
  name: "role",
  initialState: {
    value: "",
  },
  reducers: {
    updateRole: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateRole } = roleSlice.actions;

export default roleSlice.reducer;
