// import axios from "./axios";
// import cookie from "react-cookies";
import requester from "./requester";

const encrypted = () => {
	return requester.get("/encrypted");
};

const registerUser = async (formData) => {
	return requester.post("/register", formData);
};

const googleAuth = async (formData) => {
	return requester.post("/google-auth", formData);
};

const loginUser = (formData) => {
	// return requester.post("/login", formData);
	return new Promise(async (resolve, reject) => {
		try {
			const response = await requester.post("/login", formData);
			resolve(response.data);
		} catch (error) {
			reject(error);
		}
	});
};

const logoutUser = async () => {
	const token = JSON.parse(localStorage?.getItem("APOMUDIN_USER_TOKEN"));
	try {
		const response = await requester.get("/logout?token=" + token);
		if (response.data.success) {
			localStorage.removeItem("APOMUDIN_USER_TOKEN");
			localStorage.removeItem("EXPIRES_IN");
			window.location.href = "/login"; //reload();
		}
	} catch (error) {
		console.log(error.response);
	}
};

const authService = {
	registerUser,
	loginUser,
	logoutUser,
	googleAuth,
	encrypted,
};

export default authService;
