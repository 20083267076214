import axios from "axios";
import { isExpired } from "react-jwt";
// import cookie from "react-cookies";

//pass new generated access token here
export const headers = () => {
	const token = JSON.parse(localStorage?.getItem("APOMUDIN_USER_TOKEN"));

	if (token) {
		const tokenExpired = isExpired(token);
		if (!tokenExpired) {
			const auth = `Bearer ${token}`;
			return {
				Authorization: auth,
				"Content-Type": "application/json",
			};
		} else {
			localStorage.removeItem("APOMUDIN_USER_TOKEN");
			localStorage.removeItem("EXPIRES_IN");
			window.location.href = "/expired-session"; //login
		}
	} else {
		return {
			"Content-Type": "application/json",
			Accept: "application/json",
		};
	}
};

const env = process.env.NODE_ENV;
const baseEndpoint = "https://dev.apomudin.com/api"; // env === "development" ? "http://localhost:8000/api" : "https://dev.apomudin.com/api";

const axiosApi = axios.create({
	baseURL: baseEndpoint,
	headers: headers(),
});

axiosApi.interceptors.response.use(
	(response) => {
		if (response.data.status === "Token is Expired") {
			localStorage.removeItem("APOMUDIN_USER_TOKEN");
			localStorage.removeItem("EXPIRES_IN");
			window.location.href = "/expired-session"; //login
		}
		return response;
	},
	(error) => {
		if (error && error.response && error.response.status === 401) {
			window.location?.reload();
			localStorage.removeItem("APOMUDIN_USER_TOKEN");
			localStorage.removeItem("EXPIRES_IN");
		}
		return Promise.reject(error);
	}
);

export async function get(url, config = { headers: headers() }) {
	return await axiosApi
		.get(url, { ...config })
		.then((response) => response.data)
		.catch((error) => error);
}

export async function post(url, data, config = { headers: headers() }) {
	return axiosApi.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function put(url, data, config = { headers: headers() }) {
	return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url, config = { headers: headers() }) {
	return await axiosApi.delete(url, { ...config }).then((response) => response.data);
}

export async function patch(url, config = { headers: headers() }) {
	return await axiosApi.patch(url, { ...config }).then((response) => response.data);
}

export default axiosApi;
