const SearchFormCard = ({value,setFunction}) => {
    return (
			<>
				<div className="h-100 ms-auto">
					<form onSubmit={(e)=>e.preventDefault()}>
						<div className="input-group">
							<input
								className="form-control form-control-sm shadow-none search"
								type="search"
								placeholder="Search for a patient"
								aria-label="search"
								value={value}
								onChange={(e) => {
									setFunction(e.target.value);
								}}
							/>
							<div className="input-group-text bg-white">
								<span className="fas fa-filter fs--1 text-muted"></span>
							</div>
						</div>
					</form>
				</div>
			</>
		);
}
 
export default SearchFormCard;