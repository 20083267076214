import PageHeader from "../PageHeader";
import { NavLink, Outlet, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import visitService from "../../../helpers/visitService";
import Loader from "../../Loader";
import TriageModal from "../TriageModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment/moment";
import { Fade } from "react-awesome-reveal";

const Visit = () => {
	const navigate = useNavigate();
	const { visit_number, fid } = useParams();
	const [isLoading, setisLoading] = useState(true);
	const [visit, setvisit] = useState({});
	const [vitals, setvitals] = useState({});
	const [patient, setpatient] = useState({});
	const [showTriageModal, setshowTriageModal] = useState(false);

	const getVisit = () => {
		visitService
			.getVisit({ visit_number: visit_number, fid: fid })
			.then((response) => {
				if (response.data.success) {
					setvisit(response.data.data.visit);
					setpatient(response.data.data.patient);
					setisLoading(false);
				}

				if (!response.data.success) {
					navigate("../visits/all");
				}
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	};

	useEffect(() => {
		getVisit();
	}, []);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<PageHeader title="Appointments" subTitle={visit_number} />
					<div className="card mb-3">
						<div className="card-body">
							<div>
								<div className="d-flex align-items-center mb-2">
									<div className="avatar avatar-xl me-2">
										<img
											className="rounded-circle"
											src={process.env.PUBLIC_URL + "/assets/img/team/1-thumb.png"}
											alt=""
										/>
									</div>
									<h6 className="m-0 text-capitalize">{patient.full_name}</h6>
								</div>

								<div className="row my-1">
									<div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
										<p className="mb-0 fs--1">
											<strong>Folder No: </strong>
											{patient.pid}
										</p>
									</div>

									<div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
										<p className="mb-0 fs--1">
											<strong>Time In: </strong>
											{moment(visit.created_at).format("DD MMM, Y - hh:mm A")}
										</p>
									</div>

									<div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
										<p className="mb-0 fs--1 text-capitalize">
											<strong>Gender: </strong>
											{patient.gender}
										</p>
									</div>
								</div>
								<div className="row my-1">
									<div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
										<p className="mb-0 fs--1 text-capitalize">
											<strong>Visit Type: </strong>
											{visit.visit_type}
										</p>
									</div>

									<div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
										<p className="mb-0 fs--1">
											<strong>Age: </strong>
											{patient.age}
										</p>
									</div>

									<div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
										<p className="mb-0 fs--1 text-capitalize">
											<strong>Department: </strong>
											{visit.department}
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
										<p className="mb-0 fs--1 text-capitalize">
											<strong>To See: </strong>
											{visit.to_see}
										</p>
									</div>

									<div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
										<p className="mb-0 fs--1 text-capitalize">
											<strong>Status: </strong>
											{visit.status}
										</p>
									</div>

									<div className="col-md-6 col-lg-4 mb-2 mb-lg-0">
										<p className="mb-0 fs--1">
											<strong>Care Type: </strong>
											{visit.care_type}
										</p>
									</div>

									{/* <div className="col-md-6 col-lg-6">
										<div className="d-flex">
											<div className="me-3">
												<h6 className="mb-0">Triage Score</h6>
												<p className="mb-0 fs--1">Should be treated in less than 10 minutes</p>
											</div>
											<OverlayTrigger key={3} placement="top" overlay={<Tooltip id={"3"}>More about Triage Score</Tooltip>}>
												<div className="card bg-triage-green shadow-none d-flex text-center p-1 tews" onClick={() => setshowTriageModal(true)}>
													<span className="m-auto">3</span>
												</div>
											</OverlayTrigger>
										</div>
									</div> */}
								</div>
							</div>
						</div>
						{vitals.tews && (
							<Fade>
								<OverlayTrigger
									key={vitals.tews.score}
									placement="top"
									overlay={<Tooltip id={vitals.tews.score}>More about Triage Score</Tooltip>}
								>
									<div
										className={`card-footer bg-triage-${vitals.tews.color} tews`}
										onClick={() => setshowTriageModal(true)}
									>
										<p className="mb-0 fs--1">
											<strong>Triage Score: </strong>
											<span>{vitals.tews.score}</span> - <span>{vitals.tews.description}</span>
										</p>
									</div>
								</OverlayTrigger>
							</Fade>
						)}
					</div>
					<div className="row g-2">
						<div className="col-lg-3">
							<div className="sticky-sidebar">
								<div className="bg-white dark__bg-1000 rounded-2 py-2">
									<span className="dropdown-item fw-bold">Treatment Details</span>
									<div className="dropdown-divider"></div>
									<NavLink className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`} to="vitals">
										Vitals
									</NavLink>
									<NavLink
										className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`}
										to="nurse-assessment"
									>
										Nurse Assessment
									</NavLink>
									<NavLink className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`} to="diagnosis">
										Diagnosis
									</NavLink>

									<NavLink className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`} to="allergies">
										Allergies
									</NavLink>
									<NavLink
										className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`}
										to="current-medication"
									>
										Current Medication
									</NavLink>
									<NavLink
										className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`}
										to="presenting-complaint"
									>
										Presenting Complaint
									</NavLink>
									<NavLink
										className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`}
										to="history-and-examination"
									>
										History and Examination
									</NavLink>

									<NavLink className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`} to="investigation">
										Investigation/Procedure
									</NavLink>
									<NavLink
										className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`}
										to="prescription-medicine"
									>
										Prescription Medicine
									</NavLink>
									<NavLink className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`} to="advice">
										Advice
									</NavLink>
									<NavLink
										className={({ isActive }) => `dropdown-item ${isActive ? "active" : ""}`}
										to="medical-certificate"
									>
										Medical Certificate
									</NavLink>
									<div className="dropdown-divider"></div>
									<a className="dropdown-item" href="../../pages/user/settings.html">
										Medical Records
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-9">
							<Outlet context={{ setvitals, patient }} />
						</div>
					</div>
					<TriageModal showTriageModal={showTriageModal} setShowTriageModal={setshowTriageModal} />
				</>
			)}
		</>
	);
};

export default Visit;
