const nationalities = () => {
	return [
		"afghan",
		"albanian",
		"algerian",
		"american",
		"andorran",
		"angolan",
		"antiguans",
		"argentinean",
		"armenian",
		"australian",
		"austrian",
		"azerbaijani",
		"bahamian",
		"bahraini",
		"bangladeshi",
		"barbadian",
		"barbudans",
		"batswana",
		"belarusian",
		"belgian",
		"belizean",
		"beninese",
		"bhutanese",
		"bolivian",
		"bosnian",
		"brazilian",
		"british",
		"bruneian",
		"bulgarian",
		"burkinabe",
		"burmese",
		"burundian",
		"cambodian",
		"cameroonian",
		"canadian",
		"cape verdean",
		"central african",
		"chadian",
		"chilean",
		"chinese",
		"colombian",
		"comoran",
		"congolese",
		"costa rican",
		"croatian",
		"cuban",
		"cypriot",
		"czech",
		"danish",
		"djibouti",
		"dominican",
		"dutch",
		"east timorese",
		"ecuadorean",
		"egyptian",
		"emirian",
		"equatorial guinean",
		"eritrean",
		"estonian",
		"ethiopian",
		"fijian",
		"filipino",
		"finnish",
		"french",
		"gabonese",
		"gambian",
		"georgian",
		"german",
		"ghanaian",
		"greek",
		"grenadian",
		"guatemalan",
		"guinea-bissauan",
		"guinean",
		"guyanese",
		"haitian",
		"herzegovinian",
		"honduran",
		"hungarian",
		"icelander",
		"indian",
		"indonesian",
		"iranian",
		"iraqi",
		"irish",
		"israeli",
		"italian",
		"ivorian",
		"jamaican",
		"japanese",
		"jordanian",
		"kazakhstani",
		"kenyan",
		"kittian and nevisian",
		"kuwaiti",
		"kyrgyz",
		"laotian",
		"latvian",
		"lebanese",
		"liberian",
		"libyan",
		"liechtensteiner",
		"lithuanian",
		"luxembourger",
		"macedonian",
		"malagasy",
		"malawian",
		"malaysian",
		"maldivan",
		"malian",
		"maltese",
		"marshallese",
		"mauritanian",
		"mauritian",
		"mexican",
		"micronesian",
		"moldovan",
		"monacan",
		"mongolian",
		"moroccan",
		"mosotho",
		"motswana",
		"mozambican",
		"namibian",
		"nauruan",
		"nepalese",
		"new zealander",
		"ni-vanuatu",
		"nicaraguan",
		"nigerien",
		"north korean",
		"northern irish",
		"norwegian",
		"omani",
		"pakistani",
		"palauan",
		"panamanian",
		"papua new guinean",
		"paraguayan",
		"peruvian",
		"polish",
		"portuguese",
		"qatari",
		"romanian",
		"russian",
		"rwandan",
		"saint lucian",
		"salvadoran",
		"samoan",
		"san marinese",
		"sao tomean",
		"saudi",
		"scottish",
		"senegalese",
		"serbian",
		"seychellois",
		"sierra leonean",
		"singaporean",
		"slovakian",
		"slovenian",
		"solomon islander",
		"somali",
		"south african",
		"south korean",
		"spanish",
		"sri lankan",
		"sudanese",
		"surinamer",
		"swazi",
		"swedish",
		"swiss",
		"syrian",
		"taiwanese",
		"tajik",
		"tanzanian",
		"thai",
		"togolese",
		"tongan",
		"trinidadian or tobagonian",
		"tunisian",
		"turkish",
		"tuvaluan",
		"ugandan",
		"ukrainian",
		"uruguayan",
		"uzbekistani",
		"venezuelan",
		"vietnamese",
		"welsh",
		"yemenite",
		"zambian",
		"zimbabwean",
	];
};

const countries = () => {
	return [
		{
			id: 1,
			name: "Afghanistan",
			code: "AF",
			phone: "93",
			symbol: "؋",
			created_at: null,
			updated_at: null,
		},
		{
			id: 2,
			name: "Aland Islands",
			code: "AX",
			phone: "358",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 3,
			name: "Albania",
			code: "AL",
			phone: "355",
			symbol: "Lek",
			created_at: null,
			updated_at: null,
		},
		{
			id: 4,
			name: "Algeria",
			code: "DZ",
			phone: "213",
			symbol: "دج",
			created_at: null,
			updated_at: null,
		},
		{
			id: 5,
			name: "American Samoa",
			code: "AS",
			phone: "1684",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 6,
			name: "Andorra",
			code: "AD",
			phone: "376",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 7,
			name: "Angola",
			code: "AO",
			phone: "244",
			symbol: "Kz",
			created_at: null,
			updated_at: null,
		},
		{
			id: 8,
			name: "Anguilla",
			code: "AI",
			phone: "1264",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 9,
			name: "Antarctica",
			code: "AQ",
			phone: "672",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 10,
			name: "Antigua and Barbuda",
			code: "AG",
			phone: "1268",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 11,
			name: "Argentina",
			code: "AR",
			phone: "54",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 12,
			name: "Armenia",
			code: "AM",
			phone: "374",
			symbol: "֏",
			created_at: null,
			updated_at: null,
		},
		{
			id: 13,
			name: "Aruba",
			code: "AW",
			phone: "297",
			symbol: "ƒ",
			created_at: null,
			updated_at: null,
		},
		{
			id: 14,
			name: "Australia",
			code: "AU",
			phone: "61",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 15,
			name: "Austria",
			code: "AT",
			phone: "43",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 16,
			name: "Azerbaijan",
			code: "AZ",
			phone: "994",
			symbol: "m",
			created_at: null,
			updated_at: null,
		},
		{
			id: 17,
			name: "Bahamas",
			code: "BS",
			phone: "1242",
			symbol: "B$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 18,
			name: "Bahrain",
			code: "BH",
			phone: "973",
			symbol: ".د.ب",
			created_at: null,
			updated_at: null,
		},
		{
			id: 19,
			name: "Bangladesh",
			code: "BD",
			phone: "880",
			symbol: "৳",
			created_at: null,
			updated_at: null,
		},
		{
			id: 20,
			name: "Barbados",
			code: "BB",
			phone: "1246",
			symbol: "Bds$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 21,
			name: "Belarus",
			code: "BY",
			phone: "375",
			symbol: "Br",
			created_at: null,
			updated_at: null,
		},
		{
			id: 22,
			name: "Belgium",
			code: "BE",
			phone: "32",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 23,
			name: "Belize",
			code: "BZ",
			phone: "501",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 24,
			name: "Benin",
			code: "BJ",
			phone: "229",
			symbol: "CFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 25,
			name: "Bermuda",
			code: "BM",
			phone: "1441",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 26,
			name: "Bhutan",
			code: "BT",
			phone: "975",
			symbol: "Nu.",
			created_at: null,
			updated_at: null,
		},
		{
			id: 27,
			name: "Bolivia",
			code: "BO",
			phone: "591",
			symbol: "Bs.",
			created_at: null,
			updated_at: null,
		},
		{
			id: 28,
			name: "Bonaire, Sint Eustatius and Saba",
			code: "BQ",
			phone: "599",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 29,
			name: "Bosnia and Herzegovina",
			code: "BA",
			phone: "387",
			symbol: "KM",
			created_at: null,
			updated_at: null,
		},
		{
			id: 30,
			name: "Botswana",
			code: "BW",
			phone: "267",
			symbol: "P",
			created_at: null,
			updated_at: null,
		},
		{
			id: 31,
			name: "Bouvet Island",
			code: "BV",
			phone: "55",
			symbol: "kr",
			created_at: null,
			updated_at: null,
		},
		{
			id: 32,
			name: "Brazil",
			code: "BR",
			phone: "55",
			symbol: "R$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 33,
			name: "British Indian Ocean Territory",
			code: "IO",
			phone: "246",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 34,
			name: "Brunei Darussalam",
			code: "BN",
			phone: "673",
			symbol: "B$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 35,
			name: "Bulgaria",
			code: "BG",
			phone: "359",
			symbol: "Лв.",
			created_at: null,
			updated_at: null,
		},
		{
			id: 36,
			name: "Burkina Faso",
			code: "BF",
			phone: "226",
			symbol: "CFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 37,
			name: "Burundi",
			code: "BI",
			phone: "257",
			symbol: "FBu",
			created_at: null,
			updated_at: null,
		},
		{
			id: 38,
			name: "Cambodia",
			code: "KH",
			phone: "855",
			symbol: "KHR",
			created_at: null,
			updated_at: null,
		},
		{
			id: 39,
			name: "Cameroon",
			code: "CM",
			phone: "237",
			symbol: "FCFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 40,
			name: "Canada",
			code: "CA",
			phone: "1",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 41,
			name: "Cape Verde",
			code: "CV",
			phone: "238",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 42,
			name: "Cayman Islands",
			code: "KY",
			phone: "1345",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 43,
			name: "Central African Republic",
			code: "CF",
			phone: "236",
			symbol: "FCFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 44,
			name: "Chad",
			code: "TD",
			phone: "235",
			symbol: "FCFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 45,
			name: "Chile",
			code: "CL",
			phone: "56",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 46,
			name: "China",
			code: "CN",
			phone: "86",
			symbol: "¥",
			created_at: null,
			updated_at: null,
		},
		{
			id: 47,
			name: "Christmas Island",
			code: "CX",
			phone: "61",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 48,
			name: "Cocos (Keeling) Islands",
			code: "CC",
			phone: "672",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 49,
			name: "Colombia",
			code: "CO",
			phone: "57",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 50,
			name: "Comoros",
			code: "KM",
			phone: "269",
			symbol: "CF",
			created_at: null,
			updated_at: null,
		},
		{
			id: 51,
			name: "Congo",
			code: "CG",
			phone: "242",
			symbol: "FC",
			created_at: null,
			updated_at: null,
		},
		{
			id: 52,
			name: "Congo, Democratic Republic of the Congo",
			code: "CD",
			phone: "242",
			symbol: "FC",
			created_at: null,
			updated_at: null,
		},
		{
			id: 53,
			name: "Cook Islands",
			code: "CK",
			phone: "682",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 54,
			name: "Costa Rica",
			code: "CR",
			phone: "506",
			symbol: "₡",
			created_at: null,
			updated_at: null,
		},
		{
			id: 55,
			name: "Cote D'Ivoire",
			code: "CI",
			phone: "225",
			symbol: "CFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 56,
			name: "Croatia",
			code: "HR",
			phone: "385",
			symbol: "kn",
			created_at: null,
			updated_at: null,
		},
		{
			id: 57,
			name: "Cuba",
			code: "CU",
			phone: "53",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 58,
			name: "Curacao",
			code: "CW",
			phone: "599",
			symbol: "ƒ",
			created_at: null,
			updated_at: null,
		},
		{
			id: 59,
			name: "Cyprus",
			code: "CY",
			phone: "357",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 60,
			name: "Czech Republic",
			code: "CZ",
			phone: "420",
			symbol: "Kč",
			created_at: null,
			updated_at: null,
		},
		{
			id: 61,
			name: "Denmark",
			code: "DK",
			phone: "45",
			symbol: "Kr.",
			created_at: null,
			updated_at: null,
		},
		{
			id: 62,
			name: "Djibouti",
			code: "DJ",
			phone: "253",
			symbol: "Fdj",
			created_at: null,
			updated_at: null,
		},
		{
			id: 63,
			name: "Dominica",
			code: "DM",
			phone: "1767",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 64,
			name: "Dominican Republic",
			code: "DO",
			phone: "1809",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 65,
			name: "Ecuador",
			code: "EC",
			phone: "593",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 66,
			name: "Egypt",
			code: "EG",
			phone: "20",
			symbol: "ج.م",
			created_at: null,
			updated_at: null,
		},
		{
			id: 67,
			name: "El Salvador",
			code: "SV",
			phone: "503",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 68,
			name: "Equatorial Guinea",
			code: "GQ",
			phone: "240",
			symbol: "FCFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 69,
			name: "Eritrea",
			code: "ER",
			phone: "291",
			symbol: "Nfk",
			created_at: null,
			updated_at: null,
		},
		{
			id: 70,
			name: "Estonia",
			code: "EE",
			phone: "372",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 71,
			name: "Ethiopia",
			code: "ET",
			phone: "251",
			symbol: "Nkf",
			created_at: null,
			updated_at: null,
		},
		{
			id: 72,
			name: "Falkland Islands (Malvinas)",
			code: "FK",
			phone: "500",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 73,
			name: "Faroe Islands",
			code: "FO",
			phone: "298",
			symbol: "Kr.",
			created_at: null,
			updated_at: null,
		},
		{
			id: 74,
			name: "Fiji",
			code: "FJ",
			phone: "679",
			symbol: "FJ$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 75,
			name: "Finland",
			code: "FI",
			phone: "358",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 76,
			name: "France",
			code: "FR",
			phone: "33",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 77,
			name: "French Guiana",
			code: "GF",
			phone: "594",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 78,
			name: "French Polynesia",
			code: "PF",
			phone: "689",
			symbol: "₣",
			created_at: null,
			updated_at: null,
		},
		{
			id: 79,
			name: "French Southern Territories",
			code: "TF",
			phone: "262",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 80,
			name: "Gabon",
			code: "GA",
			phone: "241",
			symbol: "FCFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 81,
			name: "Gambia",
			code: "GM",
			phone: "220",
			symbol: "D",
			created_at: null,
			updated_at: null,
		},
		{
			id: 82,
			name: "Georgia",
			code: "GE",
			phone: "995",
			symbol: "ლ",
			created_at: null,
			updated_at: null,
		},
		{
			id: 83,
			name: "Germany",
			code: "DE",
			phone: "49",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 84,
			name: "Ghana",
			code: "GH",
			phone: "233",
			symbol: "GH₵",
			created_at: null,
			updated_at: null,
		},
		{
			id: 85,
			name: "Gibraltar",
			code: "GI",
			phone: "350",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 86,
			name: "Greece",
			code: "GR",
			phone: "30",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 87,
			name: "Greenland",
			code: "GL",
			phone: "299",
			symbol: "Kr.",
			created_at: null,
			updated_at: null,
		},
		{
			id: 88,
			name: "Grenada",
			code: "GD",
			phone: "1473",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 89,
			name: "Guadeloupe",
			code: "GP",
			phone: "590",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 90,
			name: "Guam",
			code: "GU",
			phone: "1671",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 91,
			name: "Guatemala",
			code: "GT",
			phone: "502",
			symbol: "Q",
			created_at: null,
			updated_at: null,
		},
		{
			id: 92,
			name: "Guernsey",
			code: "GG",
			phone: "44",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 93,
			name: "Guinea",
			code: "GN",
			phone: "224",
			symbol: "FG",
			created_at: null,
			updated_at: null,
		},
		{
			id: 94,
			name: "Guinea-Bissau",
			code: "GW",
			phone: "245",
			symbol: "CFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 95,
			name: "Guyana",
			code: "GY",
			phone: "592",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 96,
			name: "Haiti",
			code: "HT",
			phone: "509",
			symbol: "G",
			created_at: null,
			updated_at: null,
		},
		{
			id: 97,
			name: "Heard Island and Mcdonald Islands",
			code: "HM",
			phone: "0",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 98,
			name: "Holy See (Vatican City State)",
			code: "VA",
			phone: "39",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 99,
			name: "Honduras",
			code: "HN",
			phone: "504",
			symbol: "L",
			created_at: null,
			updated_at: null,
		},
		{
			id: 100,
			name: "Hong Kong",
			code: "HK",
			phone: "852",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 101,
			name: "Hungary",
			code: "HU",
			phone: "36",
			symbol: "Ft",
			created_at: null,
			updated_at: null,
		},
		{
			id: 102,
			name: "Iceland",
			code: "IS",
			phone: "354",
			symbol: "kr",
			created_at: null,
			updated_at: null,
		},
		{
			id: 103,
			name: "India",
			code: "IN",
			phone: "91",
			symbol: "₹",
			created_at: null,
			updated_at: null,
		},
		{
			id: 104,
			name: "Indonesia",
			code: "ID",
			phone: "62",
			symbol: "Rp",
			created_at: null,
			updated_at: null,
		},
		{
			id: 105,
			name: "Iran, Islamic Republic of",
			code: "IR",
			phone: "98",
			symbol: "﷼",
			created_at: null,
			updated_at: null,
		},
		{
			id: 106,
			name: "Iraq",
			code: "IQ",
			phone: "964",
			symbol: "د.ع",
			created_at: null,
			updated_at: null,
		},
		{
			id: 107,
			name: "Ireland",
			code: "IE",
			phone: "353",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 108,
			name: "Isle of Man",
			code: "IM",
			phone: "44",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 109,
			name: "Israel",
			code: "IL",
			phone: "972",
			symbol: "₪",
			created_at: null,
			updated_at: null,
		},
		{
			id: 110,
			name: "Italy",
			code: "IT",
			phone: "39",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 111,
			name: "Jamaica",
			code: "JM",
			phone: "1876",
			symbol: "J$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 112,
			name: "Japan",
			code: "JP",
			phone: "81",
			symbol: "¥",
			created_at: null,
			updated_at: null,
		},
		{
			id: 113,
			name: "Jersey",
			code: "JE",
			phone: "44",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 114,
			name: "Jordan",
			code: "JO",
			phone: "962",
			symbol: "ا.د",
			created_at: null,
			updated_at: null,
		},
		{
			id: 115,
			name: "Kazakhstan",
			code: "KZ",
			phone: "7",
			symbol: "лв",
			created_at: null,
			updated_at: null,
		},
		{
			id: 116,
			name: "Kenya",
			code: "KE",
			phone: "254",
			symbol: "KSh",
			created_at: null,
			updated_at: null,
		},
		{
			id: 117,
			name: "Kiribati",
			code: "KI",
			phone: "686",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 118,
			name: "Korea, Democratic People's Republic of",
			code: "KP",
			phone: "850",
			symbol: "₩",
			created_at: null,
			updated_at: null,
		},
		{
			id: 119,
			name: "Korea, Republic of",
			code: "KR",
			phone: "82",
			symbol: "₩",
			created_at: null,
			updated_at: null,
		},
		{
			id: 120,
			name: "Kosovo",
			code: "XK",
			phone: "381",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 121,
			name: "Kuwait",
			code: "KW",
			phone: "965",
			symbol: "ك.د",
			created_at: null,
			updated_at: null,
		},
		{
			id: 122,
			name: "Kyrgyzstan",
			code: "KG",
			phone: "996",
			symbol: "лв",
			created_at: null,
			updated_at: null,
		},
		{
			id: 123,
			name: "Lao People's Democratic Republic",
			code: "LA",
			phone: "856",
			symbol: "₭",
			created_at: null,
			updated_at: null,
		},
		{
			id: 124,
			name: "Latvia",
			code: "LV",
			phone: "371",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 125,
			name: "Lebanon",
			code: "LB",
			phone: "961",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 126,
			name: "Lesotho",
			code: "LS",
			phone: "266",
			symbol: "L",
			created_at: null,
			updated_at: null,
		},
		{
			id: 127,
			name: "Liberia",
			code: "LR",
			phone: "231",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 128,
			name: "Libyan Arab Jamahiriya",
			code: "LY",
			phone: "218",
			symbol: "د.ل",
			created_at: null,
			updated_at: null,
		},
		{
			id: 129,
			name: "Liechtenstein",
			code: "LI",
			phone: "423",
			symbol: "CHf",
			created_at: null,
			updated_at: null,
		},
		{
			id: 130,
			name: "Lithuania",
			code: "LT",
			phone: "370",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 131,
			name: "Luxembourg",
			code: "LU",
			phone: "352",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 132,
			name: "Macao",
			code: "MO",
			phone: "853",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 133,
			name: "Macedonia, the Former Yugoslav Republic of",
			code: "MK",
			phone: "389",
			symbol: "ден",
			created_at: null,
			updated_at: null,
		},
		{
			id: 134,
			name: "Madagascar",
			code: "MG",
			phone: "261",
			symbol: "Ar",
			created_at: null,
			updated_at: null,
		},
		{
			id: 135,
			name: "Malawi",
			code: "MW",
			phone: "265",
			symbol: "MK",
			created_at: null,
			updated_at: null,
		},
		{
			id: 136,
			name: "Malaysia",
			code: "MY",
			phone: "60",
			symbol: "RM",
			created_at: null,
			updated_at: null,
		},
		{
			id: 137,
			name: "Maldives",
			code: "MV",
			phone: "960",
			symbol: "Rf",
			created_at: null,
			updated_at: null,
		},
		{
			id: 138,
			name: "Mali",
			code: "ML",
			phone: "223",
			symbol: "CFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 139,
			name: "Malta",
			code: "MT",
			phone: "356",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 140,
			name: "Marshall Islands",
			code: "MH",
			phone: "692",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 141,
			name: "Martinique",
			code: "MQ",
			phone: "596",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 142,
			name: "Mauritania",
			code: "MR",
			phone: "222",
			symbol: "MRU",
			created_at: null,
			updated_at: null,
		},
		{
			id: 143,
			name: "Mauritius",
			code: "MU",
			phone: "230",
			symbol: "₨",
			created_at: null,
			updated_at: null,
		},
		{
			id: 144,
			name: "Mayotte",
			code: "YT",
			phone: "269",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 145,
			name: "Mexico",
			code: "MX",
			phone: "52",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 146,
			name: "Micronesia, Federated States of",
			code: "FM",
			phone: "691",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 147,
			name: "Moldova, Republic of",
			code: "MD",
			phone: "373",
			symbol: "L",
			created_at: null,
			updated_at: null,
		},
		{
			id: 148,
			name: "Monaco",
			code: "MC",
			phone: "377",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 149,
			name: "Mongolia",
			code: "MN",
			phone: "976",
			symbol: "₮",
			created_at: null,
			updated_at: null,
		},
		{
			id: 150,
			name: "Montenegro",
			code: "ME",
			phone: "382",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 151,
			name: "Montserrat",
			code: "MS",
			phone: "1664",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 152,
			name: "Morocco",
			code: "MA",
			phone: "212",
			symbol: "DH",
			created_at: null,
			updated_at: null,
		},
		{
			id: 153,
			name: "Mozambique",
			code: "MZ",
			phone: "258",
			symbol: "MT",
			created_at: null,
			updated_at: null,
		},
		{
			id: 154,
			name: "Myanmar",
			code: "MM",
			phone: "95",
			symbol: "K",
			created_at: null,
			updated_at: null,
		},
		{
			id: 155,
			name: "Namibia",
			code: "NA",
			phone: "264",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 156,
			name: "Nauru",
			code: "NR",
			phone: "674",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 157,
			name: "Nepal",
			code: "NP",
			phone: "977",
			symbol: "₨",
			created_at: null,
			updated_at: null,
		},
		{
			id: 158,
			name: "Netherlands",
			code: "NL",
			phone: "31",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 159,
			name: "Netherlands Antilles",
			code: "AN",
			phone: "599",
			symbol: "NAf",
			created_at: null,
			updated_at: null,
		},
		{
			id: 160,
			name: "New Caledonia",
			code: "NC",
			phone: "687",
			symbol: "₣",
			created_at: null,
			updated_at: null,
		},
		{
			id: 161,
			name: "New Zealand",
			code: "NZ",
			phone: "64",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 162,
			name: "Nicaragua",
			code: "NI",
			phone: "505",
			symbol: "C$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 163,
			name: "Niger",
			code: "NE",
			phone: "227",
			symbol: "CFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 164,
			name: "Nigeria",
			code: "NG",
			phone: "234",
			symbol: "₦",
			created_at: null,
			updated_at: null,
		},
		{
			id: 165,
			name: "Niue",
			code: "NU",
			phone: "683",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 166,
			name: "Norfolk Island",
			code: "NF",
			phone: "672",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 167,
			name: "Northern Mariana Islands",
			code: "MP",
			phone: "1670",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 168,
			name: "Norway",
			code: "NO",
			phone: "47",
			symbol: "kr",
			created_at: null,
			updated_at: null,
		},
		{
			id: 169,
			name: "Oman",
			code: "OM",
			phone: "968",
			symbol: ".ع.ر",
			created_at: null,
			updated_at: null,
		},
		{
			id: 170,
			name: "Pakistan",
			code: "PK",
			phone: "92",
			symbol: "₨",
			created_at: null,
			updated_at: null,
		},
		{
			id: 171,
			name: "Palau",
			code: "PW",
			phone: "680",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 172,
			name: "Palestinian Territory, Occupied",
			code: "PS",
			phone: "970",
			symbol: "₪",
			created_at: null,
			updated_at: null,
		},
		{
			id: 173,
			name: "Panama",
			code: "PA",
			phone: "507",
			symbol: "B/.",
			created_at: null,
			updated_at: null,
		},
		{
			id: 174,
			name: "Papua New Guinea",
			code: "PG",
			phone: "675",
			symbol: "K",
			created_at: null,
			updated_at: null,
		},
		{
			id: 175,
			name: "Paraguay",
			code: "PY",
			phone: "595",
			symbol: "₲",
			created_at: null,
			updated_at: null,
		},
		{
			id: 176,
			name: "Peru",
			code: "PE",
			phone: "51",
			symbol: "S/.",
			created_at: null,
			updated_at: null,
		},
		{
			id: 177,
			name: "Philippines",
			code: "PH",
			phone: "63",
			symbol: "₱",
			created_at: null,
			updated_at: null,
		},
		{
			id: 178,
			name: "Pitcairn",
			code: "PN",
			phone: "64",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 179,
			name: "Poland",
			code: "PL",
			phone: "48",
			symbol: "zł",
			created_at: null,
			updated_at: null,
		},
		{
			id: 180,
			name: "Portugal",
			code: "PT",
			phone: "351",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 181,
			name: "Puerto Rico",
			code: "PR",
			phone: "1787",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 182,
			name: "Qatar",
			code: "QA",
			phone: "974",
			symbol: "ق.ر",
			created_at: null,
			updated_at: null,
		},
		{
			id: 183,
			name: "Reunion",
			code: "RE",
			phone: "262",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 184,
			name: "Romania",
			code: "RO",
			phone: "40",
			symbol: "lei",
			created_at: null,
			updated_at: null,
		},
		{
			id: 185,
			name: "Russian Federation",
			code: "RU",
			phone: "70",
			symbol: "₽",
			created_at: null,
			updated_at: null,
		},
		{
			id: 186,
			name: "Rwanda",
			code: "RW",
			phone: "250",
			symbol: "FRw",
			created_at: null,
			updated_at: null,
		},
		{
			id: 187,
			name: "Saint Barthelemy",
			code: "BL",
			phone: "590",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 188,
			name: "Saint Helena",
			code: "SH",
			phone: "290",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 189,
			name: "Saint Kitts and Nevis",
			code: "KN",
			phone: "1869",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 190,
			name: "Saint Lucia",
			code: "LC",
			phone: "1758",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 191,
			name: "Saint Martin",
			code: "MF",
			phone: "590",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 192,
			name: "Saint Pierre and Miquelon",
			code: "PM",
			phone: "508",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 193,
			name: "Saint Vincent and the Grenadines",
			code: "VC",
			phone: "1784",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 194,
			name: "Samoa",
			code: "WS",
			phone: "684",
			symbol: "SAT",
			created_at: null,
			updated_at: null,
		},
		{
			id: 195,
			name: "San Marino",
			code: "SM",
			phone: "378",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 196,
			name: "Sao Tome and Principe",
			code: "ST",
			phone: "239",
			symbol: "Db",
			created_at: null,
			updated_at: null,
		},
		{
			id: 197,
			name: "Saudi Arabia",
			code: "SA",
			phone: "966",
			symbol: "﷼",
			created_at: null,
			updated_at: null,
		},
		{
			id: 198,
			name: "Senegal",
			code: "SN",
			phone: "221",
			symbol: "CFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 199,
			name: "Serbia",
			code: "RS",
			phone: "381",
			symbol: "din",
			created_at: null,
			updated_at: null,
		},
		{
			id: 200,
			name: "Serbia and Montenegro",
			code: "CS",
			phone: "381",
			symbol: "din",
			created_at: null,
			updated_at: null,
		},
		{
			id: 201,
			name: "Seychelles",
			code: "SC",
			phone: "248",
			symbol: "SRe",
			created_at: null,
			updated_at: null,
		},
		{
			id: 202,
			name: "Sierra Leone",
			code: "SL",
			phone: "232",
			symbol: "Le",
			created_at: null,
			updated_at: null,
		},
		{
			id: 203,
			name: "Singapore",
			code: "SG",
			phone: "65",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 204,
			name: "Sint Maarten",
			code: "SX",
			phone: "1",
			symbol: "ƒ",
			created_at: null,
			updated_at: null,
		},
		{
			id: 205,
			name: "Slovakia",
			code: "SK",
			phone: "421",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 206,
			name: "Slovenia",
			code: "SI",
			phone: "386",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 207,
			name: "Solomon Islands",
			code: "SB",
			phone: "677",
			symbol: "Si$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 208,
			name: "Somalia",
			code: "SO",
			phone: "252",
			symbol: "Sh.so.",
			created_at: null,
			updated_at: null,
		},
		{
			id: 209,
			name: "South Africa",
			code: "ZA",
			phone: "27",
			symbol: "R",
			created_at: null,
			updated_at: null,
		},
		{
			id: 210,
			name: "South Georgia and the South Sandwich Islands",
			code: "GS",
			phone: "500",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 211,
			name: "South Sudan",
			code: "SS",
			phone: "211",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 212,
			name: "Spain",
			code: "ES",
			phone: "34",
			symbol: "€",
			created_at: null,
			updated_at: null,
		},
		{
			id: 213,
			name: "Sri Lanka",
			code: "LK",
			phone: "94",
			symbol: "Rs",
			created_at: null,
			updated_at: null,
		},
		{
			id: 214,
			name: "Sudan",
			code: "SD",
			phone: "249",
			symbol: ".س.ج",
			created_at: null,
			updated_at: null,
		},
		{
			id: 215,
			name: "Suriname",
			code: "SR",
			phone: "597",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 216,
			name: "Svalbard and Jan Mayen",
			code: "SJ",
			phone: "47",
			symbol: "kr",
			created_at: null,
			updated_at: null,
		},
		{
			id: 217,
			name: "Swaziland",
			code: "SZ",
			phone: "268",
			symbol: "E",
			created_at: null,
			updated_at: null,
		},
		{
			id: 218,
			name: "Sweden",
			code: "SE",
			phone: "46",
			symbol: "kr",
			created_at: null,
			updated_at: null,
		},
		{
			id: 219,
			name: "Switzerland",
			code: "CH",
			phone: "41",
			symbol: "CHf",
			created_at: null,
			updated_at: null,
		},
		{
			id: 220,
			name: "Syrian Arab Republic",
			code: "SY",
			phone: "963",
			symbol: "LS",
			created_at: null,
			updated_at: null,
		},
		{
			id: 221,
			name: "Taiwan, Province of China",
			code: "TW",
			phone: "886",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 222,
			name: "Tajikistan",
			code: "TJ",
			phone: "992",
			symbol: "SM",
			created_at: null,
			updated_at: null,
		},
		{
			id: 223,
			name: "Tanzania, United Republic of",
			code: "TZ",
			phone: "255",
			symbol: "TSh",
			created_at: null,
			updated_at: null,
		},
		{
			id: 224,
			name: "Thailand",
			code: "TH",
			phone: "66",
			symbol: "฿",
			created_at: null,
			updated_at: null,
		},
		{
			id: 225,
			name: "Timor-Leste",
			code: "TL",
			phone: "670",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 226,
			name: "Togo",
			code: "TG",
			phone: "228",
			symbol: "CFA",
			created_at: null,
			updated_at: null,
		},
		{
			id: 227,
			name: "Tokelau",
			code: "TK",
			phone: "690",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 228,
			name: "Tonga",
			code: "TO",
			phone: "676",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 229,
			name: "Trinidad and Tobago",
			code: "TT",
			phone: "1868",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 230,
			name: "Tunisia",
			code: "TN",
			phone: "216",
			symbol: "ت.د",
			created_at: null,
			updated_at: null,
		},
		{
			id: 231,
			name: "Turkey",
			code: "TR",
			phone: "90",
			symbol: "₺",
			created_at: null,
			updated_at: null,
		},
		{
			id: 232,
			name: "Turkmenistan",
			code: "TM",
			phone: "7370",
			symbol: "T",
			created_at: null,
			updated_at: null,
		},
		{
			id: 233,
			name: "Turks and Caicos Islands",
			code: "TC",
			phone: "1649",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 234,
			name: "Tuvalu",
			code: "TV",
			phone: "688",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 235,
			name: "Uganda",
			code: "UG",
			phone: "256",
			symbol: "USh",
			created_at: null,
			updated_at: null,
		},
		{
			id: 236,
			name: "Ukraine",
			code: "UA",
			phone: "380",
			symbol: "₴",
			created_at: null,
			updated_at: null,
		},
		{
			id: 237,
			name: "United Arab Emirates",
			code: "AE",
			phone: "971",
			symbol: "إ.د",
			created_at: null,
			updated_at: null,
		},
		{
			id: 238,
			name: "United Kingdom",
			code: "GB",
			phone: "44",
			symbol: "£",
			created_at: null,
			updated_at: null,
		},
		{
			id: 239,
			name: "United States",
			code: "US",
			phone: "1",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 240,
			name: "United States Minor Outlying Islands",
			code: "UM",
			phone: "1",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 241,
			name: "Uruguay",
			code: "UY",
			phone: "598",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 242,
			name: "Uzbekistan",
			code: "UZ",
			phone: "998",
			symbol: "лв",
			created_at: null,
			updated_at: null,
		},
		{
			id: 243,
			name: "Vanuatu",
			code: "VU",
			phone: "678",
			symbol: "VT",
			created_at: null,
			updated_at: null,
		},
		{
			id: 244,
			name: "Venezuela",
			code: "VE",
			phone: "58",
			symbol: "Bs",
			created_at: null,
			updated_at: null,
		},
		{
			id: 245,
			name: "Viet Nam",
			code: "VN",
			phone: "84",
			symbol: "₫",
			created_at: null,
			updated_at: null,
		},
		{
			id: 246,
			name: "Virgin Islands, British",
			code: "VG",
			phone: "1284",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 247,
			name: "Virgin Islands, U.s.",
			code: "VI",
			phone: "1340",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
		{
			id: 248,
			name: "Wallis and Futuna",
			code: "WF",
			phone: "681",
			symbol: "₣",
			created_at: null,
			updated_at: null,
		},
		{
			id: 249,
			name: "Western Sahara",
			code: "EH",
			phone: "212",
			symbol: "MAD",
			created_at: null,
			updated_at: null,
		},
		{
			id: 250,
			name: "Yemen",
			code: "YE",
			phone: "967",
			symbol: "﷼",
			created_at: null,
			updated_at: null,
		},
		{
			id: 251,
			name: "Zambia",
			code: "ZM",
			phone: "260",
			symbol: "ZK",
			created_at: null,
			updated_at: null,
		},
		{
			id: 252,
			name: "Zimbabwe",
			code: "ZW",
			phone: "263",
			symbol: "$",
			created_at: null,
			updated_at: null,
		},
	];
};

const regions = () => {
	return [
		"Ahafo Region",
		"Ashanti Region",
		"Bono East Region",
		"Brong Ahafo Region",
		"Central Region",
		"Eastern Region",
		"Greater Accra Region",
		"Northern Region",
		"North-East Region",
		"Oti Region",
		"Savannah Region",
		"Upper East Region",
		"Upper West Region",
		"Volta Region",
		"Western Region",
		"Western North Region",
	];
};

const countriesList = {
	nationalities,
	countries,
	regions,
};

export default countriesList;
