import requester from "./requester";

const createFacility = async (formData) => {
	return requester.post("/facility/create", formData);
};

const getUserFacilities = async () => {
	// return requester.get("/user/facility/all");
	return new Promise(async (resolve, reject) => {
		try {
			const response = await requester.get("/user/facility/all");
			resolve(response.data);
		} catch (error) {
			reject(error);
		}
	});
};

const fetchFacility = async (fid) => {
	return requester.get("/facility/fetch/" + fid);
};

const updateFacility = async (formData) => {
	return requester.patch("/facility/update", formData);
};

const facilityService = {
	createFacility,
	getUserFacilities,
	fetchFacility,
	updateFacility,
};

export default facilityService;
