import Layout from "../Layout";
// import { Link } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import facilityService from "../../helpers/facilityService";
// import { usePlacesWidget } from "react-google-autocomplete";
// import Autocomplete from "react-google-autocomplete";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateFacility = () => {
  const [name, setname] = useState("");
  const [type, settype] = useState("clinic");
  const [location, setlocation] = useState("");
  const [numberOfEmployees, setnumberOfEmployees] = useState("below-10");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: name,
      type: type,
      location: location,
      number_of_employees: numberOfEmployees,
    };
    console.log(formData);
    try {
      const response = await facilityService.createFacility(formData);
      console.log(response);
      if (response.data.success) {
        navigate("/facilities");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Layout>
        <div className="mt-5"></div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <BreadCrumb pathName="facilities" to="New" />
            <form onSubmit={handleSubmit}>
              <div className="card mb-3">
                <div className="card-header">
                  <h5 className="mb-0">CREATE NEW FACILITY</h5>
                  <p className="mb-0">Fill out the form below to create your new facility</p>
                </div>
                <div className="card-body">
                  <div className="row">
                    {/* logo     */}
                    {/* <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="customFile">
                          Upload a logo
                        </label>
                        <input className="form-control" id="customFile" type="file" />
                      </div>
                    </div> */}

                    {/* Operating lisence */}
                    {/* <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="customFile">
                          Upload operating license / certificate
                        </label>
                        <input className="form-control" id="customFile" type="file" />
                      </div>
                    </div> */}

                    {/* name */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="exampleFormControlInput1">
                          Facility name
                        </label>
                        <input value={name} onChange={(e) => setname(e.target.value)} className="form-control" id="exampleFormControlInput1" type="text" placeholder="" />
                      </div>
                    </div>

                    {/* Facility type */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="exampleFormControlInput1">
                          Facility type
                        </label>
                        <select defaultValue={type} onChange={(e) => settype(e.target.value)} className="form-select" aria-label="Default select example">
                          <option disabled hidden value="0">
                            Select type
                          </option>
                          <option value="clinic">Clinic</option>
                          <option value="hospital">Hospital</option>
                          <option value="lab">Lab</option>
                        </select>
                      </div>
                    </div>

                    {/* Location */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="exampleFormControlInput1">
                          Location
                        </label>
                        {/* <Autocomplete
                          apiKey={GOOGLE_MAPS_API_KEY}
                          onPlaceSelected={(place) => {
                            console.log(place);
                          }}
                        /> */}
                        <input value={location} onChange={(e) => setlocation(e.target.value)} className="form-control" id="exampleFormControlInput1" type="text" placeholder="" />
                      </div>
                    </div>

                    {/* Number of employees */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="exampleFormControlInput1">
                          Number of employees
                        </label>
                        <select defaultValue={numberOfEmployees} onChange={(e) => setnumberOfEmployees(e.target.value)} className="form-select" aria-label="Default select example">
                          <option value="below-10">Below 10</option>
                          <option value="10-above">10 and above</option>
                          <option value="50-above">50 and above</option>
                          <option value="100-above">100 and above</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CreateFacility;
