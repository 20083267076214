import Layout from "../Layout";
// import { Link } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import facilityService from "../../helpers/facilityService";
// import { usePlacesWidget } from "react-google-autocomplete";
// import Autocomplete from "react-google-autocomplete";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const FacilitySettings = () => {
	const [name, setname] = useState("");
	const [type, settype] = useState("");
	const [location, setlocation] = useState("");
	const [numberOfEmployees, setnumberOfEmployees] = useState("");
	const { fid, facilityName } = useParams();
	const [facility, setfacility] = useState(null);
	const [isSubmitting, setisSubmitting] = useState(false);
	const [settingsSaved, setsettingsSaved] = useState(false);
	const [isLoading, setisLoading] = useState(false);

	const navigate = useNavigate();

	const fetchFacility = async () => {
		setisLoading(true);
		facilityService
			.fetchFacility(fid)
			.then((response) => {
				if (response.data.success) {
					setfacility(response.data.facility);
					setname(response.data.facility.name);
					settype(response.data.facility.type);
					setlocation(response.data.facility.location);
					setnumberOfEmployees(response.data.facility.employees_count);
				}
				setisLoading(false);
			})
			.catch((error) => {
				setisLoading(false);
			});
	};

	useEffect(() => {
		if (fid) {
			fetchFacility();
		}
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();

		setisSubmitting(true);
		setsettingsSaved(false);

		const formData = {
			fid: fid,
			name: name,
			type: type,
			location: location,
			number_of_employees: numberOfEmployees,
		};

		facilityService
			.updateFacility(formData)
			.then((response) => {
				if (response.data.success) {
					setsettingsSaved(true);
					setisSubmitting(false);
				}
			})
			.catch((error) => {});
	};
	return (
		<>
			{!isLoading && (
				<Layout>
					<div className="mt-5"></div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<BreadCrumb pathName="facilities" to={fid ? `${facilityName.replace("-", " ")} / Settings` : "New"} />
							<form onSubmit={handleSubmit}>
								<div className="card mb-3">
									<div className="card-header">
										<h5 className="mb-0 text-uppercase">{facilityName.replace("-", " ")} OVERVIEW</h5>
										<p className="mb-0">Change and update your {type} here</p>
									</div>
									<div className="card-body">
										<div className="row">
											{/* logo     */}
											{/* <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="customFile">
                          Upload a logo
                        </label>
                        <input className="form-control" id="customFile" type="file" />
                      </div>
                    </div> */}

											{/* Operating lisence */}
											{/* <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="customFile">
                          Upload operating license / certificate
                        </label>
                        <input className="form-control" id="customFile" type="file" />
                      </div>
                    </div> */}

											{/* name */}
											<div className="col-md-6">
												<div className="mb-3">
													<label className="form-label" htmlFor="exampleFormControlInput1">
														Facility name
													</label>
													<input value={name} onChange={(e) => setname(e.target.value)} className="form-control" id="exampleFormControlInput1" type="text" placeholder="" />
												</div>
											</div>

											{/* Facility type */}
											<div className="col-md-6">
												<div className="mb-3">
													<label className="form-label" htmlFor="exampleFormControlInput1">
														Facility type
													</label>
													<select defaultValue={type} onChange={(e) => settype(e.target.value)} className="form-select" aria-label="Default select example">
														<option disabled hidden value="0">
															Select type
														</option>
														<option value="clinic">Clinic</option>
														<option value="hospital">Hospital</option>
														<option value="lab">Lab</option>
													</select>
												</div>
											</div>

											{/* Location */}
											<div className="col-md-6">
												<div className="mb-3">
													<label className="form-label" htmlFor="exampleFormControlInput1">
														Location
													</label>
													{/* <Autocomplete
                          apiKey={GOOGLE_MAPS_API_KEY}
                          onPlaceSelected={(place) => {
                            console.log(place);
                          }}
                        /> */}
													<input value={location} onChange={(e) => setlocation(e.target.value)} className="form-control" id="exampleFormControlInput1" type="text" placeholder="" />
												</div>
											</div>

											{/* Number of employees */}
											<div className="col-md-6">
												<div className="mb-3">
													<label className="form-label" htmlFor="exampleFormControlInput1">
														Number of employees
													</label>
													<select defaultValue={numberOfEmployees} onChange={(e) => setnumberOfEmployees(e.target.value)} className="form-select" aria-label="Default select example">
														<option value="below-10">Below 10</option>
														<option value="10-above">10 and above</option>
														<option value="50-above">50 and above</option>
														<option value="100-above">100 and above</option>
													</select>
												</div>
											</div>
										</div>
										<button className="btn btn-primary" type="submit" disabled={isSubmitting}>
											{!isSubmitting ? <span>Save</span> : <span>Saving...</span>}
										</button>
									</div>
								</div>
							</form>

							{settingsSaved && (
								<Fade>
									<div className="alert alert-success border-2 d-flex align-items-center" role="alert">
										<div className="bg-success me-3 icon-item">
											<span className="fas fa-check-circle text-white fs-3"></span>
										</div>
										<p className="mb-0 flex-1">Your settings have been saved!</p>
										<button className="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
									</div>
								</Fade>
							)}
						</div>
					</div>
				</Layout>
			)}
		</>
	);
};

export default FacilitySettings;
