import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import requester from "../../helpers/requester";

const initialState = {
	patients: [],
	todaysPatients:0,
	status: "idle", // 'idle' | 'pending' | 'succeeded' | 'failed'
	error: null,
};

export const fetchAllPatients = createAsyncThunk("",async (thunkAPI, { dispatch,getState }) => {
	const state = getState();
	try {
		const response = await requester.get(`/patients/${state.facility.value.fid}/all`);
		return response.data;
	} catch (error) {
		return error.response.status;
	}
});


export const patientSlice = createSlice({
	name: "patient",
	initialState,
	reducers: {
		updatePatients: (state, action) => {
			state.patients = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllPatients.pending, (state, action) => {
				state.status = "pending";
			})
			.addCase(fetchAllPatients.fulfilled, (state, action) => {
				state.status = "success";
				state.patients = action.payload.patients;
				state.todaysPatients = action.payload.todays_patients;
				state.error = null;
			})
			.addCase(fetchAllPatients.rejected, (state, action) => {
				state.status = "failed";
				state.error = action;
			});
	},
});

export const { updatePatients } = patientSlice.actions;
export default patientSlice.reducer;
