import { Fade } from "react-awesome-reveal";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { fetchAllPatients } from "../../../app/slices/patientSlice";
import DataTable from "react-data-table-component";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchFormCard from "../../SearchFormCard";
import patientService from "../../../helpers/patientService";
import PageHeader from "../PageHeader";

const AllPatients = () => {
	const navigate = useNavigate();
	const facility = useSelector((state) => state.facility.value);
	// const { patients, todaysPatients, status, error } = useSelector((state) => state.patient);
	const [patients, setpatients] = useState([]);
	const slug = facility.name.replace(/ +/g, "-").toLowerCase();
	const [filterText, setfilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [loading, setloading] = useState(true);
	// const dispatch = useDispatch();

	const fetchAllPatients = () => {
		patientService
			.getAllPatients(facility.fid)
			.then((res) => {
				setpatients(res.data);
				setloading(false);
			})
			.catch((error) => {});
	};

	useEffect(() => {
		return () => {
			fetchAllPatients();
		};
	}, []);

	const filteredItems = !loading
		? patients.patients.filter(
				(patient) =>
					patient.full_name.toLowerCase().trim().includes(filterText.toLocaleLowerCase().trim()) ||
					(patient.phone_number && patient.phone_number.trim().includes(filterText.toLowerCase().trim())) ||
					patient.pid.includes(filterText.toLowerCase()) ||
					(patient.created_at && patient.created_at.includes(filterText.toLowerCase()))
		  )
		: [];

	const columns = [
		{
			name: "Full Name",
			selector: (patient) => patient.full_name,
			sortable: true,
			filterable: true,
			grow: 2,
			cell: (patient) => (
				<div
					className="d-flex align-items-center"
					onClick={() => {
						navigate(`../patient/${patient.pid}`);
					}}>
					{/* <div className="avatar avatar-xl">
						<img className="rounded-circle" src={process.env.PUBLIC_URL + "/assets/img/team/1-thumb.png"} alt="" />
					</div> */}
					<h6 className="mb-0 text-800 name">{patient.full_name}</h6>
				</div>
			),
		},
		{
			name: "Folder No.",
			selector: (patient) => patient.pid,
			sortable: true,
			filterable: true,
		},
		{
			name: "Gender",
			selector: (patient) => (patient.gender === "male" ? "M" : "F"),
		},
		{
			name: "Age",
			selector: (patient) => `${patient.age} yrs` || "-",
		},
		{
			name: "Date Registered",
			selector: (patient) => patient.created_at,
			grow: 2,
		},
		{
			name: "",
			selector: (patient) => patient.pid,
			right: true,
			cell: (patient) => (
				<>
					<div className="hover-actions bg-transparent">
						<OverlayTrigger key={patient.pid} placement="top" overlay={<Tooltip id={patient.pid}>View</Tooltip>}>
							<Link className="btn icon-item rounded-3 me-2 fs--2 icon-item-sm" to={`../patient/${patient.pid}`}>
								<span className="far fa-eye"></span>
							</Link>
						</OverlayTrigger>
						<OverlayTrigger key={patient.pid + "2"} placement="top" overlay={<Tooltip id={patient.id}>Create visit</Tooltip>}>
							<Link className="btn icon-item rounded-3 me-2 fs--2 icon-item-sm" to={`../visits/new/${patient.pid}`} state={patient}>
								<span className="far fa-list-alt"></span>
							</Link>
						</OverlayTrigger>
					</div>
					<div className="dropdown font-sans-serif btn-reveal-trigger">
						<button className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal-sm transition-none" type="button" id="crm-recent-leads-0" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
							<span className="fas fa-ellipsis-h fs--2"></span>
						</button>
						<div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="crm-recent-leads-0">
							<Link className="dropdown-item" state={patient} to={`../patient/${patient.pid}`}>
								View
							</Link>
							<Link className="dropdown-item" state={patient} to={`../visits/new/${patient.pid}`}>
								Create visit
							</Link>
							{/* <a className="dropdown-item" href="#!">
								Export
							</a>
							<div className="dropdown-divider"></div>
							<a className="dropdown-item text-danger" href="#!">
								Remove
							</a> */}
						</div>
					</div>
				</>
			),
		},
	];

	return (
		<>
			<Fade>
				<div className="mb-3">
					<PageHeader title={`Patients`} subTitle={`All Patients`} showButton={true} buttonText={`new patient`} buttonRoute={`../patient/new`} />
					<div className="card bg-100">
						<div className="card-body">
							<div className="row">
								<div className="col-lg-4 border-lg-end border-bottom border-lg-0 pb-3 pb-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-primary">
												<span className="fs--2 fas fa-phone text-primary"></span>
											</div>
											<h6 className="mb-0">New patients</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{patients.todays_patients}</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-up text-primary"></span>
												<p className="fs--2 mb-0 text-nowrap">today</p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[220,230,150,175,200,170,70,160],"color":"#2c7be5","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#2c7be53A"},{"offset":1,"color":"#2c7be50A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>
								<div className="col-lg-4 border-lg-end border-bottom border-lg-0 py-3 py-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-info">
												<span className="fs--2 fas fa-user text-info"></span>
											</div>
											<h6 className="mb-0">Waiting patients</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">13</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-up text-success"></span>
												<p className="fs--2 mb-0 text-nowrap">in queue</p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[90,160,150,120,230,155,220,240],"color":"#27bcfd","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#27bcfd3A"},{"offset":1,"color":"#27bcfd0A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>
								<div className="col-lg-4 pt-3 pt-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-success">
												<span className="fs--2 fas fa-bolt text-success"></span>
											</div>
											<h6 className="mb-0">Total patients</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{patients.length}</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-down text-danger"></span>
												<p className="fs--2 mb-0 text-nowrap">in total</p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[200,150,175,130,150,115,130,100],"color":"#00d27a","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#00d27a3A"},{"offset":1,"color":"#00d27a0A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="card" id="TableCrmRecentLeads" data-list='{"valueNames":["name","email","status"],"page":8,"pagination":true}'>
							<div className="card-header d-flex flex-between-center bg-light py-2">
								<SearchFormCard value={filterText} setFunction={setfilterText}></SearchFormCard>

								{/* <div className="h-100">
									<form>
										<div className="input-group">
											<input
												className="form-control form-control-sm shadow-none search"
												type="search"
												placeholder="Search for a patient"
												aria-label="search"
												value={filterText}
												onChange={(e) => {
													setfilterText(e.target.value);
												}}
											/>
											<div className="input-group-text bg-white">
												<span className="fas fa-filter fs--1 text-muted"></span>
											</div>
										</div>
									</form>
								</div> */}

								{/* <div className="search-box ms-auto" data-list='{"valueNames":["title"]}'>
									<form className={`position-relative ${filterText ? "show" : ""}`} data-bs-toggle="search" data-bs-display="static">
										<input
											className="form-control search-input fuzzy-search"
											value={filterText}
											onChange={(e) => {
												setfilterText(e.target.value);
											}}
											type="search"
											placeholder="Search by name or folder number"
											aria-label="Search"
										/>
										<span className="fas fa-filter search-box-icon"></span>
									</form>
									<div className="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none" onClick={handleClear} data-bs-dismiss="search">
										<div className="btn-close-falcon" aria-label="Close"></div>
									</div>
								</div> */}
							</div>

							<div className="card-body py-0">
								<DataTable
									columns={columns}
									data={filteredItems}
									paginationResetDefaultPage={resetPaginationToggle}
									// subHeaderComponent={subHeaderComponentMemo}
									pagination
									responsive
									progressPending={loading}
									onRowClicked={(patient, event) => {
										navigate(`../patient/${patient.pid}`);
									}}
									progressComponent={
										<div className="p-5">
											<Spinner animation="grow" variant="primary" />
										</div>
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</>
	);
};

export default AllPatients;
