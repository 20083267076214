import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import PageHeader from "../PageHeader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import inventoryService from "../../../helpers/inventoryService";
import { Modal } from "react-bootstrap";
import miscellaneous from "../../../helpers/miscellaneous";

const AddInventoryItem = () => {
	const { category, fid } = useParams();
	const [isSubmitting, setisSubmitting] = useState(false);
	const [showModal, setshowModal] = useState(false);
	const [formErrorMesage, setformErrorMesage] = useState([]);
	const [formData, setformData] = useState([
		{
			fid: fid,
			category: category,
			name: "",
			price: "",
			quantity_available: "",
			sku: "",
			measuring_unit_singular: "",
			measuring_unit_plural: "",
			image: null,
			imagePreview: "",
		},
	]);

	const handleRemoveItem = (itemIndex) => {
		if (itemIndex > 0) {
			formData.splice(itemIndex, 1);
			setformData([...formData]);
		}
	};

	const handleAddItem = () => {
		const newobj = {
			fid: fid,
			category: category,
			name: "",
			price: "",
			quantity_available: "",
			sku: "",
			measuring_unit_singular: "",
			measuring_unit_plural: "",
			image: null,
			imagePreview: "",
		};

		setformData([...formData, ...[newobj]]);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setformErrorMesage([]);
		setisSubmitting(true);

		inventoryService
			.addInventoryItem(formData)
			.then((response) => {
				console.log(response);
				if (response.data.success) {
					setshowModal(true);
				} else {
					setformErrorMesage(response.data.errors);
					setisSubmitting(false);
				}
			})
			.catch((error) => {
				setisSubmitting(false);
			});
	};

	return (
		<>
			<Fade>
				<PageHeader
					title={`Inventory`}
					subTitle={`Add ${category} items`}
					showButton={false}
					buttonText={``}
					buttonRoute={``}
				/>
				<div className="row align-items-center mb-3">
					<div className="col-auto order-md-0">
						<h6 className="fw-normal m-0 ">
							Feilds with <span className="text-warning">orange</span> markings are required
						</h6>
					</div>
				</div>

				<form className="row g-3 needs-validation" onSubmit={(e) => handleSubmit(e)}>
					<div className="col-12">
						<ul className="list-unstyled">
							{formData.map((item, index) => (
								<Fade key={index}>
									<li>
										<div className="card mb-3 overflow-hidden">
											<div className="card-header py-2 d-flex align-items-center">
												<h5 className="mb-0">{`${
													formData[index].name || (category === "service" ? "Service" : "Item")
												} ${!formData[index].name ? index + 1 : ""}`}</h5>
												{index > 0 && (
													<div className="ms-auto btn-reveal-trigger">
														<button
															className="btn btn-sm btn-reveal ms-auto"
															type="button"
															id="dropdown-session-by-country"
															onClick={() => handleRemoveItem(index)}
														>
															{/* Remove */}
															<span className="fas fa-times"></span>
														</button>
													</div>
												)}
											</div>
											<div className="card-body bg-light">
												<div className="row g-3">
													{/* Item / Service name */}
													<div className="col-lg-4">
														<label className="form-label" htmlFor="name">
															{category === "service" ? "Service" : "Item"} Name
														</label>
														<input
															className="form-control"
															id="name"
															name="name"
															type="text"
															value={formData[index].name}
															onChange={(e) => {
																formData[index].name = e.target.value;
																setformData([...formData]);
															}}
															required
														/>
													</div>

													{/* Unit Price */}
													<div className="col-lg-4">
														<label className="form-label" htmlFor="price">
															Unit Price
														</label>
														<div className="input-group">
															<span className="input-group-text px-2">GHS</span>
															<input
																className="form-control"
																id="price"
																type="number"
																name="price"
																value={formData[index].price}
																onChange={(e) => {
																	formData[index].price = e.target.value;
																	setformData([...formData]);
																}}
																required
															/>
														</div>
													</div>

													{/* Quantity Available */}
													{category != "service" && (
														<div className="col-lg-4">
															<label className="form-label" htmlFor="quantity_available">
																Quanity
															</label>
															<input
																className="form-control"
																id="quantity_available"
																name="quantity_available"
																type="number"
																min={0}
																value={formData[index].quantity_available}
																onChange={(e) => {
																	formData[index].quantity_available = e.target.value;
																	setformData([...formData]);
																}}
																required
															/>
														</div>
													)}

													{/* Measuring Unit singular */}

													<div className="col-lg-4">
														<label className="form-label" htmlFor="measuring_unit_singular">
															Measuring Unit (Singular)
														</label>
														<input
															className="form-control"
															id="measuring_unit_singular"
															name="measuring_unit_singular"
															placeholder="Eg. box, bottle"
															type="text"
															value={formData[index].measuring_unit_singular}
															onChange={(e) => {
																formData[index].measuring_unit_singular = e.target.value;
																setformData([...formData]);
															}}
															required
														/>
													</div>

													{/* Measuring unit plural */}
													<div className="col-lg-4">
														<label className="form-label" htmlFor="measuring_unit_plural">
															Measuring Unit (Plural)
														</label>
														<input
															className="form-control"
															id="measuring_unit_plural"
															name="measuring_unit_plural"
															placeholder="Eg. boxes, bottles"
															type="text"
															value={formData[index].measuring_unit_plural}
															onChange={(e) => {
																formData[index].measuring_unit_plural = e.target.value;
																setformData([...formData]);
															}}
															required
														/>
													</div>

													{/* SKU */}
													<div className="col-lg-4">
														<label className="form-label" htmlFor="sku">
															SKU
														</label>
														<input
															className="form-control"
															id="sku"
															name="sku"
															type="text"
															value={formData[index].sku}
															onChange={(e) => {
																formData[index].sku = e.target.value;
																setformData([...formData]);
															}}
														/>
													</div>

													{/* Image */}
													{formData[index].imagePreview && (
														<div className="col-12">
															<div className="border-dashed p-2" style={{ width: "250px" }} onClick={() => {}}>
																<img className="w-100" src={formData[index].imagePreview} alt="" />
															</div>
														</div>
													)}
													<div className="col-lg-4">
														<label className="form-label" htmlFor={`image_${index}`}>
															Choose an image for this item
														</label>
														<input
															className="form-control"
															id={`image_${index}`}
															name={`image_${index}`}
															type="file"
															onChange={async (e) => {
																let files = e.target.files;
																let fileReader = new FileReader();
																// const fileSize = e.target.files[0].size;

																// console.log(fileSize);

																// if (fileSize > 2048) {
																// 	setformErrorMesage(["Image size cannot exceed 2M"]);
																// }

																fileReader.readAsDataURL(files[0]);
																fileReader.onload = (event) => {
																	formData[index].image = event.target.result;
																	formData[index].imagePreview = URL.createObjectURL(e.target.files[0]);
																	setformData([...formData]);
																};
															}}
															accept="image/x-png,image/jpeg,image/webp"
														/>
													</div>
												</div>
											</div>
										</div>
									</li>
								</Fade>
							))}
						</ul>

						{formData.length < 10 && (
							<div className="d-flex">
								<button className="btn btn-falcon-default ms-auto" type="button" onClick={() => handleAddItem()}>
									<span className="fas fa-plus"></span>
									{/* Add {category === "services" ? "Service" : "Item"} */}
								</button>
							</div>
						)}
					</div>
					{formErrorMesage.length > 0 && (
						<div className="col-12">
							{formErrorMesage.map((error) => (
								<p className="text-danger m-0">{error}</p>
							))}
						</div>
					)}
					<div className="col-12 d-flex">
						<button className="btn btn-primary px-4" type="submit" disabled={isSubmitting}>
							<span>{`${isSubmitting ? "Saving..." : "Save"}`}</span>
						</button>
					</div>
				</form>
			</Fade>

			<Modal
				show={showModal}
				onHide={() => {
					setshowModal(false);
				}}
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Header closeButton className="border-0"></Modal.Header>
				<Modal.Body className="text-center">
					<img
						className="rounded-circle"
						width={"80"}
						src={process.env.PUBLIC_URL + "/assets/img/icons/success.png"}
						alt=""
					/>
					<div className="my-4">
						<h5>Success!</h5>
						<p>{`Your inventory has been saved.`}</p>
					</div>
					<div className="my-4">
						{/* <Link className="btn btn-falcon-primary me-3" to={`../appointment/new/`}>
							Create appointment for {formData.first_name}
						</Link> */}
						<Link className="btn btn-primary" to={`../inventory/${category === "service" ? "services" : category}`}>
							Continue
						</Link>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default AddInventoryItem;
