import { useState } from "react";

const SearchForm = () => {
  const [state, setstate] = useState({
    showSearchDropDown: false,
  });

  const handleSearchForm = (e) => {
    if (e.target.value) {
      setstate({ showSearchDropDown: true });
    } else {
      setstate({ showSearchDropDown: false });
    }
  };
  return (
    <>
      <div className="search-box" data-list='{"valueNames":["title"]}'>
        <form
          className={`position-relative ${state.showSearchDropDown && "show"}`}
          data-bs-toggle="search"
          data-bs-display="static"
          aria-expanded={`${state.showSearchDropDown ? "true" : "false"}`}>
          <input
            className="form-control search-input fuzzy-search"
            type="search"
            placeholder="Search..."
            aria-label="Search"
            onChange={(e) => handleSearchForm(e)}
          />
          <span className="fas fa-search search-box-icon"></span>
        </form>
        <div
          className="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
          data-bs-dismiss="search"
          onClick={(e) => setstate({ showSearchDropDown: false })}>
          <div className="btn-close-falcon" aria-label="Close"></div>
        </div>
        <div className={`dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100 ${state.showSearchDropDown && "show"}`}>
          <div className="scrollbar list py-3" style={{ maxHeight: "24rem" }}>
            <h6 className="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">Recently Browsed</h6>
            <a className="dropdown-item fs--1 px-card py-1 hover-primary" href="../app/events/event-detail.html">
              <div className="d-flex align-items-center">
                <span className="fas fa-circle me-2 text-300 fs--2"></span>

                <div className="fw-normal title">
                  Pages <span className="fas fa-chevron-right mx-1 text-500 fs--2" data-fa-transform="shrink-2"></span> Events
                </div>
              </div>
            </a>
            <a className="dropdown-item fs--1 px-card py-1 hover-primary" href="../app/e-commerce/customers.html">
              <div className="d-flex align-items-center">
                <span className="fas fa-circle me-2 text-300 fs--2"></span>

                <div className="fw-normal title">
                  E-commerce <span className="fas fa-chevron-right mx-1 text-500 fs--2" data-fa-transform="shrink-2"></span> Customers
                </div>
              </div>
            </a>

            <hr className="bg-200 dark__bg-900" />
            <h6 className="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">Suggested Filter</h6>
            <a className="dropdown-item px-card py-1 fs-0" href="../app/e-commerce/customers.html">
              <div className="d-flex align-items-center">
                <span className="badge fw-medium text-decoration-none me-2 badge-soft-warning">customers:</span>
                <div className="flex-1 fs--1 title">All customers list</div>
              </div>
            </a>
            <a className="dropdown-item px-card py-1 fs-0" href="../app/events/event-detail.html">
              <div className="d-flex align-items-center">
                <span className="badge fw-medium text-decoration-none me-2 badge-soft-success">events:</span>
                <div className="flex-1 fs--1 title">Latest events in current month</div>
              </div>
            </a>
            <a className="dropdown-item px-card py-1 fs-0" href="../app/e-commerce/product/product-grid.html">
              <div className="d-flex align-items-center">
                <span className="badge fw-medium text-decoration-none me-2 badge-soft-info">products:</span>
                <div className="flex-1 fs--1 title">Most popular products</div>
              </div>
            </a>

            <hr className="bg-200 dark__bg-900" />
            <h6 className="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">Files</h6>
            <a className="dropdown-item px-card py-2" href="#!">
              <div className="d-flex align-items-center">
                <div className="file-thumbnail me-2">
                  <img className="border h-100 w-100 fit-cover rounded-3" src="../assets/img/products/3-thumb.png" alt="" />
                </div>
                <div className="flex-1">
                  <h6 className="mb-0 title">iPhone</h6>
                  <p className="fs--2 mb-0 d-flex">
                    <span className="fw-semi-bold">Antony</span>
                    <span className="fw-medium text-600 ms-2">27 Sep at 10:30 AM</span>
                  </p>
                </div>
              </div>
            </a>
            <a className="dropdown-item px-card py-2" href="#!">
              <div className="d-flex align-items-center">
                <div className="file-thumbnail me-2">
                  <img className="img-fluid" src="../assets/img/icons/zip.png" alt="" />
                </div>
                <div className="flex-1">
                  <h6 className="mb-0 title">Falcon v1.8.2</h6>
                  <p className="fs--2 mb-0 d-flex">
                    <span className="fw-semi-bold">John</span>
                    <span className="fw-medium text-600 ms-2">30 Sep at 12:30 PM</span>
                  </p>
                </div>
              </div>
            </a>

            <hr className="bg-200 dark__bg-900" />
            <h6 className="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">Members</h6>
            <a className="dropdown-item px-card py-2" href="../pages/user/profile.html">
              <div className="d-flex align-items-center">
                <div className="avatar avatar-l status-online me-2">
                  <img className="rounded-circle" src="../assets/img/team/1.jpg" alt="" />
                </div>
                <div className="flex-1">
                  <h6 className="mb-0 title">Anna Karinina</h6>
                  <p className="fs--2 mb-0 d-flex">Technext Limited</p>
                </div>
              </div>
            </a>
            <a className="dropdown-item px-card py-2" href="../pages/user/profile.html">
              <div className="d-flex align-items-center">
                <div className="avatar avatar-l me-2">
                  <img className="rounded-circle" src="../assets/img/team/2.jpg" alt="" />
                </div>
                <div className="flex-1">
                  <h6 className="mb-0 title">Antony Hopkins</h6>
                  <p className="fs--2 mb-0 d-flex">Brain Trust</p>
                </div>
              </div>
            </a>
            <a className="dropdown-item px-card py-2" href="../pages/user/profile.html">
              <div className="d-flex align-items-center">
                <div className="avatar avatar-l me-2">
                  <img className="rounded-circle" src="../assets/img/team/3.jpg" alt="" />
                </div>
                <div className="flex-1">
                  <h6 className="mb-0 title">Emma Watson</h6>
                  <p className="fs--2 mb-0 d-flex">Google</p>
                </div>
              </div>
            </a>
          </div>
          <div className="text-center mt-n3">
            <p className="fallback fw-bold fs-1 d-none">No Result Found.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchForm;
