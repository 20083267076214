const Loader = () => {
  return (
    <>
      <div className="w-100 d-flex" style={{ height: "100vh" }}>
        <div className="spinner-grow text-primary m-auto" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Loader;
