export const languages = [
	{
		value: "akan",
		label: "Akan",
	},
	{
		value: "akposo",
		label: "Akposo",
	},
	{
		value: "anii or basila",
		label: "Anii or Basila",
	},
	{
		value: "dagaare",
		label: "Dagaare",
	},
	{
		value: "dagbani",
		label: "Dagbani",
	},
	{
		value: "english",
		label: "English",
	},
	{
		value: "ewe",
		label: "Ewe",
	},
	{
		value: "fante",
		label: "Fante",
	},
	{
		value: "french",
		label: "French",
	},
	{
		value: "ga",
		label: "Ga",
	},
	{
		value: "gonja",
		label: "Gonja",
	},
	{
		value: "kasem",
		label: "Kasem",
	},
	{
		value: "krobo",
		label: "Krobo",
	},
	{
		value: "nzema",
		label: "Nzema",
	},
	{
		value: "other",
		label: "Other",
	},
	{
		value: "twi",
		label: "Twi",
	},
];

// export default {
// 	languages,
// };
