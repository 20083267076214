import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="row justify-content-center min-vh-100 py-6 text-center">
        <div className="col-sm-10 col-md-5 col-lg-5 col-xxl-5">
          <Link className="d-flex flex-center mb-4" to="/facilities">
            <img className="me-2" src="../../assets/img/logo_text.svg" alt="" width="200" />
            {/* <span className="font-sans-serif fw-bolder fs-5 d-inline-block">falcon</span> */}
          </Link>
          <div className="card">
            <div className="card-body p-4 p-sm-5">
              <div className="fw-black lh-1 text-300 fs-error">404</div>
              <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">The page you're looking for is not found.</p>
              <hr />
              <p>
                Make sure the address is correct and that the page hasn't moved. If you think this is a mistake, <a href="mailto:rjbtechconsult.com">contact us</a>.
              </p>
              <Link className="btn btn-primary btn-sm mt-3" to="/facilities">
                <span className="fas fa-home me-2"></span>My Facilities
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
