import { useParams, useOutletContext } from "react-router-dom";
import vitalsService from "../../../../helpers/vitalsService";
import { useState, useEffect } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import miscellaneous from "../../../../helpers/miscellaneous";

const Vitals = () => {
	const { fid, visit_number } = useParams();
	const [isSubmitting, setisSubmitting] = useState(false);
	const [showModal, setshowModal] = useState(false);
	const [formFeedback, setformFeedback] = useState("");
	const [formError, setformError] = useState(false);
	const { setvitals, patient } = useOutletContext();

	const [formData, setformData] = useState({
		visit_number: visit_number,
		fid: fid,
		systolic_bp: "",
		diastolic_bp: "",
		temperature: "",
		weight: "",
		height: "",
		bmi: "",
		respiratory_rate: "",
		heart_rate: "",
		urine_protein: "",
		urine_sugar: "",
		blood_sugar_f: "",
		blood_sugar_r: "",
		spo2: "",
		avpu: "",
		trauma: "",
		mobility: "",
		oxygen_supplement: "",
		comments: "",
	});

	const handleBMI = (weight = formData.weight, height = formData.height) => {
		let bmi = miscellaneous.calculateBMI(weight, height);
		setformData({ ...formData, weight: weight, height: height, bmi: bmi });
	};

	const getVitals = () => {
		vitalsService
			.getVitals({ visit_number: visit_number, fid: fid })
			.then((response) => {
				if (response.data.success) {
					const vitals = response.data.vitals;
					Object.keys(vitals).forEach((k) => (vitals[k] = vitals[k] === null ? "" : vitals[k]));
					Object.assign(formData, vitals);
					setformData({ ...formData });
					setvitals(vitals);
					handleBMI(formData.weight, formData.height);
				}
			})
			.catch((eror) => {});
	};

	useEffect(() => {
		getVitals();
	}, []);

	useEffect(() => {
		if (formData.bmi === "NaN") {
			setformData({ ...formData, bmi: "" });
		}
	}, [formData.bmi]);

	const handleSubmit = (e) => {
		e.preventDefault();

		setformError(false);
		setisSubmitting(true);

		if (
			formData.systolic_bp === "" &&
			formData.diastolic_bp === "" &&
			formData.temperature === "" &&
			formData.weight === "" &&
			formData.height === "" &&
			formData.bmi === "" &&
			formData.respiratory_rate === "" &&
			formData.heart_rate === "" &&
			formData.urine_protein === "" &&
			formData.urine_sugar === "" &&
			formData.blood_sugar_f === "" &&
			formData.blood_sugar_r === "" &&
			formData.spo2 === "" &&
			formData.avpu === "" &&
			formData.trauma === "" &&
			formData.mobility === "" &&
			formData.oxygen_supplement === "" &&
			formData.comments === ""
		) {
			setformFeedback("You cannot submit an empty form.");
			setformError(true);
			setisSubmitting(false);
		} else {
			vitalsService
				.saveVitals(formData)
				.then((response) => {
					if (response.data.success) {
						setformFeedback(response.data.message);
						setisSubmitting(false);
						setshowModal(true);
						setvitals(response.data.vitals);
					}
				})
				.catch((error) => {
					console.log(error.response.data);
					setisSubmitting(false);
				});
		}
	};

	return (
		<>
			<div className="card mb-3 overflow-hidden">
				<div className="card-header">
					<h5>Vital Signs</h5>
					<h6 className="fw-normal">
						Feilds with <span className="text-warning">orange</span> markings are required
					</h6>
				</div>
				<div className="card-body  bg-light">
					<form className="row g-3 needs-validation" onSubmit={handleSubmit}>
						{/* Systolic B.P. */}
						{patient.age > 12 && (
							<div className="col-lg-8">
								<label className="form-label" htmlFor="systolic_bp">
									Systolic/Diastolic Blood Pressure (mmHg)
								</label>
								<div className="input-group">
									<input
										className="form-control"
										id="systolic_bp"
										name="systolic_bp"
										type="number"
										placeholder=""
										value={formData.systolic_bp}
										onChange={(e) => {
											setformData({ ...formData, systolic_bp: e.target.value });
										}}
									/>
									<span className="input-group-text px-2">/</span>
									{/* Diastolic B.P. */}
									<input
										className="form-control"
										id="diastolic_bp"
										name="diastolic_bp"
										type="number"
										placeholder=""
										value={formData.diastolic_bp}
										onChange={(e) => {
											setformData({ ...formData, diastolic_bp: e.target.value });
										}}
									/>
								</div>
							</div>
						)}

						{/* Diastolic B.P. */}
						{/* <div className="col-lg-4">
							<label className="form-label" htmlFor="diastolic_bp">
								Diastolic B.P. (mmHg)
							</label>
							<input
								className="form-control"
								id="diastolic_bp"
								name="diastolic_bp"
								type="number"
								placeholder=""
								value={formData.diastolic_bp}
								onChange={(e) => {
									setformData({ ...formData, diastolic_bp: e.target.value });
								}}
							/>
						</div> */}

						{/* Temperature */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="temperature">
								Temperature (<sup>o</sup>C)
							</label>
							<input
								className="form-control"
								id="temperature"
								name="temperature"
								type="number"
								placeholder=""
								value={formData.temperature}
								onChange={(e) => {
									setformData({ ...formData, temperature: e.target.value });
								}}
							/>
						</div>

						{/* Weight (KG) */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="weight">
								Weight (KG)
							</label>
							<input
								className="form-control"
								id="weight"
								name="weight"
								type="number"
								min={0}
								step="any"
								placeholder=""
								value={formData.weight}
								onChange={(e) => {
									handleBMI(e.target.value, formData.height);
									// setformData({ ...formData, weight: e.target.value, bmi: miscellaneous.calculateBMI(e.target.value, formData.height) });
								}}
							/>
						</div>

						{/* Height (M) */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="height">
								Height (M)
							</label>
							<input
								className="form-control"
								id="height"
								name="height"
								type="number"
								min={0}
								step="any"
								placeholder=""
								value={formData.height}
								onChange={(e) => {
									handleBMI(formData.weight, e.target.value);
									// setformData({ ...formData, height: e.target.value, bmi: miscellaneous.calculateBMI(formData.weight, e.target.value) });
								}}
							/>
						</div>

						{/* BMI (Kg/m2) */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="bmi">
								BMI (Kg/m<sup>2</sup>)
								{formData.weight > 0 && formData.height > 0 ? (
									<OverlayTrigger
										key={formData.bmi}
										placement="top"
										overlay={<Tooltip id={formData.bmi}>{miscellaneous.checkBMI(formData.bmi)}</Tooltip>}
									>
										<span className="ms-1">
											<i className="fa fa-info-circle"></i>
										</span>
									</OverlayTrigger>
								) : (
									""
								)}
							</label>
							<input
								className="form-control"
								id="bmi"
								name="bmi"
								type="number"
								placeholder=""
								value={formData.bmi}
								readOnly
								onChange={(e) => {
									setformData({ ...formData, bmi: e.target.value });
								}}
							/>
							{formData.weight > 0 && formData.height > 0 ? (
								<small className="text-warning">{miscellaneous.checkBMI(formData.bmi)}</small>
							) : (
								""
							)}
						</div>

						{/*  Respiratory Rate (/Min) */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="respiratory_rate">
								Respiratory Rate
							</label>
							<input
								className="form-control"
								id="respiratory_rate"
								name="respiratory_rate"
								type="number"
								placeholder=""
								value={formData.respiratory_rate}
								onChange={(e) => {
									setformData({ ...formData, respiratory_rate: e.target.value });
								}}
							/>
						</div>

						{/* Heart Rate (BPM) */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="heart_rate">
								Heart Rate (BPM)
							</label>
							<input
								className="form-control"
								id="heart_rate"
								name="heart_rate"
								type="number"
								min={0}
								placeholder=""
								value={formData.heart_rate}
								onChange={(e) => {
									setformData({ ...formData, heart_rate: e.target.value });
								}}
							/>
						</div>

						{/* Blood Sugar (F) */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="blood_sugar_f">
								Blood Sugar (F)
							</label>
							<input
								className="form-control"
								id="blood_sugar_f"
								name="blood_sugar_f"
								type="number"
								placeholder=""
								value={formData.blood_sugar_f}
								onChange={(e) => {
									setformData({ ...formData, blood_sugar_f: e.target.value });
								}}
							/>
						</div>

						{/* Blood Sugar (R) */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="blood_sugar_r">
								Blood Sugar (R)
							</label>
							<input
								className="form-control"
								id="blood_sugar_r"
								name="blood_sugar_r"
								type="number"
								placeholder=""
								value={formData.blood_sugar_r}
								onChange={(e) => {
									setformData({ ...formData, blood_sugar_r: e.target.value });
								}}
							/>
						</div>

						{/* SPO2 */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="spo2">
								SpO2 (%)
							</label>
							<input
								className="form-control"
								id="spo2"
								name="spo2"
								type="number"
								placeholder=""
								value={formData.spo2}
								onChange={(e) => {
									setformData({ ...formData, spo2: e.target.value });
								}}
							/>
						</div>

						{/* Trauma */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="trauma">
								Trauma
							</label>
							<select
								className="form-select"
								id="trauma"
								value={formData.trauma}
								name="trauma"
								onChange={(e) => {
									setformData({ ...formData, trauma: e.target.value });
								}}
							>
								<option value="" hidden disabled></option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</select>
						</div>

						{/* Urine Output */}
						<div className="col-lg-8">
							<label className="form-label" htmlFor="urine_protein">
								Urine (Protein/Sugar)
							</label>
							<div className="input-group">
								<select
									className="form-select"
									id="urine_protein"
									value={formData.urine_protein}
									name="urine_protein"
									onChange={(e) => {
										setformData({ ...formData, urine_protein: e.target.value });
									}}
								>
									<option value="" hidden></option>
									<option value="trace">Trace</option>
									<option value="negative">Negative</option>
								</select>
								{/* <input
									className="form-control"
									id="urine_output"
									name="urine_output"
									type="number"
									placeholder=""
									value={formData.urine_output}
									onChange={(e) => {
										setformData({ ...formData, urine_output: e.target.value });
									}}
								/> */}
								<span className="input-group-text px-2">/</span>
								<select
									className="form-select"
									id="urine_sugar"
									value={formData.urine_sugar}
									name="urine_sugar"
									onChange={(e) => {
										setformData({ ...formData, urine_sugar: e.target.value });
									}}
								>
									<option value="" hidden></option>
									<option value="trace">Trace</option>
									<option value="negative">Negative</option>
								</select>
								{/* <input
									className="form-control"
									id="urine_output"
									name="urine_output"
									type="number"
									placeholder=""
									value={formData.urine_output}
									onChange={(e) => {
										setformData({ ...formData, urine_output: e.target.value });
									}}
								/> */}
							</div>
						</div>

						{/* AVPU */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="avpu">
								AVPU
							</label>
							<select
								className="form-select"
								id="avpu"
								value={formData.avpu}
								name="avpu"
								onChange={(e) => {
									setformData({ ...formData, avpu: e.target.value });
								}}
							>
								<option value="" hidden></option>
								<option value="alert">Alert</option>
								{patient.age >= 3 && <option value="confused">Confused</option>}
								<option value="reacts to pain">Reacts to pain</option>
								<option value="reacts to voice">Reacts to voice</option>
								<option value="unresponsive">Unresponsive</option>
							</select>
						</div>

						{/* Mobility */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="mobility">
								Mobility
							</label>
							<select
								className="form-select"
								id="mobility"
								value={formData.mobility}
								name="mobility"
								onChange={(e) => {
									setformData({ ...formData, mobility: e.target.value });
								}}
							>
								<option value="" hidden></option>

								{patient.age > 12 && (
									<>
										<option value="walking">Walking</option>
										<option value="with help">With help</option>
										<option value="immobile">Immobile</option>
									</>
								)}

								{patient.age <= 12 && (
									<>
										<option value="normal for age">Normal for age</option>
										<option value="unable to walk as normal">Unable to walk as normal</option>
									</>
								)}
							</select>
						</div>

						{/* Oxygen Supplementation */}
						<div className="col-lg-4">
							<label className="form-label" htmlFor="oxygen_supplement">
								Oxygen Supplement
							</label>
							<select
								className="form-select"
								id="oxygen_supplement"
								value={formData.oxygen_supplement}
								name="oxygen_supplement"
								onChange={(e) => {
									setformData({ ...formData, oxygen_supplement: e.target.value });
								}}
							>
								<option value="" hidden></option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</select>
						</div>

						{/* Comments */}
						<div className="col-lg-12">
							<label className="form-label" htmlFor="comments">
								Comments
							</label>
							<textarea
								className="form-control"
								name="comments"
								value={formData.comments}
								id="comments"
								rows="3"
								onChange={(e) => {
									setformData({ ...formData, comments: e.target.value });
								}}
							/>
						</div>

						{formError && (
							<div className="col-12">
								<p className={`shake text-danger m-0`}>{formFeedback}</p>
							</div>
						)}

						<div className="col-12 d-flex">
							<button className="btn btn-primary px-4" type="submit" disabled={isSubmitting}>
								{`${isSubmitting ? "Saving..." : "Save"}`}
							</button>
						</div>
					</form>
				</div>
			</div>

			<Modal
				show={showModal}
				onHide={() => {
					setshowModal(false);
				}}
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Header closeButton className="border-0"></Modal.Header>
				<Modal.Body className="text-center">
					<img
						className="rounded-circle"
						width={"80"}
						src={process.env.PUBLIC_URL + "/assets/img/icons/success.png"}
						alt=""
					/>
					<div className="my-4">
						<h5>Success!</h5>
						<p className="m-0">{formFeedback}</p>
					</div>
					<div className="my-4">
						{/* <Button variant="outline-secondary me-2" onClick={handleCloseModal}>
							Create visit
						</Button> */}
						<button
							className="btn btn-primary px-4"
							onClick={() => {
								setshowModal(false);
							}}
						>
							Ok
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Vitals;
