import GoogleLogin from "react-google-login";
import { useLocation, useNavigate } from "react-router-dom";
import authService from "../helpers/authService";
import { useState } from "react";
import tokenService from "../helpers/tokenService";
import { useDispatch } from "react-redux";
import { fetchUser } from "../app/slices/userSlice";
// import { updateUserInfo } from "../app/slices/userSlice";

const GoogleButton = () => {
	const location = useLocation();
	const route_name = location.pathname;
	const [errorMessage, seterrorMessage] = useState("");
	let navigate = useNavigate();
	let dispatch = useDispatch();

	const googleResponse = (response) => {
		seterrorMessage("");
		// console.log(response);
		if (!response.error) {
			let formData = {
				tokenId: response.tokenId,
			};
			googleAuth(formData);
		} else {
			// seterrorMessage(response.error);
		}
	};

	function googleAuth(data) {
		authService
			.googleAuth(data)
			.then(async (response) => {
				console.log(response);

				if (response.data.message === "success") {
					await tokenService.setToken(response);
					let userInfo = await response.data.user_obj;
					await dispatch(fetchUser());
					// await dispatch(updateUserInfo(userInfo));
					// navigate("/facilities");
					window.location.href = "/facilities";
				}

				if (response.data.error) {
					// seterrorMessage(response.data.error);
				}
			})
			.catch((error) => {
				console.log(error);
				// if (error > 0) {
				//   setformError(true);
				//   setisLoading(false);
				// }
			});
	}

	return (
		<>
			{errorMessage.length > 0 && (
				<p className={`text-danger text-center ${errorMessage.length > 0 && "shake"}`}>{errorMessage}</p>
			)}
			<GoogleLogin
				clientId="281613013412-q11jthq9mnfnfe4a619j63jhucg7vqc5.apps.googleusercontent.com"
				buttonText="Continue with Google" //{route_name === "/register" ? "Register with Google" : "Login with Google"}
				onSuccess={googleResponse}
				onFailure={googleResponse}
				cookiePolicy={"single_host_origin"}
				className="d-flex justify-content-center w-100"
				onRequest={() => {
					seterrorMessage("");
				}}
			/>
		</>
	);
};

export default GoogleButton;
