import { Link } from "react-router-dom";

const PageHeader = ({ title, subTitle, showButton, buttonText, buttonRoute }) => {
	return (
		<>
			<div className="row gx-0 flex-between-center mb-3">
				<div className="col-sm-auto d-flex align-items-center">
					<div>
						<h6 className="text-info fs--1 mb-0 text-capitalize">{title}</h6>
						<h4 className="text-primary fw-bold mb-0 text-capitalize">{subTitle}</h4>
					</div>
				</div>
				{showButton && (
					<div className="col-md-auto">
						<Link className="btn btn-primary" to={buttonRoute}>
							<span className="fas fa-plus me-2"></span> {buttonText}
						</Link>
					</div>
				)}
				{/* <form className="row align-items-center g-3">
					<div className="col-auto">
						<h6 className="text-700 mb-0">Showing Data For: </h6>
					</div>
					<div className="col-md-auto position-relative">
						<input className="form-control form-control-sm datetimepicker ps-4" id="CRMDateRange" type="text" data-options='{"mode":"range","dateFormat":"M d","disableMobile":true , "defaultDate": ["Feb 28", "Mar 07"] }' />
						<span className="fas fa-calendar-alt text-primary position-absolute top-50 translate-middle-y ms-2"> </span>
					</div>
				</form> */}
			</div>
		</>
	);
};

export default PageHeader;
