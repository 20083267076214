import requester from "./requester";

const saveDiagnosis = (payload) => {
	return requester.post("/diagnosis/new", payload);
};

const getDiagnoses = (visit_number, fid) => {
	return requester.get(`/diagnoses/${visit_number}/${fid}`);
};

const deleteDiagnosis = (visit_number, fid, code) => {
	return requester.delete(`/diagnosis/${visit_number}/${fid}/${code}`);
};

const diagnosisService = {
	saveDiagnosis,
	getDiagnoses,
	deleteDiagnosis,
};

export default diagnosisService;
