import { Link } from "react-router-dom";
import { useState } from "react";
import AuthService from "../../helpers/authService";
import GoogleButton from "../GoogleButton";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

import { loginPending, loginSuccess, loginFail } from "../../app/slices/loginSlice";

const Login = () => {
	const dispatch = useDispatch();

	const { isLoading, error } = useSelector((state) => state.login);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		dispatch(loginPending());

		try {
			const response = await AuthService.loginUser({ email, password });
			if (response.success) {
				dispatch(
					loginSuccess({
						token: response.access_token,
						expires: response.expires_in,
					})
				);
				window.location.href = "/facilities";
			}
		} catch (error) {
			console.log(error.response);
			dispatch(loginFail(error.response.data.message));
		}
	};

	return (
		<>
			<main className="main" id="top">
				<div className="container-fluid">
					<div className="row min-vh-100 flex-center g-0">
						<div className="col-lg-6 col-xxl-6 py-3 position-relative">
							<img
								className="bg-auth-circle-shape"
								src="../../../assets/img/icons/spot-illustrations/bg-shape.png"
								alt=""
								width="250"
							/>
							<img
								className="bg-auth-circle-shape-2"
								src="../../../assets/img/icons/spot-illustrations/shape-1.png"
								alt=""
								width="150"
							/>
							<div className="card overflow-hidden z-index-1">
								<div className="card-body p-0">
									<div className="row g-0 h-100">
										<div className="col-md-6 text-center bg-card-gradient d-none d-md-block">
											<div className="position-relative p-4 pt-md-5 pb-md-7 light">
												<div
													className="bg-holder bg-auth-card-shape"
													style={{
														backgroundImage: "url(../../../assets/img/icons/spot-illustrations/half-circle.png)",
													}}
												></div>

												<div className="z-index-1 position-relative">
													<a
														className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder"
														href="../../../index.html"
													>
														apomudin
													</a>
													<p className="opacity-75 text-white">
														With the power of Apomudin you can avoid the delays of manual data entry by extracting your
														invoices from patient records. With Apomudin, you can save time and work more efficiently.
													</p>
												</div>
											</div>
											<div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
												<p className="text-white">
													Don't have an account?
													<br />
													<Link className="text-decoration-underline link-light" to="/register">
														Get started!
													</Link>
												</p>
												<p className="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold text-white opacity-75">
													Read our{" "}
													<Link className="text-decoration-underline text-white" to="/privacy-policy">
														terms
													</Link>{" "}
													and{" "}
													<a className="text-decoration-underline text-white" href="#!">
														conditions{" "}
													</a>
												</p>
											</div>
										</div>

										<div className="col-md-6 d-flex flex-center">
											<div className="p-4 p-md-5 flex-grow-1">
												<h3 className="text-center">Login</h3>

												{/* login with google */}
												<div className="row g-2 mt-2 justify-content-center">
													<div className="col-12">
														<GoogleButton />
													</div>
												</div>

												<div className="position-relative my-4">
													<hr className="bg-300" />
													<div className="divider-content-center">Or fill the form below to register</div>
												</div>

												<form onSubmit={handleSubmit} className="needs-validation" noValidate="" autoComplete="on">
													<div className="mb-3">
														<label className="form-label" htmlFor="email">
															Email address
														</label>
														<input
															className="form-control"
															id="email"
															name="email"
															value={email}
															onChange={(e) => setEmail(e.target.value)}
															type="email"
															autoComplete="on"
															required
														/>
													</div>
													<div className="mb-3">
														<div className="d-flex justify-content-between">
															<label className="form-label" htmlFor="password">
																Password
															</label>
														</div>
														<input
															className="form-control"
															id="password"
															name="password"
															value={password}
															onChange={(e) => setPassword(e.target.value)}
															type="password"
															autoComplete="on"
															required
														/>
													</div>
													<div className="row flex-between-center">
														<div className="col-auto">
															<div className="form-check mb-0">
																<input
																	className="form-check-input"
																	type="checkbox"
																	id="card-checkbox"
																	onChange={() => {}}
																/>
																<label className="form-check-label mb-0" htmlFor="card-checkbox">
																	Remember me
																</label>
															</div>
														</div>
														<div className="col-auto">
															<a className="fs--1" href="../../../pages/authentication/card/forgot-password.html">
																Forgot Password?
															</a>
														</div>
													</div>
													<div className="my-3">
														<p className={`text-danger mb-3 text-center ${error.length > 0 && "shake"} `}>{error}</p>
														<button
															className="btn btn-primary d-block w-100 text-center "
															type="submit"
															name="submit"
															disabled={isLoading}
														>
															{!isLoading && <span className="d-block align-items-center text-center">Login</span>}
															{isLoading && (
																<span className="d-flex align-items-center justify-content-center">
																	<Spinner animation="grow" className="me-2" size="sm" variant="light" />
																	Logging in
																</span>
															)}
														</button>
													</div>
												</form>
												<div className="text-center d-block d-md-none">
													<p className="mb-0">
														<span className="me-2">Don't have an account?</span>
														<Link className="text-decoration-underline" to="/register">
															Register here!
														</Link>
													</p>
													<p className="mb-0 mt-3 fs--1 fw-semi-bold opacity-75">
														Read our{" "}
														<Link className="text-decoration-underline" to="/privacy-policy">
															terms
														</Link>{" "}
														and{" "}
														<a className="text-decoration-underline" href="#!">
															conditions{" "}
														</a>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default Login;
