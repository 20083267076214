import * as ECT from "@whoicd/icd11ect";
import "@whoicd/icd11ect/style.css";
import { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import diagnosisService from "../../../../helpers/diagnosisService";

const Diagnosis = () => {
	const { fid, visit_number } = useParams();
	const [diagnosis, setDiagnosis] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [changeDiagnosis, setChangeDiagnosis] = useState(1);
	const [iNo, setINo] = useState(1);
	const diagnosisRef = useRef(null);

	function openDiagnosisModal() {
		let diag = changeDiagnosis + 1;
		setChangeDiagnosis(diag);
		setShowModal(true);
	}

	function focusInput() {
		diagnosisRef.current && diagnosisRef.current.focus();
	}

	function onDiagnosisSelected(selectedDiagnosis) {
		handleSubmit(selectedDiagnosis);
		// const alreadySelected = diagnosis.filter((diag) => diag.code === selectedDiagnosis.code);
		// if (!alreadySelected.length) {
		// 	setDiagnosis((prevDiagnosis) => [...prevDiagnosis, selectedDiagnosis]);
		// }
		// setShowModal(false);
		// ECT.Handler.clear(iNo);
	}

	function initializeICD11Tool() {
		// For documentation: https://icd.who.int/icdapi/docs2/icd11ect-1.6-EmbeddedCodingTool/
		const settings = {
			apiServerUrl: "https://icd11restapi-developer-test.azurewebsites.net",
			autoBind: false,
			language: "en",
			sourceApp: "Apomudin",
			searchByCodeOrURI: true,
			enableKeyboard: true,
			flexisearchAvailable: true,
		};
		const callbacks = {
			selectedEntityFunction: (selectedEntity) => onDiagnosisSelected(selectedEntity),
		};
		ECT.Handler.configure(settings, callbacks);

		// manual binding only after the component has been mounted
		ECT.Handler.bind(iNo);

		return () => {
			// Clean up the ECT bindings when the component is unmounted
			ECT.Handler.clear(iNo);
		};
	}

	async function handleSubmit(diagnosisToSave) {
		const payload = {
			facility_id: fid,
			visit_number: visit_number,
			description: diagnosisToSave.title,
			icd11_code: diagnosisToSave.code,
		};

		diagnosisService
			.saveDiagnosis(payload)
			.then((response) => {
				const alreadySelected = diagnosis.filter((diag) => diag.code === response.data.diagnosis);
				if (!alreadySelected.length) {
					setDiagnosis((prevDiagnosis) => [...prevDiagnosis, diagnosisToSave]);
				}

				setShowModal(false);
				ECT.Handler.clear(iNo);
			})
			.catch((error) => console.log(error));
	}

	async function deleteDiagnosis(code) {
		diagnosisService
			.deleteDiagnosis(visit_number, fid, code)
			.then((response) => {
				console.log(response);
				getDiagnosis();
			})
			.catch((e) => {});
	}

	async function getDiagnosis() {
		diagnosisService
			.getDiagnoses(visit_number, fid)
			.then((response) => {
				const newDiagnoses = response.data.diagnoses.map((diagnosis) => {
					return {
						title: diagnosis.description,
						code: diagnosis.icd11_code,
					};
				});
				setDiagnosis(newDiagnoses);
			})
			.catch((error) => {});
	}

	useEffect(() => initializeICD11Tool, [changeDiagnosis]);
	useEffect(() => getDiagnosis, []);

	return (
		<>
			<div className="card mb-3 overflow-hidden">
				<div className="card-header">
					<h5>Diagnosis</h5>
				</div>
				<div className="card-body  bg-light">
					<form className="row g-3 needs-validation" onSubmit={handleSubmit}>
						<div className="col-12">
							{diagnosis.length > 0 && (
								<div className="d-flex mb-3 gap-2 flex-wrap">
									{diagnosis.sort().map((data, index) => (
										<div className="" key={index + data}>
											<div className="badge fw-semi-bold badge-soft-warning d-flex flex-column text-start selected-diagnosis">
												<span className="text-black mb-1">{data.title}</span>
												<span className=""> ICD-11: {data.code}</span>

												<a className="pointer remove" onClick={() => deleteDiagnosis(data.code)} title="Remove">
													<i className="fas fa-times text-danger fa-lg"></i>
												</a>
											</div>
										</div>
									))}
								</div>
							)}

							<a
								className="mb-4 d-block d-flex align-items-center"
								href="#experience-form"
								data-bs-toggle="collapse"
								aria-expanded="true"
								aria-controls="experience-form"
								onClick={openDiagnosisModal}
							>
								<span className="circle-dashed">
									<span className="fas fa-plus"></span>
								</span>
								<span className="ms-3">Add diagnosis</span>
							</a>
						</div>
						{/* <div className="col-lg-6">
							<label className="form-label" htmlFor="attendance">
								Attendance
							</label>
							<select name="attendance" id="attendance" className="form-select">
								<option value="">Emergency/Acute</option>
								<option value="">Chronic Follow up</option>
							</select>
						</div>
						<div className="col-lg-6">
							<label className="form-label" htmlFor="type">
								Type
							</label>
							<select name="type" id="type" className="form-select">
								<option value="">Provisional</option>
								<option value="">Principal</option>
							</select>
						</div>
						<div className="col-lg-6">
							<label className="form-label" htmlFor="category">
								Category
							</label>
							<select name="category" id="category" className="form-select">
								<option value="">Primary</option>
								<option value="">Additional</option>
							</select>
						</div> */}

						{/* <div className="col-12 d-flex">
							<button className="btn btn-primary px-4" type="submit" disabled={""}>
								Save
							</button>
						</div> */}
					</form>
				</div>
			</div>

			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				dialogClassName="modal-90w"
				contentClassName="min-vh-50"
				animation={false}
				size="lg"
				onShow={focusInput}
			>
				<Modal.Header closeButton>
					<Modal.Title>Search a diagnosis</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<input
						type="text"
						className="ctw-input form-control"
						autoComplete="true"
						data-ctw-ino={iNo}
						id="diagnosisInput"
						ref={diagnosisRef}
					/>
					<div className="ctw-window mt-3" data-ctw-ino={iNo}></div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Diagnosis;
