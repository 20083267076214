import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import AuthService from "../../helpers/authService";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import GoogleButton from "../GoogleButton";
import tokenService from "../../helpers/tokenService";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../app/slices/userSlice";

const Register = () => {
	const [first_name, setfirst_name] = useState("");
	const [middle_name, setmiddle_name] = useState("");
	const [last_name, setlast_name] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setphone] = useState("");
	const [password, setPassword] = useState("");
	const [isLoading, setisLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [formError, setformError] = useState({
		first_name: "",
		middle_name: "",
		last_name: "",
		email: "",
		phone: "",
		password: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		setisLoading(true);
		const formData = {
			first_name: first_name,
			middle_name: middle_name,
			last_name: last_name,
			email: email,
			phone: phone,
			password: password,
		};

		registerUser(formData);
	};

	function registerUser(data) {
		AuthService.registerUser(data)
			.then(async (response) => {
				console.log(response);

				if (response.data.errors) {
					let newFormError = formError;

					if (response.data.errors.first_name) {
						newFormError.first_name = response.data.errors.first_name[0];
					} else {
						newFormError.first_name = "";
					}

					if (response.data.errors.last_name) {
						newFormError.last_name = response.data.errors.last_name[0];
					} else {
						newFormError.last_name = "";
					}

					if (response.data.errors.middle_name) {
						newFormError.middle_name = response.data.errors.middle_name[0];
					} else {
						newFormError.middle_name = "";
					}

					if (response.data.errors.email) {
						newFormError.email = response.data.errors.email[0];
					} else {
						newFormError.email = "";
					}

					if (response.data.errors.phone) {
						newFormError.phone = response.data.errors.phone[0];
					} else {
						newFormError.phone = "";
					}

					if (response.data.errors.password) {
						newFormError.password = response.data.errors.password[0];
					} else {
						newFormError.password = "";
					}

					setformError({
						first_name: newFormError.first_name,
						middle_name: newFormError.middle_name,
						last_name: newFormError.last_name,
						email: newFormError.email,
						phone: newFormError.phone,
						password: newFormError.password,
					});
					setisLoading(false);
				}
				if (response.data.message === "success") {
					await tokenService.setToken(response);
					await dispatch(fetchUser());
					navigate("/");
				}
			})
			.catch((error) => {
				setformError(true);
				setisLoading(false);
			});
	}

	return (
		<>
			<main className="main" id="top">
				<div className="container-fluid">
					<div className="row min-vh-100 flex-center g-0">
						<div className="col-lg-8 col-xxl-5 py-3 position-relative">
							<img
								className="bg-auth-circle-shape"
								src="../../../assets/img/icons/spot-illustrations/bg-shape.png"
								alt=""
								width="250"
							/>
							<img
								className="bg-auth-circle-shape-2"
								src="../../../assets/img/icons/spot-illustrations/shape-1.png"
								alt=""
								width="150"
							/>

							<div className="card overflow-hidden z-index-1">
								<div className="card-body p-0">
									<div className="row g-0 h-100">
										<div className="col-md-5 text-center bg-card-gradient d-none d-md-block">
											<div className="position-relative p-4 pt-md-5 pb-md-7 light">
												<div
													className="bg-holder bg-auth-card-shape"
													style={{
														backgroundImage: "url(../../../assets/img/icons/spot-illustrations/half-circle.png)",
													}}
												></div>

												<div className="z-index-1 position-relative">
													<a
														className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder"
														href="../../../index.html"
													>
														apomudin
													</a>
													<p className="opacity-75 text-white">
														With the power of Apomudin you can avoid the delays of manual data entry by extracting your
														invoices from patient records. With Apomudin, you can save time and work more efficiently.
													</p>
												</div>
											</div>
											<div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
												<p className="pt-3 text-white">
													Have an account?
													<br />
													<Link className="btn btn-outline-light mt-2 px-4" to="/login">
														Log In
													</Link>
												</p>
											</div>
										</div>
										<div className="col-md-7 d-flex flex-center">
											<div className="p-4 p-md-5 flex-grow-1">
												<h3 className="text-center">Register</h3>

												{/* login with google */}
												<div className="row g-2 mt-2 justify-content-center">
													<div className="col-12">
														<GoogleButton />
													</div>
												</div>

												<div className="position-relative my-4">
													<hr className="bg-300" />
													<div className="divider-content-center">Or fill the form below to register</div>
												</div>

												<form onSubmit={handleSubmit} className={`needs-validation`} noValidate="">
													<div className="row gx-2">
														<div className="mb-3 col-sm-6">
															<label className="form-label" htmlFor="first_name">
																First name
															</label>
															<input
																className={`form-control ${formError?.first_name?.length > 0 ? "is-invalid" : ""}`}
																type="text"
																name="first_name"
																autoComplete="on"
																id="first_name"
																value={first_name}
																onChange={(e) => {
																	setfirst_name(e.target.value);
																}}
																required
															/>
															<div className="invalid-feedback">{formError.first_name}</div>
														</div>
														<div className="mb-3 col-sm-6">
															<label className="form-label" htmlFor="last_name">
																Last name
															</label>
															<input
																className={`form-control ${formError?.last_name?.length > 0 ? "is-invalid" : ""}`}
																type="text"
																name="last_name"
																autoComplete="on"
																id="last_name"
																value={last_name}
																onChange={(e) => setlast_name(e.target.value)}
																required
															/>
															<div className="invalid-feedback">{formError?.last_name}</div>
														</div>
													</div>
													<div className="row gx-2">
														<div className="mb-3 col-sm-6">
															<label className="form-label" htmlFor="middle_name">
																Middle name
															</label>
															<input
																className={`form-control ${formError?.middle_name?.length > 0 ? "is-invalid" : ""}`}
																type="text"
																name="middle_name"
																autoComplete="on"
																id="middle_name"
																value={middle_name}
																onChange={(e) => setmiddle_name(e.target.value)}
																required
															/>
															<div className="invalid-feedback">{formError?.middle_name}</div>
														</div>
														<div className="mb-3 col-sm-6">
															<label className="form-label" htmlFor="email">
																Email
															</label>
															<input
																className={`form-control ${formError?.email?.length > 0 ? "is-invalid" : ""}`}
																type="email"
																name="email"
																autoComplete="on"
																id="email"
																value={email}
																onChange={(e) => setEmail(e.target.value)}
																required
															/>
															<div className="invalid-feedback">{formError.email}</div>
														</div>
													</div>
													<div className="row gx-2">
														<div className="mb-3 col-sm-6">
															<label className="form-label" htmlFor="phone">
																Phone number
															</label>
															<IntlTelInput
																preferredCountries={["gh"]}
																onlyCountries={["gh"]}
																onPhoneNumberChange={(status, phone, country, e) => {
																	const re = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
																	if (re.test(phone) || phone === "") {
																		setphone(phone);
																	}
																}}
																inputClassName={`form-control required ${
																	formError.phone.length > 0 ? "is-invalid" : ""
																}`}
																containerClassName="intl-tel-input w-100 w-md-auto d-block"
																value={phone}
																defaultCountry="gh"
																autoComplete="on"
																nationalMode="true"
																formatOnInit="true"
																format="true"
																fieldId="phone"
																fieldName="phone"
																useMobileFullscreenDropdown="true"
															/>
															<div className={`invalid-feedback ${formError?.phone?.length > 0 ? "d-block" : ""}`}>
																{formError.phone}
															</div>
														</div>
														<div className="mb-3 col-sm-6">
															<label className="form-label" htmlFor="password">
																Password
															</label>
															<input
																className={`form-control ${formError?.password?.length > 0 ? "is-invalid" : ""}`}
																type="password"
																name="password"
																autoComplete="on"
																id="password"
																value={password}
																onChange={(e) => setPassword(e.target.value)}
																required
															/>
															<div className="invalid-feedback">{formError.password}</div>
														</div>
													</div>
													<div className="mb-3 col-12 text-center text-md-start">
														<label className="form-label" htmlFor="accept_terms">
															By registering I agree to Apomudin's <a href="#!">terms </a>
															and <a href="#!">privacy policy</a>
														</label>
													</div>

													<div className="mb-3">
														<button
															type="submit"
															className="btn btn-primary d-block w-100 mt-3"
															name="submitRegister"
															disabled={isLoading}
														>
															{!isLoading && <span>Register</span>}
															{isLoading && <span>Loading...</span>}
														</button>
													</div>
												</form>
												<div className="text-center d-block d-md-none">
													<p className="mb-0">
														<span className="me-2">Have an account?</span>
														<Link className="text-decoration-underline" to="/login">
															Login
														</Link>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default Register;
