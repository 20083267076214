import { createSlice } from "@reduxjs/toolkit";
import tokenService from "../../helpers/tokenService";

const initialState = {
	isLoading: false,
	isAuth: false,
	error: "",
};

export const loginSlice = createSlice({
	name: "login",
	initialState,
	reducers: {
		loginPending: (state) => {
			state.isLoading = true;
		},
		loginSuccess: (state, { payload }) => {
			// state.isLoading = false;
			state.isAuth = true;
			state.error = "";
			tokenService.setToken(payload);
		},
		loginFail: (state, { payload }) => {
			state.isLoading = false;
			state.error = payload;
		},
	},
});

// Action creators are generated for each case reducer function
const { reducer, actions } = loginSlice;

export const { loginPending, loginSuccess, loginFail } = actions;

export default reducer;
