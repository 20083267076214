const MedicalRecords = () => {
	return (
		<>
			<div className="card">
				<div className="card-body">
					<h1>Medical Records</h1>
					Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam, ipsa maiores inventore harum doloribus,
				</div>
			</div>
		</>
	);
};

export default MedicalRecords;
