import { createSlice } from "@reduxjs/toolkit";

export const facilitySlice = createSlice({
	name: "facility",
	initialState: {
		value: {},
		selected: "",
	},
	reducers: {
		updateFacility: (state, action) => {
			state.value = action.payload;
		},
		setSelectedFacility: (state, action) => {
			state.selected = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateFacility, setSelectedFacility } = facilitySlice.actions;

export default facilitySlice.reducer;
