import EmptyState from "./EmptyState";
import AllFacilities from "./AllFacilities";
import Layout from "../Layout";
import { useEffect, useState } from "react";
import facilityService from "../../helpers/facilityService";
import { Link } from "react-router-dom";
import Loader from "../Loader";

const Facilities = () => {
	const [allFacilities, setallFacilities] = useState([]);
	const [hasFacilities, sethasFacilities] = useState(false);
	const [isLoading, setisLoading] = useState(true);

	const getUserFacilities = async () => {
		try {
			const response = await facilityService.getUserFacilities();
			// console.log(response);
			if (response.owned.length === 0 && response.staff.length === 0) {
				sethasFacilities(false);
				setisLoading(false);
			} else {
				setallFacilities(response);
				sethasFacilities(true);
				setisLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getUserFacilities();
	}, []);
	return <>{isLoading ? <Loader /> : <Layout>{hasFacilities ? <AllFacilities facilities={allFacilities} /> : <EmptyState />}</Layout>}</>;
};

export default Facilities;
