import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateRole } from "../../app/slices/roleSlice";
import { updateFacility } from "../../app/slices/facilitySlice";

const AllFacilities = (props) => {
	// console.log(props.facilities);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleNavigation = async (facility) => {
		// console.log(facility);
		const slug = await facility.name.replace(/ +/g, "-").toLowerCase();

		await dispatch(updateRole(facility.role));
		await navigate(`/${facility.fid}/${slug}`);
		// await navigate(`/dashboard/${facility.fid}/${slug}/overview`);
		// window.location.href = `/dashboard/${facility.fid}/${slug}/overview`;
	};

	return (
		<>
			<div className="mt-5"></div>
			<div className="row justify-content-center">
				<div className="col-md-8">
					<div className="card mb-3 min-vh-50">
						<div className="card-body">
							<div className="row">
								<div className="col-12">
									<div className="overflow-hidden">
										<ul className="nav nav-tabs" id="myTab" role="tablist">
											<li className="nav-item">
												<a className="nav-link active ps-0" id="my-facilities-tab" data-bs-toggle="tab" href="#tab-my-facilities" role="tab" aria-controls="tab-my-facilities" aria-selected="true">
													My Facilities
												</a>
											</li>
											<li className="nav-item">
												<a className="nav-link px-2 px-md-3" id="my-employers-tab" data-bs-toggle="tab" href="#tab-employers" role="tab" aria-controls="tab-employers" aria-selected="false">
													Facilities I Work With
												</a>
											</li>
										</ul>
										<div className="tab-content" id="myTabContent">
											<div className="tab-pane fade show active" id="tab-my-facilities" role="tabpanel" aria-labelledby="my-facilities-tab">
												{props.facilities.owned.length > 0 ? (
													<div className="mt-3">
														<Link className="mb-4 d-block d-flex align-items-center" to="/facility/new" aria-expanded="false" aria-controls="education-form">
															<span className="circle-dashed">
																<span className="fas fa-plus"></span>
															</span>
															<span className="ms-3">Add new facility</span>
														</Link>

														{props.facilities.owned.map((facility, index, totalData) => {
															const initials = facility.name
																.match(/(^.{0,2})?/g)
																.join("")
																.toUpperCase();

															const slug = facility.name.replace(/ +/g, "-").toLowerCase();

															return (
																<div className="d-flex" key={facility.fid}>
																	<Link
																		to="#!" //{`/dashboard/${facility.fid}/${slug}/overview`}
																		onClick={(e) => {
																			handleNavigation(facility);
																		}}>
																		<div className="avatar avatar-3xl">
																			<div className="avatar-name rounded-circle">
																				<span>{initials}</span>
																			</div>
																		</div>
																	</Link>
																	<div className="flex-1 position-relative ps-3">
																		<h6 className="fs-0 mb-0">
																			<Link className="text-1000 text-capitalize" to={``} onClick={(e) => handleNavigation(facility)}>
																				{facility.name}
																			</Link>
																		</h6>
																		<p className="mb-1">
																			Role:
																			<span className="badge rounded-pill badge-soft-success fs--2 ms-2 text-capitalize">
																				{facility.role}
																				<span className="fas fa-check ms-1" data-fa-transform="shrink-2"></span>
																			</span>
																		</p>
																		<p className="text-1000 mb-0">{facility.location}</p>
																		{index + 1 === totalData.length ? "" : <div className="border-dashed-bottom my-3"></div>}
																	</div>

																	<div className="dropdown font-sans-serif btn-reveal-trigger">
																		<button className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal" type="button" id="dropdown-active-user-report" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
																			<span className="fas fa-ellipsis-h fs--2"></span>
																		</button>
																		<div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown-active-user-report">
																			<button className="dropdown-item" to="" onClick={(e) => handleNavigation(facility)}>
																				Manage
																			</button>
																			<Link className="dropdown-item" to={`/facility/${facility.fid}/${slug}/settings`}>
																				Settings
																			</Link>
																			<div className="dropdown-divider"></div>
																			<a className="dropdown-item text-danger" href="#!">
																				Remove
																			</a>
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												) : (
													<div className="mt-3 ">
														<p>
															No facilities under your belt yet.
															<br /> Facilities you own will show here.
														</p>
														<div className="">
															<Link className="mb-4 d-block d-flex align-items-center" to="/facility/new" aria-expanded="false" aria-controls="education-form">
																<span className="circle-dashed">
																	<span className="fas fa-plus"></span>
																</span>
																<span className="ms-3">Add new facility</span>
															</Link>
														</div>
													</div>
												)}
											</div>
											<div className="tab-pane fade" id="tab-employers" role="tabpanel" aria-labelledby="my-employers-tab">
												{/* employers */}
												{props.facilities.staff.length > 0 ? (
													<div className="mt-3">
														{props.facilities.staff.map((facility, index, totalData) => {
															const initials = facility.name
																.match(/(^.{0,2})?/g)
																.join("")
																.toUpperCase();
															const slug = facility.name.replace(/ +/g, "-").toLowerCase();

															return (
																<div className="d-flex" key={facility.fid}>
																	<Link to="#!" onClick={(e) => handleNavigation(facility)}>
																		<div className="avatar avatar-3xl">
																			<div className="avatar-name rounded-circle">
																				<span>{initials}</span>
																			</div>
																		</div>
																	</Link>
																	<div className="flex-1 position-relative ps-3">
																		<h6 className="fs-0 mb-0">
																			<Link className="text-1000 text-capitalize" to="#!" onClick={(e) => handleNavigation(facility)}>
																				{facility.name}
																			</Link>
																		</h6>
																		<p className="mb-1">
																			Role:
																			<span className="badge rounded-pill badge-soft-info fs--2 ms-2 text-capitalize">
																				{facility.role}
																				<span className="fas fa-check ms-1" data-fa-transform="shrink-2"></span>
																			</span>
																		</p>
																		<p className="text-1000 mb-0 text-capitalize">{facility.location}</p>
																		{index + 1 === totalData.length ? "" : <div className="border-dashed-bottom my-3"></div>}
																	</div>
																	<div className="dropdown font-sans-serif btn-reveal-trigger">
																		<button className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal" type="button" id="dropdown-active-user-report" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
																			<span className="fas fa-ellipsis-h fs--2"></span>
																		</button>
																		<div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown-active-user-report">
																			<Link className="dropdown-item" to="#!" onClick={(e) => handleNavigation(facility)}>
																				Get to work
																			</Link>
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												) : (
													<div className="mt-3">
														<p>
															No facility has registered you as thier staff yet. <br /> When the Administrator of a facility registers you as their staff, the facility will show here.
														</p>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AllFacilities;
