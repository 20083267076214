import { createSlice } from "@reduxjs/toolkit";

export const navActiveSlice = createSlice({
  name: "navActive",
  initialState: {
    value: "/dashboard",
  },
  reducers: {
    updateNavActive: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateNavActive } = navActiveSlice.actions;

export default navActiveSlice.reducer;
