import requester from "./requester";

const getInventory = (fid, category) => {
	return requester.get(`inventory/${fid}/${category}/all`);
};

const addInventoryItem = (data) => {
	return requester.post("inventory/new", data);
};

const inventoryService = {
	getInventory,
	addInventoryItem,
};

export default inventoryService;
