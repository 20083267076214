import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import requester from "../../helpers/requester";

const initialState = {
	visits: [],
	status: "idle", // 'idle' | 'pending' | 'succeeded' | 'failed'
	error: null,
};

export const fetchAllVisits = createAsyncThunk("", async (thunkAPI, { dispatch, getState }) => {
	const state = getState();
	try {
		const response = await requester.get(`/visits/${state.facility.value.fid}/all`);
		return response.data;
	} catch (error) {
		return error.response.status;
	}
});

export const visitSlice = createSlice({
	name: "visit",
	initialState,
	reducers: {
		updateVisits: (state, action) => {
			state.visits = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllVisits.pending, (state, action) => {
				state.status = "pending";
			})
			.addCase(fetchAllVisits.fulfilled, (state, action) => {
				state.status = "success";
				state.visits = action.payload.visits;
				state.error = null;
			})
			.addCase(fetchAllVisits.rejected, (state, action) => {
				state.status = "failed";
				state.error = action;
			});
	},
});

export const { updateVisits } = visitSlice.actions;
export default visitSlice.reducer;
