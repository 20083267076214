import requester from "./requester";

const saveVitals = (data) => {
	return requester.post("/vital/new", data);
};

const getVitals = (data) => {
	return requester.get(`/vital/${data.visit_number}/${data.fid}`);
};

const vitalsService = {
	saveVitals,
	getVitals,
};

export default vitalsService;
