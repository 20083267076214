import { Link, useNavigate } from "react-router-dom";
import { isExpired } from "react-jwt";
import { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";

const ExpiredToken = () => {
	const navigate = useNavigate();
	const [isValidToken, setisValidToken] = useState(false);

	const checkToken = () => {
		const token = JSON.parse(localStorage?.getItem("APOMUDIN_USER_TOKEN"));

		if (token) {
			const tokenExpired = isExpired(token);
			if (tokenExpired) {
				// document.getElementById("expiryModal").click();
				setisValidToken(false);
			} else {
				// localStorage.removeItem("APOMUDIN_USER_TOKEN");
				// localStorage.removeItem("EXPIRES_IN");
				// window.location.href = "/facilities";
				setisValidToken(true);
				navigate("/facilities");
			}
		} else {
			// window.location.href = "/login";
			setisValidToken(false);
		}
	};
	useEffect(() => {
		// checkToken();
	}, []);
	//   useEffect(() => {
	//     return () => {
	//       const token = JSON.parse(localStorage?.getItem("APOMUDIN_USER_TOKEN"));
	//       if (token) {
	//         const tokenExpired = isExpired(token);
	//         if (tokenExpired) {
	//           const modal = document.getElementById("expiryModal").click();
	//         } else {
	//           localStorage.removeItem("APOMUDIN_USER_TOKEN");
	//           localStorage.removeItem("EXPIRES_IN");
	//           window.location.href = "/facilities";
	//         }
	//       } else {
	//         window.location.href = "/login";
	//       }
	//     };
	//   }, []);
	return (
		<>
			{/* {!isValidToken && ( */}
			<Fade>
				<div className="row flex-center min-vh-100 py-6 text-center">
					<div className="col-sm-10 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
						<a className="d-flex flex-center mb-4" href="../../../index.html">
							<img className="me-2" src={process.env.PUBLIC_URL + "/assets/img/logo_text.svg"} alt="" width="250" />
							{/* <span className="font-sans-serif fw-bolder fs-5 d-inline-block">Apomudin</span> */}
						</a>
						<div className="card">
							<div className="card-body p-4 p-sm-5">
								{/* <div className="avatar avatar-4xl">
								<img className="rounded-circle" src="../../../assets/img/team/1.jpg" alt="" />
							</div> */}
								<i className="fas fa-user-lock fa-2x"></i>
								<h5 className="mt-4 mb-0">Just to be safe, we logged you out.</h5>
								<p className="mb-4">Log back in to pick up where you left off.</p>
								<div className="">
									<Link className="btn btn-primary px-3" to={`/login`}>
										Login
									</Link>
								</div>
								{/* <form className="mt-4 row g-0 mx-sm-4">
								<div className="col">
									<input className="form-control me-2 mb-2" type="password" placeholder="Enter your password" aria-label="User's password" aria-describedby="user-password" />
								</div>
								<div className="col-auto ps-2">
									<button className="btn btn-primary px-3 mb-2" id="user-password" type="button">
										Login
									</button>
								</div>
							</form> */}
							</div>
						</div>
					</div>
				</div>
			</Fade>
			{/* )} */}
		</>
	);
};

export default ExpiredToken;
