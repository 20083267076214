const NotFoundDashboard = () => {
	return (
		<>
			<div className="row justify-content-center py-6 text-center">
				<div className="col-sm-10 col-md-8 col-lg-8 col-xxl-5">
					<div className="card">
						<div className="card-body p-4 p-sm-5">
							<div className="fw-black lh-1 text-3  00 fs-error">404</div>
							<p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">The page you're looking for is not found.</p>
							<hr />
							<p>
								Make sure the address is correct and that the page hasn't moved. If you think this is a mistake, <a href="mailto:rjbtechconsult.com">contact us</a>.
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotFoundDashboard;
