import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import PageHeader from "../PageHeader";
import inventoryService from "../../../helpers/inventoryService";

const InventoryItems = () => {
	const { category, fid } = useParams();
	const [inventory, setinventory] = useState([]);
	const [isLoading, setisLoading] = useState(true);
	const [pageTitle, setpageTitle] = useState("");

	const getInventoryItems = () => {
		setinventory([]);
		setisLoading(true);
		setpageTitle(category);

		inventoryService
			.getInventory(fid, category)
			.then((response) => {
				// if (response.data.success) {
				setinventory(response.data.inventory);
				setisLoading(false);
				// }

				// if (!response.data.success && response.data.inventory.length <= 0) {
				// 	setinventory(response.data.inventory);
				// }
			})
			.catch((error) => {
				setisLoading(false);
			});
	};

	useEffect(() => {
		getInventoryItems();
	}, [category]);
	return (
		<>
			<PageHeader
				title={`Inventory`}
				subTitle={pageTitle}
				showButton={true}
				buttonText={`Add ${category === "services" ? "Service" : "Item"}`}
				buttonRoute={`../inventory/${category === "services" ? "service" : category}/new`}
			/>

			{!isLoading && (
				<Fade>
					{/* header card */}
					{inventory.length > 0 ? (
						<>
							<div className="card mb-3">
								<div className="card-body">
									<div className="row flex-between-center">
										<div className="col-sm-auto mb-2 mb-sm-0">
											<h6 className="mb-0">
												Showing {inventory.length}-{inventory.length} of {inventory.length}{" "}
												{`${pageTitle != "services" ? "item(s)" : "service(s)"}`}
											</h6>
										</div>
										<div className="col-sm-auto">
											<div className="row gx-2 align-items-center">
												<div className="col-auto">
													<form className="row gx-2">
														<div className="col-auto">
															<small>Sort by:</small>
														</div>
														<div className="col-auto">
															<select className="form-select form-select-sm" aria-label="Bulk actions">
																<option hidden value="" disabled></option>
																<option value="Refund">Newest</option>
																<option value="Delete">Price</option>
															</select>
														</div>
													</form>
												</div>
												<div className="col-auto pe-0">
													<a
														className="text-600 px-1"
														href="../../../app/e-commerce/product/product-list.html"
														data-bs-toggle="tooltip"
														data-bs-placement="top"
														title="Product List"
													>
														<span className="fas fa-list-ul"></span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* content */}
							<div className="card mb-3">
								<div className="card-body">
									<div className="row">
										{inventory.map((item, index) => {
											return (
												<div key={index} className="mb-4 col-md-6 col-lg-4">
													<div className="border rounded-1 h-100 d-flex flex-column justify-content-between">
														<div className="overflow-hidden">
															{/* Image */}
															{pageTitle != "services" && (
																<div className="position-relative rounded-top overflow-hidden p-2">
																	{/* {`http://localhost:8000/storage/images/inventory/${item.image}`} */}
																	<a
																		className="d-flex justify-content-center"
																		href="../../../app/e-commerce/product/product-details.html"
																		style={{ height: "150px" }}
																	>
																		<img
																			className="img-fluid rounded-top h-100"
																			src={`http://localhost:8000/storage/app/public/images/inventory/${item.image}`}
																			alt=""
																		/>
																	</a>
																	{/* <span className="badge rounded-pill bg-success position-absolute mt-2 me-2 z-index-2 top-0 end-0">New</span> */}
																</div>
															)}

															<div className="p-3">
																{/* name */}
																<h5 className="fs-0">
																	<a className="text-dark" href="../../../app/e-commerce/product/product-details.html">
																		{item.name}
																	</a>
																</h5>

																{/* category */}
																<p className="fs--1 mb-3">
																	<a className="text-500 text-capitalize" href="#!">
																		{item.category}
																	</a>
																</p>
																{/* price */}
																<h5 className="fs-md-2 text-warning mb-0 d-flex align-items-center mb-3">
																	GHS {item.price.toFixed(2)}
																	<del className="ms-2 fs--1 text-500">$2399 </del>
																</h5>

																{/* <p className="fs--1 mb-1">
														Shipping Cost: <strong>$50</strong>
													</p> */}

																{/* availability */}
																{pageTitle != "services" && (
																	<p className="fs--1 mb-1">
																		Stock:{" "}
																		<strong
																			className={`${item.quantity_available > 0 ? "text-success" : "text-danger"}`}
																		>{`${item.quantity_available > 0 ? "Available" : "Sold Out"}`}</strong>
																		{item.quantity_available > 0 && (
																			<span className="ms-2">{`(${item.quantity_available})`}</span>
																		)}
																	</p>
																)}
															</div>
														</div>
														{/* <div className="d-flex flex-between-center px-3">
												<div>
													<span className="fa fa-star text-warning"></span>
													<span className="fa fa-star text-warning"></span>
													<span className="fa fa-star text-warning"></span>
													<span className="fa fa-star text-warning"></span>
													<span className="fa fa-star text-300"></span>
													<span className="ms-1">(8)</span>
												</div>
												<div>
													<a className="btn btn-sm btn-falcon-default me-2" href="#!" data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Wish List">
														<span className="far fa-heart"></span>
													</a>
													<a className="btn btn-sm btn-falcon-default" href="#!" data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Cart">
														<span className="fas fa-cart-plus"></span>
													</a>
												</div>
											</div> */}
													</div>
												</div>
											);
										})}
									</div>
								</div>
								<div className="card-footer bg-light d-flex justify-content-center">
									<div>
										<button
											className="btn btn-falcon-default btn-sm me-2"
											type="button"
											disabled="disabled"
											data-bs-toggle="tooltip"
											data-bs-placement="top"
											title="Prev"
										>
											<span className="fas fa-chevron-left"></span>
										</button>
										<a className="btn btn-sm btn-falcon-default text-primary me-2" href="#!">
											1
										</a>
										<a className="btn btn-sm btn-falcon-default me-2" href="#!">
											2
										</a>
										<a className="btn btn-sm btn-falcon-default me-2" href="#!">
											<span className="fas fa-ellipsis-h"></span>
										</a>
										<a className="btn btn-sm btn-falcon-default me-2" href="#!">
											35
										</a>
										<button
											className="btn btn-falcon-default btn-sm"
											type="button"
											data-bs-toggle="tooltip"
											data-bs-placement="top"
											title="Next"
										>
											<span className="fas fa-chevron-right"> </span>
										</button>
									</div>
								</div>
							</div>
						</>
					) : (
						<>Nothing</>
					)}
				</Fade>
			)}
		</>
	);
};

export default InventoryItems;
