import { Link, useParams, useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import SearchFormCard from "../../SearchFormCard";
import { Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import visitService from "../../../helpers/visitService";
import TriageModal from "../TriageModal";
import moment from "moment/moment";

const AllVisits = () => {
	const navigate = useNavigate();
	const [filterText, setfilterText] = useState("");
	// const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const { fid } = useParams();
	const [visits, setvisits] = useState([]);
	const [loading, setloading] = useState(true);
	const [showTewsModal, setshowTewsModal] = useState(false);
	// const facility = useSelector((state) => state.facility.value);

	// const dispatch = useDispatch();

	const getAllVisits = () => {
		visitService
			.getAllVisits(fid)
			.then((res) => {
				setvisits(res.data.visits);
				setloading(false);
			})
			.catch((error) => {});
	};

	useEffect(() => {
		return () => {
			getAllVisits();
		};
	}, []);

	const filteredItems = !loading ? visits.filter((item) => item.visit_number && item.visit_number.toLowerCase().trim().includes(filterText.toLocaleLowerCase().trim())) : [];

	const columns = [
		{
			name: "Visit Type",
			selector: (visit) => visit.full_name,
			sortable: true,
			filterable: true,
			grow: 2,
			cell: (visit) => (
				<div
					className="d-flex align-items-center"
					onClick={() => {
						navigate(`../appointment/${visit.visit_number}/vitals`);
					}}>
					{/* <div className="avatar avatar-xl">
						<img className="rounded-circle" src={process.env.PUBLIC_URL + "/assets/img/team/1-thumb.png"} alt="" />
					</div> */}
					<h6 className="mb-0 text-800 name">{visit.full_name}</h6>
				</div>
			),
		},
		{
			name: "Queue No.",
			selector: (visit) => visit.visit_number,
			sortable: true,
			filterable: true,
		},
		{
			name: "Care Type.",
			selector: (visit) => visit.care_type,
			sortable: true,
			filterable: true,
		},
		// {
		// 	name: "To See",
		// 	selector: (visit) => visit.to_see,
		// },
		// {
		// 	name: "status",
		// 	selector: (visit) => visit.status,
		// 	grow: 1,
		// },
		{
			name: "Date Time",
			selector: (visit) => moment(visit.created_at).format("lll"),
		},
		{
			name: "Score",
			selector: (visit) => visit.vitals,
			cell: (visit) => (
				<>
					{visit.vitals && visit.vitals.tews && (
						<OverlayTrigger key={visit.vitals.tews.score} placement="top" overlay={<Tooltip id={visit.vitals.tews.score}>{visit.vitals.tews.description}</Tooltip>}>
							<div className={`card bg-triage-${visit.vitals.tews.color} shadow-none d-flex text-center p-1 tews`} onClick={() => setshowTewsModal(true)}>
								<span className="m-auto">{visit.vitals.tews.score}</span>
							</div>
						</OverlayTrigger>
					)}
				</>
			),
		},
		{
			name: "",
			selector: (visit) => visit.id,
			right: true,
			cell: (visit) => (
				<>
					<div className="hover-actions bg-transparent">
						<OverlayTrigger key={visit.pid} placement="top" overlay={<Tooltip id={visit.pid}>View</Tooltip>}>
							<Link className="btn icon-item rounded-3 me-2 fs--2 icon-item-sm" state={visit} to={`../appointment/${visit.visit_number}/vitals`}>
								<span className="far fa-eye"></span>
							</Link>
						</OverlayTrigger>
						{/* <OverlayTrigger key={patient.pid} placement="top" overlay={<Tooltip id={patient.id}>Create visit</Tooltip>}>
							<Link className="btn icon-item rounded-3 me-2 fs--2 icon-item-sm" to={`../appointments/new/${patient.pid}`} state={patient}>
								<span className="far fa-list-alt"></span>
							</Link>
						</OverlayTrigger> */}
					</div>
					<div className="dropdown font-sans-serif btn-reveal-trigger">
						<button className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal-sm transition-none" type="button" id="crm-recent-leads-0" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
							<span className="fas fa-ellipsis-h fs--2"></span>
						</button>
						<div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="crm-recent-leads-0">
							<Link className="dropdown-item" state={visit} to={`../appointment/${visit.visit_number}/vitals`}>
								View
							</Link>
							{/* <Link className="dropdown-item" state={visit} to={`../patient/${visit.visit_number}`}>
								Take vitals
							</Link> */}
							{/* <a className="dropdown-item" href="#!">
								Export
							</a>
							<div className="dropdown-divider"></div>
							<a className="dropdown-item text-danger" href="#!">
								Remove
							</a> */}
						</div>
					</div>
				</>
			),
		},
	];

	return (
		<>
			<Fade>
				<div className="mb-3">
					<div className="row gx-0 flex-between-center mb-3">
						<div className="col-sm-auto d-flex align-items-center">
							<div>
								<h6 className="text-info fs--1 mb-0">Clinical Management</h6>
								<h4 className="text-primary fw-bold mb-0">Appointments</h4>
							</div>
						</div>
						{/* <div className="col-md-auto">
							<Link className="btn btn-primary" to={`../appointments/new`}>
								<span className="fas fa-plus me-2"></span> Creae new visit
							</Link>
						</div> */}
					</div>
					<div className="card bg-100">
						<div className="card-body">
							<div className="row">
								<div className="col-lg-3 border-lg-end border-bottom border-lg-0 pb-3 pb-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-primary">
												<span className="fs--2 fas fa-phone text-primary"></span>
											</div>
											<h6 className="mb-0">New visits</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{visits.length}</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-up text-primary"></span>
												<p className="fs--2 mb-0 text-nowrap">today</p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[220,230,150,175,200,170,70,160],"color":"#2c7be5","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#2c7be53A"},{"offset":1,"color":"#2c7be50A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>

								<div className="col-lg-3 border-lg-end border-bottom border-lg-0 py-3 py-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-info">
												<span className="fs--2 fas fa-user text-info"></span>
											</div>
											<h6 className="mb-0">Nurse seen</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">13</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-up text-success"></span>
												<p className="fs--2 mb-0 text-nowrap">in queue</p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[90,160,150,120,230,155,220,240],"color":"#27bcfd","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#27bcfd3A"},{"offset":1,"color":"#27bcfd0A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>

								<div className="col-lg-3 pt-3 pt-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-success">
												<span className="fs--2 fas fa-bolt text-success"></span>
											</div>
											<h6 className="mb-0">Doctor seen</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{6}</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-down text-danger"></span>
												<p className="fs--2 mb-0 text-nowrap">in total</p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[200,150,175,130,150,115,130,100],"color":"#00d27a","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#00d27a3A"},{"offset":1,"color":"#00d27a0A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>

								<div className="col-lg-3 pt-3 pt-lg-0">
									<div className="d-flex flex-between-center mb-3">
										<div className="d-flex align-items-center">
											<div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-success">
												<span className="fs--2 fas fa-bolt text-success"></span>
											</div>
											<h6 className="mb-0">Visit complete</h6>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex">
											<p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{6}</p>
											<div className="d-flex flex-column">
												<span className="me-1 text-success fas fa-caret-down text-danger"></span>
												<p className="fs--2 mb-0 text-nowrap">in total</p>
											</div>
										</div>
										<div
											className="echart-crm-statistics w-100 ms-2"
											data-echart-responsive="true"
											data-echarts='{"series":[{"type":"line","data":[200,150,175,130,150,115,130,100],"color":"#00d27a","areaStyle":{"color":{"colorStops":[{"offset":0,"color":"#00d27a3A"},{"offset":1,"color":"#00d27a0A"}]}}}],"grid":{"bottom":"-10px"}}'></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col">
						<div className="card">
							<div className="card-header d-flex flex-between-center bg-light py-2">
								<SearchFormCard value={filterText} setFunction={setfilterText}></SearchFormCard>
							</div>
							<div className="card-body py-0">
								<DataTable
									columns={columns}
									data={filteredItems}
									// paginationResetDefaultPage={resetPaginationToggle}
									// subHeaderComponent={subHeaderComponentMemo}
									pagination
									responsive
									progressPending={loading}
									onRowClicked={(visit, event) => {
										navigate(`../appointment/${visit.visit_number}/vitals`);
									}}
									progressComponent={
										<div className="p-5">
											<Spinner animation="grow" variant="primary" />
										</div>
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
			<TriageModal showTriageModal={showTewsModal} setShowTriageModal={setshowTewsModal} />
		</>
	);
};

export default AllVisits;
